import React from 'react'
import { Box, Text } from './Shared/Base'
import styled from 'styled-components'
import Helmet from 'react-helmet'
import { options_from_hashmap, qps_from_search } from '../helpers/misc'
import { FocusableField, useFormState } from './Shared/Form/Field'
import { useFetchApi } from './Shared/hooks'
import { FormTextareaInput } from './Shared/Form/Input'

const LandingStyled = styled.main`
  background: linear-gradient(180deg, #753a92 21.85%, #a15ba3 72.35%);
  color: white;
  padding-bottom: 440px;

  label {
    font-weight: bold;
  }

  .nps-title {
    font-family: 'montserrat';
  }
`

const FirstBox = styled(Box)`
  position: relative;

  :before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    bottom: -440px;
    width: 100%;
    height: 100%;
    opacity: 0.6;
    background-image: url('https://res.cloudinary.com/loueragile/image/upload/v1698224268/web/jinka/Rent2023_BG.png');
    background-repeat: no-repeat;
    background-position: bottom;
  }

  @media screen and (max-width: 1110px) {
    br {
      display: none;
    }
  }
`

const Radio = ({ checked, onCheck, label }) => {
  return (
    <Box display="flex" onClick={() => onCheck(!checked)} style={{ cursor: 'pointer' }}>
      <Box as="label" className="form-radio" mb={0}>
        <input type="radio" checked={checked} onChange={() => {}} />
        <span className="checkmark" />
      </Box>

      <Text fontSize={16} fontFamily="Lato_400Regular" color="text">
        {label}
      </Text>
    </Box>
  )
}

const Nps = ({ location, history }) => {
  const fetcher = useFetchApi()
  const queryString = qps_from_search(location.search)

  const form = useFormState({
    initial_values: {
      score: null,
      double_ads: null,
      discovered: null,
      discovered_custom: null,
      suggestion: null
    },
    validators: {
      score: { required: v => v !== -1 },
      double_ads: { required: v => v !== '' },
      discovered: { required: v => v !== '' },
      discovered_custom: { required: (v, ctx) => ctx.values.discovered !== 'other' || v !== '' },
      suggestion: {}
    },
    onSubmitSuccess: async values => {
      await fetcher('user/nps', {
        method: 'PUT',
        body: JSON.stringify({
          user_id: queryString.id,
          score: values.score,
          double_ads: values.double_ads,
          discovered: values.discovered,
          discovered_custom:
            values.discovered_custom === null ? undefined : values.discovered_custom,
          suggestion: values.suggestion === null ? undefined : values.suggestion
        })
      })
      history.replace('/')
    }
  })

  const score_options = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  const double_ads_options = options_from_hashmap({
    no: 'Non, je ne pense pas',
    sometimes: 'Oui, quelques-unes',
    yes: 'Oui, trop à mon goût'
  })
  const discovered_options = [
    { label: 'TikTok', value: 'tiktok' },
    { label: 'Youtube', value: 'youtube' },
    { label: 'Par un ami ou un collègue', value: 'friend' },
    { label: 'Autre', value: 'other', has_description_label: 'Autre (précisez)' }
  ]

  return (
    <LandingStyled>
      <Helmet>
        <title>Jinka</title>
        <meta name="description" content="Jinka" />
        <meta property="og:title" content="Jinka" />
        <meta property="og:description" content="Jinka" />
        <meta property="og:url" content="https://www.jinka.fr/" />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/loueragile/image/upload/v1636016457/web/jinka/Image_OG.jpg"
        />
        <link rel="canonical" href="https://www.jinka.fr/" />
      </Helmet>

      <FirstBox
        as="section"
        maxWidth="100%"
        mx="auto"
        px={{ lg: 0, xs: 20 }}
        pt={{ lg: 100, xs: 50 }}
      >
        <Box maxWidth={1200} mx="auto" position="relative" onChange={form.onChange}>
          <Box textAlign="center">
            <Box
              as="img"
              src="https://res.cloudinary.com/loueragile/image/upload/v1653311807/web/jinka/Logo-Jinka-white.svg"
              alt="Logo Jinka"
              className="logo"
              width="200 px"
            />
          </Box>

          <Box bg="white" p={{ lg: 40, xs: 20 }} mt={35} borderRadius={20} maxWidth={520} mx="auto">
            {!!queryString.id ? (
              <>
                <Text className="nps-title fz-24" color="text" mb={20} textAlign="center">
                  <strong>
                    Quelle est la probabilité que vous recommandiez Jinka à un ami&nbsp;?
                  </strong>
                </Text>

                <Box
                  display="flex"
                  flexWrap="wrap"
                  justifyContent="center"
                  width={{ sm: 330, xs: 'auto' }}
                  style={{ columnGap: '10px', rowGap: '10px' }}
                  margin="0 auto"
                >
                  {score_options.map(score => (
                    <Box
                      key={score}
                      width={{ md: 42, xs: 36 }}
                      height={{ md: 42, xs: 36 }}
                      borderRadius={100}
                      fontSize={18}
                      border="1px solid"
                      bg={form.values.score === score ? 'bg_n_secondary' : 'white'}
                      borderColor={form.values.score === score ? 'n_secondary' : 'thin'}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      onClick={() => form.onChangeByNameValue('score', score)}
                      style={{ cursor: 'pointer' }}
                    >
                      <Text
                        color={form.values.score === score ? 'n_secondary' : 'text'}
                        style={{ lineHeight: 0 }}
                      >
                        {score}
                      </Text>
                    </Box>
                  ))}
                </Box>

                <Box display="flex" flexDirection="row" justifyContent="space-between" mt={20}>
                  <Text fontSize={14} fontFamily="Lato_400Regular" color="light">
                    0 - Pas du tout probable
                  </Text>
                  <Text fontSize={14} fontFamily="Lato_400Regular" color="light">
                    10 - Très probable
                  </Text>
                </Box>

                <Box height="1px" backgroundColor="thin" my={20} />

                <Text
                  fontSize={18}
                  fontFamily="Lato_700Bold"
                  color="text"
                  textAlign="start"
                  mb={10}
                >
                  <strong>Avez-vous reçu des annonces en double&nbsp;?</strong>
                </Text>
                <Box display="flex" flexDirection="column" style={{ gap: 15 }}>
                  {double_ads_options.map(option => (
                    <Radio
                      checked={form.values.double_ads === option.value}
                      label={option.label}
                      onCheck={() => form.onChangeByNameValue('double_ads', option.value)}
                    />
                  ))}
                </Box>

                <Box height="1px" backgroundColor="thin" my={20} />

                <Text
                  fontSize={18}
                  fontFamily="Lato_700Bold"
                  color="text"
                  textAlign="start"
                  mb={10}
                >
                  <strong>Comment avez-vous découvert Jinka&nbsp;?</strong>
                </Text>
                <Box display="flex" flexDirection="column" style={{ gap: 15 }}>
                  {discovered_options.map(option => (
                    <Radio
                      checked={form.values.discovered === option.value}
                      label={option.label}
                      onCheck={() => form.onChangeByNameValue('discovered', option.value)}
                    />
                  ))}

                  {form.values.discovered === 'other' && (
                    <Box>
                      <FocusableField
                        type="text"
                        showRequiredError={form.submittedOnce}
                        name="discovered_custom"
                        value={form.values.discovered_custom}
                        validators={form.validators.discovered_custom}
                        placeholder="Votre réponse"
                        context={form.context}
                      >
                        {FormTextareaInput}
                      </FocusableField>
                    </Box>
                  )}
                </Box>

                <Box height="1px" backgroundColor="thin" my={20} />

                <Text
                  fontSize={18}
                  fontFamily="Lato_700Bold"
                  color="text"
                  textAlign="start"
                  mb={10}
                >
                  <strong>Comment amélioreriez-vous Jinka&nbsp;?</strong>
                </Text>
                <FocusableField
                  type="text"
                  showRequiredError={form.submittedOnce}
                  name="suggestion"
                  value={form.values.suggestion}
                  validators={form.validators.suggestion}
                  placeholder="Votre réponse"
                  context={form.context}
                >
                  {FormTextareaInput}
                </FocusableField>

                <Box textAlign="center">
                  <Box
                    as="button"
                    type="submit"
                    className="btn is-n_secondary is-big"
                    onClick={form.onSubmit}
                    mt={30}
                    disabled={
                      form.loading ||
                      form.values.score === undefined ||
                      !form.values.double_ads ||
                      !form.values.discovered ||
                      (form.values.discovered === 'other' && !form.values.discovered_custom)
                    }
                    width="100%"
                  >
                    Envoyer
                  </Box>
                </Box>
              </>
            ) : (
              <Text fontSize={18} fontFamily="Lato_700Bold" color="negative" textAlign="center">
                <strong>Ce lien est invalide.</strong>
              </Text>
            )}
          </Box>
        </Box>
      </FirstBox>
    </LandingStyled>
  )
}

export default Nps
