export const with_res_db_tenement = res_db_tenement => action => ({
  res_db_tenement,
  ...action
})

export const db_tenement_selector = state => state.db.tenement

const reducer = (state = {}, action) => {
  if (action.res_db_tenement) {
    const { id } = action.res_db_tenement
    return {
      ...state,
      [id]: action.res_db_tenement
    }
  }

  return state
}

export default reducer
