import React from 'react'

import LayoutWithLeftNav from '../Shared/Layout/WithLeftNav'
import LayoutNavLink from './NavLink'
import routes from '../../routes'

export const NavRenter = () => (
  <>
    <LayoutNavLink
      to={routes.alert_list.route.reverse()}
      activeOnRoutes={[routes.alert_list.route, routes.alert_create.route]}
    >
      Alertes
    </LayoutNavLink>
    {/* <LayoutNavLink
      to={routes.asrenter_application_list.route.reverse()}
      activeOnRoutes={[
        routes.asrenter_application_list.route,
        routes.asrenter_adpremium_apply.route
      ]}
    >
      Candidatures
    </LayoutNavLink> */}
    {/* <LayoutNavLink
      to={routes.asrenter_global_application_home.route.reverse()}
      activeOnRoutes={[
        routes.asrenter_global_application.route,
        routes.asrenter_global_application_home.route
      ]}
    >
      Dossier
    </LayoutNavLink> */}
    <LayoutNavLink
      to={routes.asrenter_settings.route.reverse()}
      activeOnRoutes={[routes.asrenter_settings.route]}
    >
      Compte
    </LayoutNavLink>
    <LayoutNavLink
      to={routes.asrenter_profile.route.reverse()}
      activeOnRoutes={[routes.asrenter_profile.route]}
    >
      Profil
    </LayoutNavLink>
  </>
)

const LayoutRenter = ({ children }) => {
  return (
    <LayoutWithLeftNav
      navigation={
        <ul>
          <NavRenter />
        </ul>
      }
    >
      {children}
    </LayoutWithLeftNav>
  )
}

export default LayoutRenter
