import React from 'react'
import styled from 'styled-components'
import Helmet from 'react-helmet'
import { Box, Text } from '../Shared/Base'
// import Avis from './Avis'
import { dataLayer } from '../../store/analytics.middleware'
import { useImmerStore } from '../../store'
import { useAppStoreLink, useGooglePlayLink } from '../Shared/hooks'

const CardFav = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="none" viewBox="0 0 17 17">
    <path d="M15.234 1.776l.001.001a4.249 4.249 0 011.23 3.033h-.001v.005c0 1.012-.445 2.192-1.179 3.433-.728 1.231-1.71 2.472-2.714 3.593a44.164 44.164 0 01-4.089 3.975 44.146 44.146 0 01-4.09-3.976C3.39 10.72 2.408 9.48 1.68 8.247.945 7.005.5 5.825.5 4.813v-.004A4.279 4.279 0 014.77.5h.019a4.163 4.163 0 013.298 1.609l.395.508.395-.508A4.164 4.164 0 0112.175.5H12.22a4.25 4.25 0 013.015 1.276z" />
    <path d="M13.571 4.241c-.15 1.08-1.583 3.609-3.392 5.09" />
  </svg>
)

const IconCity = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
    <path d="M4.25 19.063H.5v-8.75h1.25M6.75 12.813h2.5M6.75 15.313h2.5M6.75 10.313h2.5M10.5 4.063V.938l8.75 3.125v15h-15v-12.5h7.5v12.29M15.75 15.313v-8.75" />
  </svg>
)

const LandingStyled = styled.main`
  color: var(--colorN_primary);

  .btn.is-medium {
    font-size: 1.8rem;
    padding: 16px 30px;
  }

  .h1-like {
    font-family: 'montserrat';
    font-size: 4.4rem;
    line-height: 5.4rem;

    @media screen and (max-width: 1439px) {
      font-size: 3.6rem;
      line-height: 4.6rem;
    }

    @media screen and (max-width: 599px) {
      text-align: center;
    }
  }

  .h2-title {
    font-weight: normal;
    font-size: 3.6rem;
    line-height: 4.6rem;

    @media screen and (max-width: 1439px) {
      font-size: 3.2rem;
      line-height: 4.2rem;
    }

    @media screen and (max-width: 599px) {
      text-align: center;
    }
  }

  .h2-like {
    font-family: 'montserrat';
    font-size: 3.6rem;
    line-height: 4.6rem;
  }
`

const FirstBox = styled(Box)`
  background: linear-gradient(180deg, rgba(162, 221, 255, 0.24) 0%, rgba(255, 255, 255, 0) 33.47%);
  position: relative;

  @media screen and (max-width: 1110px) {
    br {
      display: none;
    }
  }

  .illustration {
    @media screen and (max-width: 1140px) {
      width: 450px;
    }
    @media screen and (max-width: 1000px) {
      width: 400px;
    }
  }

  .apps {
    position: relative;

    &::after {
      content: '';
      background-image: url(https://res.cloudinary.com/loueragile/image/upload/v1601888304/web/jinka/Gratuit.png);
      background-size: 173px 83px;
      width: 173px;
      height: 83px;
      position: absolute;
      left: 450px;
      top: -45px;

      @media screen and (max-width: 1220px) {
        display: none;
      }
    }
  }
`

const CityBox = styled(Box)`
  .tags {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &.is-agglo {
      justify-content: center;
    }

    @media screen and (max-width: 1023px) {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 20px;
    }
  }

  .tag {
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 2rem;
    margin-right: 20px;
    margin-top: 20px;
    position: relative;
    transition: all 0.5s;
    border: 1px solid var(--colorThin);
    color: var(--colorN_primary);
    background-color: white;
    text-align: left;

    &.is-agglo {
      margin: 0 20px;
      margin-right: 0;
      margin-top: 20px;
    }

    @media screen and (max-width: 1100px) {
      font-size: 1.6rem;
    }

    @media screen and (max-width: 1023px) {
      margin-right: 0;
      margin-top: 0;

      &.is-agglo {
        margin: 0 0;
        margin-top: 10px;
      }
    }

    span {
      display: inline-flex;
      align-items: center;
      position: relative;
      z-index: 2;
      width: 100%;
      height: 100%;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 0%;
      height: 100%;
      z-index: 1;
      opacity: 0;
      background-color: var(--colorBg_secondary);
      transition: all 0.3s;
      border-radius: 5px;
    }

    &:hover::before {
      opacity: 1;
      width: 100%;
    }

    &:hover,
    &:focus {
      color: var(--colorSecondary);
      border: 1px solid var(--colorBg_secondary);

      svg {
        stroke: var(--colorSecondary);
      }
    }

    ${'' /* &:nth-child(3n + 0) {
      margin-right: 0;
    } */}

    svg {
      stroke: var(--colorN_tertiary);
      margin-right: 15px;

      @media screen and (max-width: 359px) {
        display: none;
      }
    }
  }
`

const LogosPresse = styled(Box)`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;

  @media screen and (max-width: 830px) {
    display: none;
  }

  a {
    min-width: 0;
    min-height: 0;
    max-height: 44px;

    &:nth-child(1) {
      max-width: 127px;
    }

    &:nth-child(2) {
      max-width: 169px;
    }

    &:nth-child(3) {
      max-width: 172px;
    }

    &:nth-child(4) {
      max-width: 139px;
      margin-top: 7px;
    }

    &:not(:last-child) {
      margin-right: 40px;
    }

    @media screen and (max-width: 830px) {
      &:not(:last-child) {
        margin-right: 0;
        margin-bottom: 20px;
      }
    }
  }
`

const BoxCards = styled(Box)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 60px;

  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-gap: 30px;
  }
`

const BoxCard = styled(Box)`
  box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  background-color: white;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.07);
  }

  .photo {
    height: 0;
    position: relative;
    padding-bottom: 66.66%;
  }

  .img {
    border-radius: 5px 5px 0 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
  }

  .head {
    position: relative;

    .top,
    .bottom {
      position: absolute;
      right: 10px;
      display: inline-grid;
      grid-template-columns: auto auto;
      z-index: 2;
    }

    .top {
      top: 10px;
    }

    .bottom {
      bottom: 10px;
    }
  }

  .tag {
    padding: 4px 10px;
    border-radius: 5px;
    font-size: 1.4rem;
    text-transform: uppercase;

    + .tag {
      margin-left: 10px;
    }

    &.premium {
      background-color: var(--colorPremium);
      color: white;
    }

    &.primary {
      background-color: var(--colorBg_primary);
      color: var(--colorPrimary);
      display: inline-grid;
      grid-template-columns: auto auto;
      grid-gap: 10px;
      align-items: center;
    }

    &.tertiary {
      background-color: white;
      color: var(--colorText);
    }

    &.negative {
      background-color: var(--colorBg_negative);
      color: var(--colorNegative);
    }
  }

  .content {
    padding: 10px 20px 15px 20px;

    .title {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      color: var(--colorText);
      font-weight: bold;
    }

    .bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;
    }
  }

  .icons {
    display: flex;
    align-items: center;
  }

  .icon {
    background-color: white;
    border-radius: 100%;
    border: 1px solid var(--colorThin);
    width: 38px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      stroke: var(--colorLight);
    }

    &.active {
      svg {
        stroke: var(--colorNegative);
        fill: var(--colorNegative);
      }
    }

    + .icon {
      margin-left: 10px;
    }
  }
`

const LandingHomepage = () => {
  const [, set_state] = useImmerStore()
  const onCtaCreateClick = React.useCallback(() => {
    dataLayer.push({
      event: 'eventGA',
      category: 'CTAInstall',
      action: 'NPCreate'
    })
    set_state(draft => {
      draft.ui.modal_app_install_open = true
    })
  }, [set_state])

  const onCtaCityClick = React.useCallback(
    (city, agglo) => {
      dataLayer.push({
        event: 'eventGA',
        category: 'CTAInstall',
        action: 'NPCity',
        label: `${agglo}_${city.toLowerCase()}`
      })
      set_state(draft => {
        draft.ui.modal_app_install_open = true
      })
    },
    [set_state]
  )
  const google_play_link = useGooglePlayLink()
  const apple_store_link = useAppStoreLink()

  return (
    <LandingStyled>
      <Helmet>
        <title>Tous les logements neufs en Ile-de-France sur une seule application - Jinka</title>
        <meta
          name="description"
          content="10 000 logements neufs en Ile-de-France à prix direct promoteur."
        />
        <meta
          property="og:title"
          content="Tous les logements neufs en Ile-de-France sur une seule application - Jinka"
        />
        <meta
          property="og:description"
          content="10 000 logements neufs en Ile-de-France à prix direct promoteur."
        />
        <meta property="og:url" content="https://www.jinka.fr/achat-logement-neuf-idf" />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/loueragile/image/upload/v1618990667/web/jinka/Image_OG_neuf.jpg"
        />
        <link rel="canonical" href="https://www.jinka.fr/achat-logement-neuf-idf" />
      </Helmet>

      <FirstBox
        as="section"
        maxWidth="100%"
        mx="auto"
        px={{ lg: 0, xs: 20 }}
        pt={{ md: 160, xs: 100 }}
      >
        <Box
          maxWidth={1200}
          mx="auto"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          position="relative"
        >
          <Box pt={50} pb={{ ml: 50, xs: 0 }}>
            <Box as="h1" className="h1-like">
              <Box as="span" color="n_secondary">
                Tous les logements neufs <br />
              </Box>{' '}
              sur une seule application
            </Box>
            <Box as="h2" mt={10} className="h2-title">
              10 000 logements neufs en <br /> Ile-de-France à prix direct promoteur
            </Box>
            <Box
              display="grid"
              gridTemplateColumns={{ sm: 'repeat(2, max-content)', xs: '1fr' }}
              gridGap={30}
              mt={{ ml: 80, xs: 60 }}
              className="apps"
            >
              <Box
                as="a"
                href={apple_store_link}
                target="_blank"
                rel="noopener noreferrer"
                textAlign="center"
                onClick={() => {
                  dataLayer.push({
                    event: 'eventGA',
                    category: 'CTAInstall',
                    action: 'DirectHeader'
                  })
                  dataLayer.push({
                    event: 'link_app_ios'
                  })
                }}
              >
                <Box
                  as="img"
                  src="https://res.cloudinary.com/loueragile/image/upload/v1601450698/web/jinka/Apple.png"
                  height={58}
                  alt="Télécharger dans l'App Store"
                />
              </Box>
              <Box
                as="a"
                href={google_play_link}
                target="_blank"
                rel="noopener noreferrer"
                textAlign="center"
                onClick={() => {
                  dataLayer.push({
                    event: 'eventGA',
                    category: 'CTAInstall',
                    action: 'DirectHeader'
                  })
                  dataLayer.push({
                    event: 'link_app_android'
                  })
                }}
              >
                <Box
                  as="img"
                  src="https://res.cloudinary.com/loueragile/image/upload/v1601450698/web/jinka/Google.png"
                  height={58}
                  alt="Disponible sur Google Play"
                />
              </Box>
            </Box>
            <Box display={{ sm: 'flex', xs: 'block' }} alignItems="center" mt={40}>
              <Box display="flex" alignItems="center" justifyContent="center">
                <Text fontSize={28}>
                  <strong>4.9</strong>
                </Text>
                <Box
                  as="img"
                  src="https://res.cloudinary.com/loueragile/image/upload/v1601450826/web/jinka/Stars.svg"
                  alt="Étoile"
                  ml={10}
                  mr={{ sm: 10, xs: 0 }}
                />
              </Box>
              <Box
                className="fz-24"
                display={{ sm: 'flex', xs: 'block' }}
                alignItems="center"
                justifyContent="center"
                mt="5px"
                textAlign="center"
              >
                <Box as="p" mr={10}>
                  <strong>+ de 1000 avis</strong> sur
                </Box>{' '}
                <Box display="flex" alignItems="center" justifyContent="center">
                  <Box
                    as="a"
                    display="inline-flex"
                    href="https://g.page/loueragile?we"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Box
                      as="img"
                      src="https://res.cloudinary.com/loueragile/image/upload/v1601450826/web/jinka/Logo-Google.svg"
                      alt="Logo Google"
                      mt="5px"
                    />
                  </Box>{' '}
                  <Box as="span" mx={10}>
                    &
                  </Box>{' '}
                  <Box
                    as="a"
                    display="inline-flex"
                    href="https://www.facebook.com/pg/loueragile/reviews/"
                    target="_blank"
                    rel="noopener noreferrer"
                    mb="2px"
                  >
                    <Box
                      as="img"
                      src="https://res.cloudinary.com/loueragile/image/upload/v1601450826/web/jinka/Logo-Facebook.svg"
                      alt="Logo Facebook"
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            as="img"
            src="https://res.cloudinary.com/loueragile/image/upload/f_auto/v1642497985/web/jinka/Screen-app-neuf.png"
            alt="Application"
            display={{ md: 'block', xs: 'none' }}
            className="illustration"
            ml={30}
          />
        </Box>
      </FirstBox>

      <Box as="section" maxWidth={1000} mx="auto" px={{ lg: 0, xs: 20 }} mt={{ ml: 120, xs: 80 }}>
        <Box textAlign="center">
          <Box as="h2" className="h2-like">
            Notre selection de programmes neufs
          </Box>
          <Text className="fz-24" textAlign="center" color="n_secondary">
            Profitez des meilleures opportunités
          </Text>
        </Box>
        <BoxCards mt={40}>
          <BoxCard onClick={onCtaCreateClick}>
            <Box className="head">
              <Box className="photo">
                <img
                  src="https://res.cloudinary.com/loueragile/image/upload/f_auto/v1607418758/web/jinka/neuf-01.jpg"
                  alt=""
                  className="img"
                />
              </Box>
            </Box>
            <Box className="content">
              <Text className="title fz-">Le Berlier - 1 à 5 pcs</Text>
              <Text className="fz-16" color="light">
                Paris 13e (75013)
              </Text>
              <Box className="bottom">
                <Box color="text" className="fz-24">
                  <Text as="p" fontSize={13} lineHeight="1">
                    À partir de
                  </Text>
                  <strong>428 000 €</strong>
                </Box>
                <Box className="icons">
                  <Box as="button" className="icon">
                    <CardFav />
                  </Box>
                </Box>
              </Box>
            </Box>
          </BoxCard>
          <BoxCard onClick={onCtaCreateClick}>
            <Box className="head">
              <Box className="photo">
                <img
                  src="https://res.cloudinary.com/loueragile/image/upload/f_auto/v1607418758/web/jinka/neuf-02.jpg"
                  alt=""
                  className="img"
                />
              </Box>
            </Box>
            <Box className="content">
              <Text className="title fz-">Rue Petit - 1 à 4 pcs</Text>
              <Text className="fz-16" color="light">
                Paris 19e (75019)
              </Text>
              <Box className="bottom">
                <Box color="text" className="fz-24">
                  <Text as="p" fontSize={13} lineHeight="1">
                    À partir de
                  </Text>
                  <strong>367 000 €</strong>
                </Box>
                <Box className="icons">
                  <Box as="button" className="icon">
                    <CardFav />
                  </Box>
                </Box>
              </Box>
            </Box>
          </BoxCard>
          <BoxCard onClick={onCtaCreateClick}>
            <Box className="head">
              <Box className="photo">
                <img
                  src="https://res.cloudinary.com/loueragile/image/upload/f_auto/v1607418759/web/jinka/neuf-03.jpg"
                  alt=""
                  className="img"
                />
              </Box>
            </Box>
            <Box className="content">
              <Text className="title fz-">Le prismatic - 1 à 3 pcs</Text>
              <Text className="fz-16" color="light">
                Paris 12e (75012)
              </Text>
              <Box className="bottom">
                <Box color="text" className="fz-24">
                  <Text as="p" fontSize={13} lineHeight="1">
                    À partir de
                  </Text>
                  <strong>373 000 €</strong>
                </Box>
                <Box className="icons">
                  <Box as="button" className="icon">
                    <CardFav />
                  </Box>
                </Box>
              </Box>
            </Box>
          </BoxCard>
        </BoxCards>
      </Box>

      <Box
        as="section"
        mt={{ ml: 120, xs: 60 }}
        px={{ lg: 0, xs: 20 }}
        backgroundColor="#F6FBFF"
        py={60}
      >
        <Box textAlign="center">
          <Box as="h2" className="h2-like">
            Une application moderne, idéale pour acheter dans le neuf
          </Box>
          <Text className="fz-24" textAlign="center" color="n_secondary">
            Recevez des programmes qui vous correspondent
          </Text>
        </Box>

        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection={{ ml: 'row', xs: 'column' }}
          textAlign="center"
          maxWidth={1200}
          mx="auto"
          mt={60}
        >
          <Box maxWidth={{ ml: 360, xs: '100%' }}>
            <img
              src="https://res.cloudinary.com/loueragile/image/upload/v1601456382/web/jinka/Icon01.svg"
              alt=""
            />
            <Box as="h3" className="fz-24" mt={30}>
              Toutes les annonces promoteurs
            </Box>
            <Text className="fz-18" mt={10}>
              Tous les sites des promoteurs analysés pour avoir le plus grand choix de programmes.
            </Text>
          </Box>
          <Box maxWidth={{ ml: 390, xs: '100%' }} mt={{ ml: 0, xs: 60 }}>
            <Box
              as="img"
              src="https://res.cloudinary.com/loueragile/image/upload/v1601456382/web/jinka/Icon02.svg"
              alt=""
            />
            <Box as="h3" className="fz-24" mt={30}>
              Recevez en 1<sup>er</sup> les avant-premières
            </Box>
            <Text className="fz-18 " mt={10}>
              Soyez le premier alerté dès qu'une nouvelle opportunité se présente.
            </Text>
          </Box>
          <Box maxWidth={{ ml: 360, xs: '100%' }} mt={{ ml: 0, xs: 60 }}>
            <img
              src="https://res.cloudinary.com/loueragile/image/upload/v1601456382/web/jinka/Icon03.svg"
              alt=""
            />
            <Box as="h3" className="fz-24" mt={30}>
              Comparez avec l'ancien
            </Box>
            <Text className="fz-18 " mt={10}>
              Recherchez et trouvez des logements anciens sur la même application.
            </Text>
          </Box>
        </Box>
      </Box>

      <CityBox as="section" mt={{ ml: 120, xs: 60 }} px={{ lg: 0, xs: 20 }}>
        <Box textAlign="center">
          <Box as="h2" className="h2-like">
            Disponible dans tous les départements d'Ile-de-France
          </Box>
          <Text className="fz-24" color="n_secondary">
            Et bientôt d’autres villes&nbsp;!
          </Text>
        </Box>

        <Box
          display={{ ml: 'flex', xs: 'block' }}
          justifyContent="space-between"
          alignItems="flex-end"
          maxWidth={995}
          mx="auto"
          mt={30}
        >
          <Box display="flex" flexDirection="column" alignItems="center" className="fls">
            <Box
              as="img"
              src="https://res.cloudinary.com/loueragile/image/upload/v1607335738/web/jinka/Illus-map.svg"
              alt="Carte"
            />
          </Box>
          <Box ml={{ ml: 70, xs: 0 }} mt={{ ml: 0, xs: 60 }}>
            <Box className="tags">
              {[
                'Paris (75)',
                'Hauts-de-seine (92)',
                'Seine-Saint-Denis (93)',
                'Val-de-marne (94)',
                "Val-d'Oise (95)",
                'Yvelines (78)',
                'Seine-et-marne (77)'
              ].map(x => (
                <Box as="button" key={x} className="tag" onClick={() => onCtaCityClick(x, 'glb')}>
                  <span>
                    <IconCity /> {x}
                  </span>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </CityBox>

      <Box
        as="section"
        mt={{ ml: 120, xs: 60 }}
        px={{ lg: 0, xs: 20 }}
        py={60}
        backgroundColor="#F6FBFF"
      >
        <Box textAlign="center">
          <Box as="h2" className="h2-like">
            Construisez votre futur en achetant dans neuf
          </Box>
          <Text className="fz-24" color="n_secondary">
            Acheter dans le neuf comporte beaucoup d'avantages
          </Text>
        </Box>

        <Box
          display="flex"
          flexDirection={{ ml: 'row', xs: 'column-reverse' }}
          justifyContent="space-between"
          alignItems="center"
          maxWidth={1000}
          mx="auto"
          mt={{ ml: 60, xs: 0 }}
        >
          <Box>
            <Box
              display="flex"
              flexDirection={{ sm: 'row', xs: 'column' }}
              alignItems={{ sm: 'flex-start', xs: 'center' }}
            >
              <Box
                as="img"
                src="https://res.cloudinary.com/loueragile/image/upload/v1601460926/web/jinka/IconB01.svg"
                alt=""
                mt="6px"
              />
              <Box
                ml={{ sm: 25, xs: 0 }}
                mt={{ sm: 0, xs: 20 }}
                textAlign={{ sm: 'left', xs: 'center' }}
              >
                <Text className="fz-20">
                  <strong>Des avantages fiscaux importants</strong>
                </Text>
                <Text className="fz-16" mt={10}>
                  Frais de notaires réduits (2,5 % contre 8 % dans l'ancien), exonération de la taxe
                  foncière pendant 2 ans, tva réduite dans certaines villes et jusqu'à 63 000 €
                  d’économie d’impôt avec la loi Pinel.
                </Text>
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection={{ sm: 'row', xs: 'column' }}
              mt={{ sm: 40, xs: 30 }}
              alignItems={{ sm: 'flex-start', xs: 'center' }}
            >
              <Box
                as="img"
                src="https://res.cloudinary.com/loueragile/image/upload/v1601460926/web/jinka/IconB02.svg"
                alt=""
                mt="6px"
              />
              <Box
                ml={{ sm: 25, xs: 0 }}
                mt={{ sm: 0, xs: 20 }}
                textAlign={{ sm: 'left', xs: 'center' }}
              >
                <Text className="fz-20">
                  <strong>Logements personalisables sans effort</strong>
                </Text>
                <Text className="fz-16" mt={10}>
                  Choisissez les revêtements, les matériaux, modifiez même le plan et l'agencement
                  des pièces. Supprimez des cloisons, une entrée, des couloirs... Tout cela sans
                  aucun travaux à gérer.
                </Text>
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection={{ sm: 'row', xs: 'column' }}
              mt={{ sm: 40, xs: 30 }}
              alignItems={{ sm: 'flex-start', xs: 'center' }}
            >
              <Box
                as="img"
                src="https://res.cloudinary.com/loueragile/image/upload/v1601460926/web/jinka/IconB03.svg"
                alt=""
                mt="6px"
              />
              <Box
                ml={{ sm: 25, xs: 0 }}
                mt={{ sm: 0, xs: 20 }}
                textAlign={{ sm: 'left', xs: 'center' }}
              >
                <Text className="fz-20">
                  <strong>Logements éco-responsables</strong>
                </Text>
                <Text className="fz-16" mt={10}>
                  Des logements avec un coût énergétique extrêmement réduit, voire nul. Protégez à
                  la fois votre portefeuille et la planète en émettant un minimun de CO2.
                </Text>
                <Box
                  as="img"
                  src="https://res.cloudinary.com/loueragile/image/upload/v1607419607/web/jinka/logo-re-2020.png"
                  alt=""
                  width={175}
                  mt={15}
                />
              </Box>
            </Box>
          </Box>
          <Box
            textAlign="center"
            className="fls"
            width={{ ml: 'auto', xs: '100%' }}
            ml={{ ml: 90, xs: 0 }}
            my={{ ml: 0, xs: 60 }}
          >
            <Box
              as="img"
              src="https://res.cloudinary.com/loueragile/image/upload/v1601460802/web/jinka/Illus-dialogue.svg"
              alt=""
              className="fls"
              mt={{ ml: '-20px', xs: 0 }}
            />
          </Box>
        </Box>
      </Box>

      <Box
        as="section"
        textAlign="center"
        mt={{ sm: 60, xs: 40 }}
        mb={{ md: 0, xs: 60 }}
        px={{ lg: 0, xs: 20 }}
      >
        <Box
          as="button"
          className="btn is-n_primary is-big"
          onClick={onCtaCreateClick}
          textAlign="center"
        >
          Télécharger Jinka
        </Box>
      </Box>

      <LogosPresse
        as="section"
        maxWidth={1200}
        mx="auto"
        mt={{ sm: 80, xs: 70 }}
        position="relative"
        zIndex="3"
      >
        <a
          href="http://www.leparisien.fr/immobilier/loueragile-l-outil-confidentiel-qui-vous-aide-a-denicher-votre-appart-a-paris-24-09-2019-8158407.php"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://res.cloudinary.com/loueragile/image/upload/v1601480071/web/jinka/Logo-presse01.png"
            alt=""
            className="img-rwd"
          />
        </a>
        <a
          href="https://business.lesechos.fr/entrepreneurs/financer-sa-creation/0600699138781-loueragile-veut-fluidifier-la-location-d-appartements-a-paris-327206.php"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://res.cloudinary.com/loueragile/image/upload/v1601480071/web/jinka/Logo-presse02.png"
            alt=""
            className="img-rwd"
          />
        </a>
        <a
          href="https://www.lemonde.fr/argent/article/2019/05/29/des-nouveaux-services-pour-les-jeunes-qui-cherchent-a-louer-un-logement_5468877_1657007.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://res.cloudinary.com/loueragile/image/upload/v1601480071/web/jinka/Logo-presse03.png"
            alt=""
            className="img-rwd"
          />
        </a>
        <a
          href="https://www.lebonbon.fr/paris/news/top-5-des-applis-pour-trouver-un-appart-a-la-rentree/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://res.cloudinary.com/loueragile/image/upload/v1601480071/web/jinka/Logo-presse04.png"
            alt=""
            className="img-rwd"
          />
        </a>
      </LogosPresse>
    </LandingStyled>
  )
}

export default LandingHomepage
