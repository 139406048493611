import React from 'react'
import styled from 'styled-components'
import { fetch_ad_id, qps_from_search } from '../../helpers/misc'
import { useImmerStore } from '../../store'
import { useFetchApi } from '../Shared/hooks'
import LoadingBar from '../Shared/LoadingBar'
import { Box } from '../Shared/Base'
import { dataLayer } from '../../store/analytics.middleware'
import {
  FormPhoneInput,
  FormTextFieldInput,
  FormRadioInput
  // FormFieldError
} from '../Shared/Form/Input'
import { valid_required, valid_phone, valid_email, valid_zip } from '../Shared/Form/validators'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { useLocation } from 'react-router-dom'
import FormField, { FocusableField, useFormState } from '../Shared/Form/Field'

const BoxForm = styled(Box)`
  label {
    display: none;
  }
  .form-radio {
    display: block;
  }
`

const form_config = {
  validators: {
    civility: { required: valid_required },
    firstname: { required: valid_required },
    lastname: { required: valid_required },
    postal_code: { required: valid_required, is_zip: valid_zip },
    phone: { required: valid_required, is_phone: valid_phone('FR') },
    email: { required: valid_required, is_email: valid_email }
  },
  initial_values: {
    civility: '',
    firstname: '',
    lastname: '',
    postal_code: '',
    phone: '',
    email: ''
  },
  filters: {
    email: v => v.trim()
  }
}
const FormContact = () => {
  const [submitted, set_submitted] = React.useState(false)
  const fetcher = useFetchApi()
  const [
    {
      db: { me }
    },
    set_state
  ] = useImmerStore()
  const location = useLocation()
  const qps = qps_from_search(location.search)
  const ad_id = qps.ad
  const token = qps.token

  const onCtaCreateClick = React.useCallback(() => {
    dataLayer.push({
      event: 'eventGA',
      category: 'CTAInstall',
      action: 'HPCreate'
    })
    set_state(draft => {
      draft.ui.modal_app_install_open = true
    })
  }, [set_state])

  const form = useFormState({
    initial_values: React.useMemo(
      () => ({ ...form_config.initial_values, email: (me && me.email) || '' }),
      [me]
    ),
    validators: form_config.validators,
    filters: form_config.filters,
    onSubmitSuccess: React.useCallback(
      async values => {
        await fetcher(`ad/${ad_id}/contact/form_neuf`, {
          method: 'POST',
          body: JSON.stringify({
            civility: values.civility,
            firstname: values.firstname,
            lastname: values.lastname,
            address: '',
            city: '',
            postal_code: values.postal_code,
            phone: parsePhoneNumberFromString(values.phone, 'FR').number,
            name: values.name,
            email: values.email
          })
        })
        set_submitted(true)
        dataLayer.push({
          event: 'eventGA',
          category: 'LeadNeuf'
        })
      },
      [fetcher, ad_id]
    )
  })

  return submitted ? (
    <>
      <Box
        as="p"
        variant="body"
        backgroundColor="bg_tertiary"
        p={20}
        borderRadius={5}
        color="tertiary"
        mt={30}
        className="fz-16"
      >
        <strong>Demande envoyée !</strong> <br />
        Le promoteur a été notifé de votre demande et vous contactera au plus vite.
        {!token && (
          <>
            &nbsp;Pour consulter l'ensemble nos programmes neufs veuillez télécharger notre
            application mobile.
            <br />
            <Box
              textAlign={{ sm: 'center', xs: 'center' }}
              width={{ sm: 'auto', xs: '100%' }}
              as="button"
              className="btn is-n_secondary is-big"
              onClick={onCtaCreateClick}
              mt={50}
            >
              Télécharger Jinka
            </Box>
          </>
        )}
      </Box>
    </>
  ) : (
    <Box as="form" mt={30} onSubmit={form.onSubmit} my={30} onChange={form.onChange}>
      <BoxForm>
        <Box mt={20}>
          <FormField
            showRequiredError={form.submittedOnce}
            name="civility"
            value={form.values.civility}
            validators={form.validators.civility}
            placeholder="Civilité"
            label="Civilité"
            errorLabels={{ required: 'Information obligatoire' }}
            options={[{ label: 'Madame', value: 'Mme' }, { label: 'Monsieur', value: 'Mr' }]}
          >
            {FormRadioInput}
          </FormField>
        </Box>
        <Box display={{ sm: 'flex', xs: 'block' }} justifyContent="space-between" mt={20}>
          <Box width="100%" mr={{ sm: 10, xs: 0 }}>
            <FocusableField
              showRequiredError={form.submittedOnce}
              name="firstname"
              value={form.values.firstname}
              validators={form.validators.firstname}
              placeholder="Prénom"
              errorLabels={{ required: 'Information obligatoire' }}
            >
              {FormTextFieldInput}
            </FocusableField>
          </Box>
          <Box width="100%" ml={{ sm: 10, xs: 0 }} mt={{ sm: 0, xs: 20 }}>
            <FocusableField
              showRequiredError={form.submittedOnce}
              name="lastname"
              value={form.values.lastname}
              validators={form.validators.lastname}
              placeholder="Nom"
              errorLabels={{ required: 'Information obligatoire' }}
            >
              {FormTextFieldInput}
            </FocusableField>
          </Box>
        </Box>
        <Box mt={20}>
          <FocusableField
            showRequiredError={form.submittedOnce}
            name="postal_code"
            value={form.values.postal_code}
            validators={form.validators.postal_code}
            placeholder="Code postal"
            type="number"
            errorLabels={{ required: 'Information obligatoire', is_zip: 'Code postal incorrect' }}
          >
            {FormTextFieldInput}
          </FocusableField>
        </Box>
        <Box display={{ sm: 'flex', xs: 'block' }} justifyContent="space-between" mt={20}>
          <Box width="100%" mr={{ sm: 10, xs: 0 }}>
            <FocusableField
              showRequiredError={form.submittedOnce}
              name="email"
              value={form.values.email}
              validators={form.validators.email}
              placeholder="E-mail"
              errorLabels={{ required: 'Information obligatoire', is_email: 'E-mail incorrect' }}
            >
              {FormTextFieldInput}
            </FocusableField>
          </Box>
          <Box width="100%" ml={{ sm: 10, xs: 0 }} mt={{ sm: 0, xs: 20 }}>
            <FocusableField
              type="tel"
              showRequiredError={form.submittedOnce}
              name="phone"
              value={form.values.phone}
              validators={form.validators.phone}
              placeholder="Numéro de téléphone"
            >
              {FormPhoneInput}
            </FocusableField>
          </Box>
        </Box>
      </BoxForm>
      <Box
        as="button"
        className="btn is-medium is-tertiary"
        display="inline-block"
        width={{ sm: 'auto', xs: '100%' }}
        textAlign="center"
        mt={30}
        disabled={form.loading}
      >
        Envoyer
      </Box>
    </Box>
  )
}

const AdContactNeuf = ({ location, history }) => {
  const fetcher = useFetchApi()
  const [{ db }, set_state] = useImmerStore()
  const db_ad = db.ad
  const qps = qps_from_search(location.search)
  const ad_id = qps.ad

  React.useEffect(() => {
    const init = async () => {
      const { ad } = await fetch_ad_id(fetcher, ad_id)
      dataLayer.push({
        event: 'eventGA',
        category: 'AdContactNeuf',
        action: ad.source,
        label: ad.id
      })

      set_state(draft => {
        draft.db.ad[ad_id] = ad
      })
    }
    init()
  }, [ad_id, fetcher, set_state])

  if (db_ad && db_ad[ad_id]) {
    const ad = db_ad[ad_id]

    const message = ad.new_real_estate
      ? `Besoin d'informations sur le programme "${ad.new_real_estate.programme_name}"`
      : `Besoin d'informations sur le bien`

    if (ad.expired_at) {
      return (
        <Box
          px={{ lg: 0, xs: 20 }}
          pt={{ sm: 60, xs: 30 }}
          pb={{ sm: 150, xs: 30 }}
          maxWidth={550}
          mx="auto"
        >
          <Box mt={{ md: 0, xs: 30 }}>
            <Box
              backgroundColor="bg_negative"
              mb={30}
              className="fz-18"
              color="negative"
              textAlign="center"
              borderRadius={5}
              p={20}
            >
              Ce programme n'est plus disponible.
            </Box>
          </Box>
        </Box>
      )
    }
    return (
      <Box
        px={{ lg: 0, xs: 20 }}
        pt={{ sm: 60, xs: 30 }}
        pb={{ sm: 150, xs: 30 }}
        maxWidth={550}
        mx="auto"
      >
        <Box mt={{ md: 0, xs: 0 }}>
          <Box
            backgroundColor="bg_secondary"
            mb={30}
            className="fz-18"
            color="secondary"
            textAlign="center"
            borderRadius={5}
            p={20}
          >
            {message} ? Indiquez vos coordonnées pour être recontacté.
          </Box>
          <FormContact />
        </Box>
      </Box>
    )
  }

  return <LoadingBar />
}

export default AdContactNeuf
