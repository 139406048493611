import { AsYouType } from 'libphonenumber-js'
import React from 'react'
import MaskedInput from 'react-text-mask'
import { Icon } from '@material-ui/core'
import { IconVisibility, IconVisibilityOff } from '../../Shared/Svg/Icons'
import { withStateHandlers } from 'recompose'

import { Box, Text } from '../Base'

const default_error_labels = {
  required: 'Champ obligatoire',
  date: 'Date invalide',
  is_phone: 'Téléphone invalide',
  is_email: 'E-mail invalide'
}
export const FormFieldError = ({ showError, error, errorLabels }) =>
  showError && (
    <Box as="p" color="negative" mt={10} className="error-scrollable">
      {(errorLabels && errorLabels[error]) || default_error_labels[error] || error}
    </Box>
  )

const noop = () => {}
export const Input = ({
  innerRef,
  type,
  autoFocus,
  onFocus,
  onBlur,
  onChange = noop,
  placeholder,
  autoComplete,
  name,
  value,
  disabled,
  style,
  OtherProps
}) => (
  <input
    autoFocus={autoFocus}
    ref={innerRef}
    className="form-input"
    type={type}
    onFocus={onFocus}
    onBlur={onBlur}
    onChange={onChange}
    value={value}
    name={name}
    id={name}
    placeholder={placeholder}
    disabled={disabled}
    style={style}
    autoComplete={autoComplete}
    {...OtherProps}
  />
)

export const FormTextFieldInput = ({
  innerRef,
  value,
  error,
  name,
  focused,
  autoFocus,
  autoComplete,
  onFocus,
  onBlur,
  onChange,
  errorLabels,
  label,
  disabled,
  placeholder,
  type,
  style
}) => {
  const showError = Boolean(error) && !focused
  return (
    <>
      {label && (
        <label className="form-label" htmlFor={name}>
          {label}
        </label>
      )}
      <Input
        autoFocus={autoFocus}
        type={type}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={onChange}
        value={value}
        name={name}
        placeholder={placeholder}
        hasError={showError}
        disabled={disabled}
        style={style}
        innerRef={innerRef}
        autoComplete={autoComplete}
      />
      <FormFieldError showError={showError} error={error} errorLabels={errorLabels} />
    </>
  )
}

export const FormRadioInput = ({ label, name, options, value, error, errorLabels }) => {
  return (
    <>
      <Text className="form-label">{label}</Text>
      <Box display="flex">
        {options.map((opt, i) => (
          <Box as="label" ml={i > 0 ? 30 : 0} className="form-radio" mb={0} key={opt.value}>
            {opt.label}
            <input
              type="radio"
              name={name}
              value={opt.value}
              checked={value === opt.value}
              onChange={noop}
            />
            <span className="checkmark" />
          </Box>
        ))}
      </Box>
      <FormFieldError showError={Boolean(error)} error={error} errorLabels={errorLabels} />
    </>
  )
}

export const FormTextareaInput = ({
  value,
  error,
  name,
  focused,
  onFocus,
  onBlur,
  onChange = noop,
  errorLabels,
  label,
  placeholder,
  rows
}) => {
  const showError = Boolean(error) && !focused
  return (
    <>
      <label className="form-label" htmlFor={name}>
        {label}
      </label>
      <Box
        as="textarea"
        className="form-textarea"
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={onChange}
        value={value}
        name={name}
        id={name}
        placeholder={placeholder}
        rows={rows}
        minHeight={200}
      />
      <FormFieldError showError={showError} error={error} errorLabels={errorLabels} />
    </>
  )
}

export const FormSelectNativeInput = ({
  value,
  error,
  name,
  focused,
  onFocus,
  onBlur,
  errorLabels,
  label,
  disabled,
  options,
  disableNoValueOption,
  noValueLabel = 'Sélectionner un choix',
  hasPlaceholderStyleOnNoValue = true,
  selectClassName = 'form-select',
  onChange = noop
}) => {
  const showError = Boolean(error) && !focused
  const className = `${selectClassName} ${
    Boolean(value) ? '' : hasPlaceholderStyleOnNoValue ? 'placeholder' : ''
  }`
  return (
    <>
      {label !== null && (
        <label className="form-label" htmlFor={name}>
          {label}
        </label>
      )}
      <select
        className={className}
        value={value}
        onChange={onChange}
        name={name}
        id={name}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={disabled}
      >
        {(disableNoValueOption
          ? []
          : [
              <option key="optdef" value="" className="placeholder">
                {noValueLabel}
              </option>
            ]
        ).concat(
          options.map(o => (
            <option key={o.value} value={o.value}>
              {o.label}
            </option>
          ))
        )}
      </select>
      <FormFieldError showError={showError} error={error} errorLabels={errorLabels} />
    </>
  )
}

export const FormPhoneInput = props => (
  <FormTextFieldInput {...props} value={new AsYouType('FR').input(props.value)} />
)

export const FormInlineDateInput = ({
  value,
  error,
  name,
  focused,
  onFocus,
  onBlur,
  onChange,
  errorLabels,
  label,
  disabled,
  autoComplete,
  placeholder = 'JJ/MM/AAAA'
}) => {
  const showError = Boolean(error) && !focused
  return (
    <>
      <label className="form-label" htmlFor={name}>
        {label}
      </label>
      <MaskedInput
        value={value}
        name={name}
        id={name}
        placeholder={placeholder}
        hasError={showError}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={onChange}
        disabled={disabled}
        mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
        render={(ref, props) => <Input type="tel" innerRef={ref} {...props} />}
        autoComplete={autoComplete}
      />
      <FormFieldError showError={showError} error={error} errorLabels={errorLabels} />
    </>
  )
}

export const FormMaskedInput = ({
  value,
  error,
  name,
  autoFocus,
  focused,
  onFocus,
  onBlur,
  onChange,
  errorLabels,
  label,
  mask,
  placeholder,
  type = 'text'
}) => {
  const showError = Boolean(error) && !focused
  return (
    <>
      <label className="form-label" htmlFor={name}>
        {label}
      </label>
      <MaskedInput
        value={value}
        autoFocus={autoFocus}
        id={name}
        name={name}
        placeholder={placeholder}
        hasError={showError}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={onChange}
        mask={mask}
        render={(ref, props) => <Input type={type} innerRef={ref} {...props} />}
      />
      <FormFieldError showError={showError} error={error} errorLabels={errorLabels} />
    </>
  )
}

const PasswordInput = withStateHandlers(
  { revealed: false },
  {
    onRevealedToggle: ({ revealed }) => () => ({ revealed: !revealed })
  }
)(({ revealed, onRevealedToggle, ...rest }) => (
  <div style={{ position: 'relative' }}>
    <Input type={revealed ? 'text' : 'password'} {...rest} />
    <div
      style={{ position: 'absolute', top: 'calc(50% - 12px)', right: '15px', cursor: 'pointer' }}
    >
      <Icon aria-label="Toggle password visibility" onClick={onRevealedToggle}>
        {revealed ? (
          <IconVisibilityOff width={26} height={24} stroke="var(--colorLight)" />
        ) : (
          <IconVisibility width={26} height={24} stroke="var(--colorLight)" />
        )}
      </Icon>
    </div>
  </div>
))

export const FormPasswordInput = ({
  value,
  error,
  name,
  focused,
  onFocus,
  onBlur,
  onChange,
  errorLabels,
  label,
  placeholder
}) => {
  const showError = Boolean(error) && !focused
  return (
    <>
      {label && <label className="form-label">{label}</label>}
      <PasswordInput
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={onChange}
        value={value}
        name={name}
        placeholder={placeholder}
        hasError={showError}
      />
      <FormFieldError showError={showError} error={error} errorLabels={errorLabels} />
    </>
  )
}

export const FormCheckboxInput = ({
  label,
  name,
  value,
  disabled,
  onChange,
  formCheckboxStyle
}) => {
  return (
    <Box as="label" className="form-checkbox" style={formCheckboxStyle}>
      {label}
      <input
        type="checkbox"
        checked={value}
        onChange={() =>
          onChange({
            target: {
              name,
              value: !value
            }
          })
        }
        disabled={disabled}
      />
      <span className="checkmark" />
    </Box>
  )
}

export const FormCheckboxesInput = ({ choices, value, onChange }) => {
  return (
    <>
      {choices.map(choice => {
        return (
          <label key={choice.value} className="form-checkbox">
            {choice.label}
            <input
              type="checkbox"
              checked={value.indexOf(choice.value) > -1}
              onChange={e => {
                if (e.target.checked) {
                  onChange([...value, choice.value])
                } else {
                  onChange(value.filter(v => v !== choice.value))
                }
              }}
            />
            <span className="checkmark" />
          </label>
        )
      })}
    </>
  )
}
