import React from 'react'
import styled from 'styled-components'
import Helmet from 'react-helmet'
import { Box, Text } from '../Shared/Base'
import { useImmerStore } from '../../store'

const IconCheck = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="none" viewBox="0 0 50 50">
    <circle cx="26.293" cy="26.293" r="23.707" fill="#6EA668" />
    <rect width="46.414" height="46.414" x=".5" y=".5" fill="#fff" rx="23.207" />
    <path
      fill="#6EA668"
      fillRule="evenodd"
      d="M36.307 12.667a2.334 2.334 0 0 1 .45 3.22l-13.5 18.4c-.392.533-.99.867-1.64.914a2.223 2.223 0 0 1-1.751-.668l-9-9.2a2.336 2.336 0 0 1 0-3.252 2.216 2.216 0 0 1 3.182 0l7.165 7.324 11.944-16.278a2.218 2.218 0 0 1 3.15-.46Z"
      clipRule="evenodd"
    />
    <rect width="46.414" height="46.414" x=".5" y=".5" stroke="#6EA668" rx="23.207" />
  </svg>
)

const LandingStyled = styled.main`
  color: var(--colorN_primary);

  .btn.is-medium {
    font-size: 1.8rem;
    padding: 16px 30px;
  }

  .h1-like {
    font-family: 'nunito';
    font-size: 4.4rem;
    line-height: 5.4rem;
    font-weight: 800;

    @media screen and (max-width: 1230px) {
      font-size: 3.6rem;
      line-height: 4.6rem;
    }
  }

  .h2-like {
    font-family: 'nunito';
    font-size: 3.6rem;
    line-height: 4.6rem;
  }

  .h2-likes {
    font-family: 'nunito';
    font-size: 4.2rem;
    line-height: 5rem;
  }

  .bottom {
    background-image: url('https://res.cloudinary.com/loueragile/image/upload/v1696837487/web/jinka/BG_landing_bottom.png');
    background-position: center top;
    background-repeat: no-repeat;
    padding: 80px 0 120px 0;
  }
`

const FirstBox = styled(Box)`
  background: linear-gradient(180deg, rgba(162, 221, 255, 0.24) 0%, rgba(255, 255, 255, 0) 33.47%);
  position: relative;

  @media screen and (max-width: 1110px) {
    br {
      display: none;
    }
  }

  .illustration {
    width: 479px;

    @media screen and (max-width: 1140px) {
      width: 479px;
    }
    @media screen and (max-width: 1000px) {
      width: 400px;
    }
  }
`

const LandingHomepage = () => {
  const [, set_state] = useImmerStore()
  const onCtaDownloadClick = React.useCallback(() => {
    set_state(draft => {
      draft.ui.modal_app_chasseloc_open = true
    })
  }, [set_state])

  return (
    <LandingStyled>
      <Helmet>
        <title>Emménagez en 3 semaines grâce à votre chasseur privé - Jinka</title>
        <meta
          name="description"
          content="Évitez le parcours du combattant que représente la recherche d'un logement et faites appel à un chasseur expérimenté."
        />
        <meta
          property="og:title"
          content="Emménagez en 3 semaines grâce à votre chasseur privé - Jinka"
        />
        <meta
          property="og:description"
          content="Évitez le parcours du combattant que représente la recherche d'un logement et faites appel à un chasseur expérimenté."
        />
        <meta property="og:url" content="https://www.jinka.fr/" />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/loueragile/image/upload/v1696837252/web/jinka/Image_OG_location.jpg"
        />
        <link rel="canonical" href="https://www.jinka.fr/" />
      </Helmet>

      <FirstBox as="section" maxWidth="100%" mx="auto" px={{ lg: 0, xs: 20 }} pt={160}>
        <Box
          maxWidth={1200}
          mx="auto"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexDirection={{ md: 'row', xs: 'column-reverse' }}
          position="relative"
        >
          <Box width={{ lg: 690, xs: '100%' }}>
            <Box as="h1" className="h1-like" textAlign={{ md: 'left', xs: 'center' }}>
              <Box as="span" color="n_secondary">
                Emménagez en 3 semaines <Box as="br" display={{ sm: 'block', xs: 'none' }} />
              </Box>{' '}
              grâce à votre chasseur privé
            </Box>
            <Text
              fontSize={32}
              lineHeight="4.4rem"
              mt={20}
              textAlign={{ md: 'left', xs: 'center' }}
            >
              Évitez le parcours du combattant que représente la recherche d'un logement et faites
              appel à un chasseur expérimenté.
            </Text>
            <Box textAlign={{ md: 'left', xs: 'center' }}>
              <Box
                as="button"
                className="btn is-n_primary is-big"
                onClick={onCtaDownloadClick}
                mt={40}
              >
                Ouvrir un compte
              </Box>
            </Box>
          </Box>
          <Box
            as="img"
            src="https://res.cloudinary.com/loueragile/image/upload/f_auto/v1696320480/web/jinka/Location_01.png"
            alt="Photo"
            mb={{ md: 0, xs: 50 }}
            ml={{ md: 30, xs: 0 }}
            mr={{ ml: '-32px', xs: '0' }}
            // ml={{ ml: '0', xs: '32px' }}
            className="illustration"
          />
        </Box>
      </FirstBox>

      <Box as="section" mt={{ ml: 120, xs: 60 }} px={{ lg: 0, xs: 20 }}>
        <Box
          display="flex"
          flexDirection={{ ml: 'row', xs: 'column' }}
          justifyContent="space-between"
          alignItems="center"
          maxWidth={1200}
          mx="auto"
        >
          <Box textAlign="center" className="fls" width={{ ml: '30%', xs: '100%' }}>
            <Box
              as="img"
              src="https://res.cloudinary.com/loueragile/image/upload/v1696320480/web/jinka/Location_02.png"
              alt=""
              className="fls"
              ml={{ ml: '-45px', xs: '0' }}
              // mr={{ ml: '0', xs: '40px' }}
            />
          </Box>
          <Box width={{ ml: '65%', xs: '100%' }} mt={{ ml: 0, xs: 30 }}>
            <Text fontSize={26} color="n_secondary" textAlign={{ ml: 'left', xs: 'center' }}>
              En seulement quelques jours
            </Text>
            <Box
              as="h2"
              className="h2-likes"
              fontSize={42}
              textAlign={{ ml: 'left', xs: 'center' }}
            >
              Et avec 100 % d'exclusivités ?
            </Box>
            <Box
              as="hr"
              borderColor="decoration"
              maxWidth={100}
              mx={{ ml: '0', xs: 'auto' }}
              my={40}
            />
            <Text fontSize={24} lineHeight="3.6rem" textAlign={{ ml: 'left', xs: 'center' }}>
              Chez Jinka pas de fausses promesses ! Nous sommes experts du marché locatif et nous
              sommes là pour vous conseiller en fonction de votre profil, que vous soyez étudiant,
              parent, jeune actif, en couple ou en famille.
            </Text>
          </Box>
        </Box>
      </Box>

      <Box as="section" mt={{ ml: 120, xs: 60 }} px={{ lg: 0, xs: 20 }}>
        <Box
          display="flex"
          flexDirection={{ ml: 'row', xs: 'column-reverse' }}
          justifyContent="space-between"
          alignItems="center"
          maxWidth={1200}
          mx="auto"
        >
          <Box width={{ ml: '65%', xs: '100%' }} mt={{ ml: 0, xs: 30 }}>
            <Text fontSize={26} color="n_secondary" textAlign={{ ml: 'left', xs: 'center' }}>
              Réalité du marché
            </Text>
            <Box
              as="h2"
              className="h2-likes"
              fontSize={42}
              textAlign={{ ml: 'left', xs: 'center' }}
            >
              Nous nous adaptons
            </Box>
            <Box
              as="hr"
              borderColor="decoration"
              maxWidth={100}
              mx={{ ml: '0', xs: 'auto' }}
              my={40}
            />
            <Text fontSize={24} lineHeight="3.6rem" textAlign={{ ml: 'left', xs: 'center' }}>
              Arnaques, concurrence, chute du marché à l'achat, arrivée des JO 2024. Autant de
              raisons qui compliquent vos recherches. Soyez rassurés, nos chasseurs immobiliers
              savent composer avec la réalité du terrain.
            </Text>
          </Box>
          <Box textAlign="center" className="fls" width={{ ml: '30%', xs: '100%' }}>
            <Box
              as="img"
              src="https://res.cloudinary.com/loueragile/image/upload/v1696320480/web/jinka/Location_03.png"
              alt=""
              className="fls"
            />
          </Box>
        </Box>
      </Box>

      <Box as="section" mt={{ ml: 120, xs: 60 }} px={{ lg: 0, xs: 20 }}>
        <Box maxWidth={1200} mx="auto">
          <Box as="h2" className="h2-likes" fontSize={42} textAlign={{ ml: 'left', xs: 'center' }}>
            Alors{' '}
            <Box as="span" color="n_secondary">
              évitez les arnaques
            </Box>{' '}
            et{' '}
            <Box as="span" color="n_secondary">
              gagnez du temps
            </Box>{' '}
            en faisant appel à notre service de chasse immobilière dès à présent !
          </Box>
        </Box>
      </Box>

      <Box as="section" maxWidth={1200} mx="auto" mt={{ ml: 40, xs: 40 }} px={{ lg: 0, xs: 20 }}>
        <Box
          display="flex"
          flexDirection={{ ml: 'row', xs: 'column' }}
          alignItems="center"
          justifyContent="center"
          mx="auto"
        >
          <Box width={{ ml: '45%', xs: '100%' }}>
            <Box display="flex" flexDirection={{ ml: 'row', xs: 'column' }} alignItems="center">
              <IconCheck />
              <Box
                ml={{ sm: 20, xs: 0 }}
                textAlign={{ ml: 'left', xs: 'center' }}
                mt={{ ml: 0, xs: 10 }}
              >
                <Text fontSize={{ ml: '2.4rem', xs: '2.4rem' }} lineHeight="2.8rem">
                  Définition de votre cahier des charges
                </Text>
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection={{ ml: 'row', xs: 'column' }}
              alignItems="center"
              mt={25}
            >
              <IconCheck />
              <Box
                ml={{ sm: 20, xs: 0 }}
                textAlign={{ ml: 'left', xs: 'center' }}
                mt={{ ml: 0, xs: 10 }}
              >
                <Text fontSize={{ ml: '2.4rem', xs: '2.4rem' }} lineHeight="2.8rem">
                  Montage de votre dossier de location
                </Text>
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection={{ ml: 'row', xs: 'column' }}
              alignItems="center"
              mt={25}
            >
              <IconCheck />
              <Box
                ml={{ sm: 20, xs: 0 }}
                textAlign={{ ml: 'left', xs: 'center' }}
                mt={{ ml: 0, xs: 10 }}
              >
                <Text fontSize={{ ml: '2.4rem', xs: '2.4rem' }} lineHeight="2.8rem">
                  Recherche et prise de rendez-vous
                </Text>
              </Box>
            </Box>
          </Box>
          <Box width={{ ml: '55%', xs: '100%' }} mt={{ ml: 0, xs: 20 }}>
            <Box display="flex" flexDirection={{ ml: 'row', xs: 'column' }} alignItems="center">
              <IconCheck />
              <Box
                ml={{ sm: 20, xs: 0 }}
                textAlign={{ ml: 'left', xs: 'center' }}
                mt={{ ml: 0, xs: 10 }}
              >
                <Text fontSize={{ ml: '2.4rem', xs: '2.4rem' }} lineHeight="2.8rem">
                  Visites (accompagnement ou substitution)
                </Text>
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection={{ ml: 'row', xs: 'column' }}
              alignItems="center"
              mt={25}
            >
              <IconCheck />
              <Box
                ml={{ sm: 20, xs: 0 }}
                textAlign={{ ml: 'left', xs: 'center' }}
                mt={{ ml: 0, xs: 10 }}
              >
                <Text fontSize={{ ml: '2.4rem', xs: '2.4rem' }} lineHeight="2.8rem">
                  Relecture du bail
                </Text>
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection={{ ml: 'row', xs: 'column' }}
              alignItems="center"
              mt={25}
            >
              <IconCheck />
              <Box
                ml={{ sm: 20, xs: 0 }}
                textAlign={{ ml: 'left', xs: 'center' }}
                mt={{ ml: 0, xs: 10 }}
              >
                <Text fontSize={{ ml: '2.4rem', xs: '2.4rem' }} lineHeight="2.8rem">
                  Emménagement grâce à notre service de conciergerie
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box as="section" maxWidth={1200} mx="auto" mt={{ ml: 60, xs: 40 }} px={{ lg: 0, xs: 20 }}>
        <Box textAlign={{ md: 'center', xs: 'center' }}>
          <Box as="button" className="btn is-n_primary is-big" onClick={onCtaDownloadClick}>
            Ouvrir un compte
          </Box>
        </Box>
      </Box>

      <Box as="section" mt={{ ml: 100, xs: 100 }} className="bottom">
        <Box textAlign="center" px={{ lg: 0, xs: 20 }}>
          <Box>
            <Box
              as="img"
              src="https://res.cloudinary.com/loueragile/image/upload/v1601461848/web/jinka/Quote.svg"
              alt=""
            />
          </Box>
          <Box as="h2" className="h2-like" mt={30}>
            Nos clients recommandent Jinka
          </Box>
          <Box className="fz-24" color="n_secondary">
            Le meilleur allié des locataires
          </Box>

          <Box as="a" href="https://fr.trustpilot.com/review/jinka.fr" target="_blank">
            <Box
              as="img"
              src="https://res.cloudinary.com/loueragile/image/upload/v1696419882/web/jinka/Trust.png"
              alt="Trustpilot 4.3"
              width={675}
              className="fls"
            />
          </Box>
        </Box>
      </Box>
    </LandingStyled>
  )
}

export default LandingHomepage
