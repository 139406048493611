import { createTheme } from '@material-ui/core/styles'
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'

export const theme = createTheme({
  breakpoints: createBreakpoints({
    values: {
      xs: 0,
      sm: 600,
      ms: 767,
      md: 960,
      ml: 1024,
      lg: 1280,
      xl: 1440
    }
  }),
  // withWidth component ⚛️
  MuiWithWidth: {
    // Initial width property
    initialWidth: 'lg' // Breakpoint being globally set
  },
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, 0.2)'
      }
    },
    MuiPaper: {
      root: {
        color: 'var(--colorText)'
      },
      elevation6: {
        boxShadow: '0px 0px 30px 5px rgba(0,0,0,0.03)'
      },
      elevation24: {
        boxShadow: '0px 0px 30px 5px rgba(0,0,0,0.08)'
      }
    },
    MuiDrawer: {
      paper: {
        boxShadow: '0px 0px 30px 5px rgba(0,0,0,0.03)'
      }
    },
    MuiDialogContent: {
      root: {
        padding: '12px 24px',
        paddingBottom: '24px'
      }
    },
    MuiSnackbar: {
      root: {
        top: '30px !important'
      }
    }
  }
})

// console.log('theme', theme)

// breakpoints of mui breaks styled-systems
const { sm, ms, md, ml, lg, xl } = theme.breakpoints.values
const breakpoints = [sm, ms, md, ml, lg, xl]
breakpoints.sm = sm
breakpoints.ms = ms
breakpoints.md = md
breakpoints.ml = ml
breakpoints.lg = lg
breakpoints.xl = xl

export const theme_for_styled = {
  ...theme,
  breakpoints,
  colors: {
    text: '#5c5c5c',
    primary: '#F08821',
    secondary: '#1DA0CA',
    tertiary: ' #6EA668',
    light: '#919191',
    thin: '#dddcdc',
    negative: '#D95C5C',
    white: '#ffffff',
    warning: '#F0973F',
    premium: '#C26CC4',
    alternative: '#F6F6EF',
    bg_primary: '#FFF4EA',
    bg_secondary: '#E3F2FD',
    bg_tertiary: '#E5F8E3',
    bg_negative: '#FBD8D8',
    bg_premium: '#ECC3ED',
    layout: '#F9F9F9',
    //
    n_primary: '#33547B',
    n_secondary: '#B361B5',
    bg_n_secondary: '#F1DDF1',
    bg_n_tertiary: '#E3F2FD',
    n_tertiary: '#5A8FB6',
    decoration: '#F2C110'
  }
}
