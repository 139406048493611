import { css } from 'styled-components'

const Buttons = css`
  /* -------------------------------------------------- */
  /* .btn
  /* -------------------------------------------------- */

  .btn {
    border-radius: 5px;
    color: white;
    display: inline-block;
    line-height: normal;

    &.is-primary {
      background-color: var(--colorPrimary);

      &:hover {
        background-color: #eb7e13;
      }
    }

    &.is-n_primary {
      background-color: var(--colorN_primary);

      &:hover {
        background-color: var(--colorN_primary);
      }
    }

    &.is-n_secondary {
      background-color: var(--colorN_secondary);

      &:hover {
        background-color: var(--colorN_secondary);
      }
    }

    &.is-secondary {
      background-color: var(--colorSecondary);

      &:hover {
        background-color: #1d96ca;
      }
    }

    &.is-tertiary {
      background-color: var(--colorTertiary);

      &:hover {
        background-color: #639a5d;
      }
    }

    &.is-negative {
      background-color: var(--colorNegative);
    }

    &.is-premium {
      background-color: var(--colorPremium);
    }

    /* */

    &.is-white {
      background-color: white;
      color: var(--colorText);
    }

    /* */

    &.is-outlined {
      background-color: white;
      border: 1px solid var(--colorThin);
      color: var(--colorText);

      &:hover {
        border: 1px solid var(--colorText);
      }
    }

    &.is-outlined.is-secondary {
      border: 2px solid var(--colorN_secondary);
      color: var(--colorN_secondary);

      &:hover {
        background-color: white;
      }
    }

    &.is-outlined.is-negative {
      border: 1px solid var(--colorNegative);
      color: var(--colorNegative);

      &:hover {
        background-color: white;
      }
    }

    &.is-outlined.is-tertiary {
      border: 1px solid var(--colorTertiary);
      color: var(--colorTertiary);

      &:hover {
        background-color: white;
      }
    }

    /* */

    &.is-tiny {
      padding: 10px 15px;
      font-size: 1.4rem;
      line-height: 2.2rem;
    }

    &.is-medium {
      padding: 13px 30px;
      font-size: 1.6rem;
      line-height: 2.4rem;
    }

    &.is-big {
      padding: 17px 30px;
      font-size: 2rem;
      line-height: 2.6rem;
    }

    /* */

    &:disabled,
    &:disabled:hover {
      ${'' /* background-color: var(--colorThin); */}
      opacity: 0.3;
      /* color: var(--colorLight); */
      cursor: auto;
    }
  }

  /* -------------------------------------------------- */
  /* .btn-landing
  /* -------------------------------------------------- */

  .btn-landing {
    ${'' /* font-size: 2.4rem;
    border: 1px solid var(--colorTertiary);
    color: var(--colorTertiary);
    padding: 25px 50px;
    border-radius: 5px;
    text-align: center; */}

    ${'' /* background-color: var(--colorTertiary);
    border-radius: 5px;
    color: white;
    display: inline-block;
    line-height: normal;
    text-align: center;

    font-size: 2.4rem;
    padding: 25px 50px;

    @media screen and (max-width: 767px) {
      font-size: 2rem;
      width: 100%;
      padding: 25px 0;
    }

    &:hover {
      background-color: var(--colorBg_tertiary);
    } */}
  }

  /* -------------------------------------------------- */
  /* .btn-link
  /* -------------------------------------------------- */

  .btn-link {
    color: var(--colorN_tertiary);
    text-decoration: underline;

    &:hover {
      color: #0e8cac;
    }
  }

  /* -------------------------------------------------- */
  /* .btn-simple
  /* -------------------------------------------------- */

  .btn-simple {
    display: inline-flex;
    align-items: center;
    color: var(--colorSecondary);

    &:hover {
      color: #0e8cac;
    }
  }

  /* -------------------------------------------------- */
  /* .btn-negative
  /* -------------------------------------------------- */

  .btn-negative {
    &:hover {
      color: var(--colorNegative);
    }
  }
`

export default Buttons
