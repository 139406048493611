import { createSelector } from 'reselect'

const initialState = {
  location: {
    pathname: ''
  }
}

// /!\  This reducer is updated 1 step before react-router render the good component, prefer props
const router_location_selector = (state, props) => {
  return (props && props.location) || state.router.location
}

export const router_pathname_selector = createSelector(
  router_location_selector,
  router_location => {
    return router_location.pathname
  }
)

export const router_params_selector_creator = ({ key, routes }) =>
  createSelector(
    router_pathname_selector,
    router_pathname => {
      const match = routes.map(r => r.match(router_pathname)).filter(x => Boolean(x))[0]

      if (match) return match[key]
      return null
    }
  )

const reducer = (state = initialState, action) => {
  if (action.type === 'router_changeLocation') {
    return {
      location: action.location
    }
  }

  return state
}

export default reducer
