export const with_res_db_adpremium = res_db_adpremium => action => ({
  res_db_adpremium,
  ...action
})

export const db_adpremium_selector = state => state.db.adpremium

const reducer = (state = {}, action) => {
  if (action.res_db_adpremium) {
    const { id } = action.res_db_adpremium
    return {
      ...state,
      [id]: action.res_db_adpremium
    }
  }

  return state
}

export default reducer
