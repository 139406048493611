export const with_res_db_ad_application = res_db_ad_application => action => ({
  res_db_ad_application,
  ...action
})

export const db_ad_application_selector = state => state.db.ad_application

const reducer = (state = {}, action) => {
  if (action.res_db_ad_application) {
    const { id } = action.res_db_ad_application
    return {
      ...state,
      [id]: action.res_db_ad_application
    }
  }

  return state
}

export default reducer
