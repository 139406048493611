import { IconTrain, IconPlace } from '../../Shared/Svg/Icons'
import React from 'react'
import { components } from 'react-select'
import AsyncSelect from 'react-select/async'

import { Box, Text } from '../Base'
import { useFetchApi } from '../hooks'
import { FormFieldError } from './Input'

const get_suggestions = async (place, agglomeration, fetcher) => {
  const postalCodes = [
    { name: '75001', type: 'zip' },
    { name: '75002', type: 'zip' },
    { name: '75003', type: 'zip' },
    { name: '75004', type: 'zip' },
    { name: '75005', type: 'zip' },
    { name: '75006', type: 'zip' },
    { name: '75007', type: 'zip' },
    { name: '75008', type: 'zip' },
    { name: '75009', type: 'zip' },
    { name: '75010', type: 'zip' },
    { name: '75011', type: 'zip' },
    { name: '75012', type: 'zip' },
    { name: '75013', type: 'zip' },
    { name: '75014', type: 'zip' },
    { name: '75015', type: 'zip' },
    { name: '75016', type: 'zip' },
    { name: '75116', type: 'zip' },
    { name: '75017', type: 'zip' },
    { name: '75018', type: 'zip' },
    { name: '75019', type: 'zip' },
    { name: '75020', type: 'zip' }
  ]

  if (place.length < 2) {
    return []
  }

  if (place.match(/^75[0-9]{0,3}$/) && agglomeration === 'paris') {
    const postalCodesAutocomplete = postalCodes.filter(
      postalCode => postalCode.name.lastIndexOf(place, 0) === 0
    )
    if (postalCodesAutocomplete.length > 0) {
      return postalCodesAutocomplete
    }

    return []
  } else {
    return fetcher(`alert/places?name=${place}&agglomeration=${agglomeration}`)
  }
}

export const FormInputLocalisationLabel = ({ suggestion }) => {
  switch (suggestion.type) {
    case 'city':
      return (
        <Box display="flex" alignItems="center">
          <IconPlace width={20} height={20} fill="var(--colorText)" />
          <Text lineHeight="1" ml={10}>
            {suggestion.name} ({suggestion.postalCode})
          </Text>
        </Box>
      )

    case 'station':
      return (
        <Box display="flex" alignItems="center">
          <IconTrain width={16} height={19} fill="var(--colorText)" />
          <Text lineHeight="1" ml={10}>
            {suggestion.name}
          </Text>
          {suggestion.lines.map(l => {
            const img_name = l.replace('Ligne', 'M').replace(' ', '')
            return (
              <Box
                as="img"
                width={20}
                ml="5px"
                key={l}
                alt="metro"
                src={`https://res.cloudinary.com/loueragile/image/upload/v1558025817/web/icons/subway/${img_name}genRVB.svg`}
              />
            )
          })}
        </Box>
      )

    case 'zip':
      return (
        <Box display="flex" alignItems="center">
          <IconPlace width={20} height={20} fill="var(--colorText)" />
          <Text lineHeight="1" ml={10}>
            {suggestion.name}
          </Text>
        </Box>
      )

    default:
      return null
  }
}

export const get_option_from_suggestion = place => ({
  ...place,
  label: <FormInputLocalisationLabel suggestion={place} />,
  value: `${place.type}_${place.name}`
})

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <svg
        height="20px"
        viewBox="0 0 469.33333 469.33333"
        width="20px"
        xmlns="http://www.w3.org/2000/svg"
        style={{ fill: 'currentColor', padding: '4px' }}
      >
        <path d="m437.332031 192h-160v-160c0-17.664062-14.335937-32-32-32h-21.332031c-17.664062 0-32 14.335938-32 32v160h-160c-17.664062 0-32 14.335938-32 32v21.332031c0 17.664063 14.335938 32 32 32h160v160c0 17.664063 14.335938 32 32 32h21.332031c17.664063 0 32-14.335937 32-32v-160h160c17.664063 0 32-14.335937 32-32v-21.332031c0-17.664062-14.335937-32-32-32zm0 0" />
      </svg>
    </components.DropdownIndicator>
  )
}

const FormInputLocalisation = ({
  value,
  onChangeValue,
  error,
  isMulti,
  isCityOnly,
  autoFocus,
  label = 'Où ? en Île-de-France uniquement',
  placeholder = 'Ville, code postal, station de métro, RER',
  agglomeration = 'paris'
}) => {
  const fetcher = useFetchApi()

  // XXX no debounce option on lib
  const loadOptions = React.useCallback(
    async inputValue => {
      const suggestions = await get_suggestions(inputValue, agglomeration, fetcher)

      return suggestions
        .filter(v => (isCityOnly ? v.type === 'city' && v.postalCode !== '75' : true))
        .map(x => ({
          ...x,
          label: <FormInputLocalisationLabel suggestion={x} />,
          value: `${x.type}_${x.name}`
        }))
    },
    [agglomeration, fetcher, isCityOnly]
  )
  const ref = React.useRef(null)

  return (
    <>
      <label className="form-label" ref={ref}>
        {label}
      </label>
      <AsyncSelect
        loadOptions={loadOptions}
        isMulti={isMulti}
        isClearable
        noOptionsMessage={() => null}
        loadingMessage={() => 'Chargement...'}
        onChange={nv => {
          const defaultValue = isMulti ? [] : ''
          onChangeValue(nv || defaultValue)
        }}
        components={{ DropdownIndicator }}
        value={value}
        placeholder={placeholder}
        onFocus={() => {
          if (window && window.scrollTo) {
            window.scrollTo(0, ref.current.offsetTop - 100)
          }
        }}
        styles={{
          control: (old, { isFocused, hasValue }) => {
            return {
              ...old,
              padding: hasValue ? '10px 10px' : '10px 10px',
              borderColor: isFocused ? `#aaaaaa !important` : `var(--colorThin)`,
              boxShadow: 'none'
            }
          },
          placeholder: old => ({
            ...old,
            color: '#cdc9c9'
          }),
          valueContainer: old => ({
            ...old,
            overflow: 'visible'
          }),
          indicatorSeparator: () => ({ display: 'none' }),
          dropdownIndicator: old =>
            isMulti
              ? {
                  ...old,
                  padding: 4
                }
              : { display: 'none' },
          clearIndicator: old => ({
            ...old,
            padding: 4
          })
        }}
        autoFocus={autoFocus}
      />
      <FormFieldError showError={Boolean(error)} error={error} />
    </>
  )
}

export default FormInputLocalisation
