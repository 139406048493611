import React from 'react'
import { useImmerStore } from '../../store'
import { dataLayer } from '../../store/analytics.middleware'
import { Box, Text } from '../Shared/Base'
import { useAppStoreLink, useGooglePlayLink } from '../Shared/hooks'
import ModalSimple from '../Shared/ModalSimple'

const LayoutModalAppInstall = () => {
  const [
    {
      ui: { modal_app_install_open }
    },
    set_state
  ] = useImmerStore()
  const google_play_link = useGooglePlayLink()
  const apple_store_link = useAppStoreLink()

  if (!modal_app_install_open) return null

  return (
    <ModalSimple
      onClose={() =>
        set_state(draft => {
          draft.ui.modal_app_install_open = false
        })
      }
    >
      <Box textAlign="center" mt={20}>
        <Box
          as="img"
          src="https://res.cloudinary.com/loueragile/image/upload/v1601457561/web/jinka/Illus-fusee.svg"
          alt="Illustration fusée"
          width="228"
          height="135"
        />
      </Box>
      <Text className="fz-24" textAlign="center" color="n_primary" mt={20}>
        <strong>
          <Box as="span" color="n_secondary">
            Toutes les annonces
          </Box>{' '}
          du marché <Box as="br" display={{ sm: 'block', xs: 'none' }} /> sur une seule application
        </strong>
      </Text>
      <Text className="fz-20" textAlign="center" color="n_tertiary">
        Créez votre première alerte <Box as="br" display={{ sm: 'none', xs: 'block' }} /> en 2
        minutes&nbsp;!
      </Text>

      <Box
        display={{ sm: 'flex', xs: 'block' }}
        alignItems="center"
        justifyContent="center"
        mt={20}
      >
        <Box display="flex" alignItems="center" justifyContent="center" color="n_primary">
          <Text fontSize={18}>Noté</Text>
          <Text fontSize={20} ml={10}>
            <strong>4.8</strong>
          </Text>
          <Box
            as="img"
            src="https://res.cloudinary.com/loueragile/image/upload/v1601450826/web/jinka/Stars.svg"
            alt="Étoile"
            ml="5px"
            width={18}
          />
          <Text fontSize={16} ml={10}>
            par nos utilisateurs
          </Text>
        </Box>
      </Box>
      <Box
        display="grid"
        gridTemplateColumns={{ sm: 'repeat(2, max-content)', xs: '1fr' }}
        gridGap={30}
        justifyContent="center"
        my={40}
      >
        <Box
          as="a"
          href={apple_store_link}
          target="_blank"
          rel="noopener noreferrer"
          textAlign="center"
          onClick={() =>
            dataLayer.push({
              event: 'link_app_ios'
            })
          }
        >
          <Box
            as="img"
            src="https://res.cloudinary.com/loueragile/image/upload/v1601450698/web/jinka/Apple.png"
            height={58}
            alt="Télécharger dans l'App Store"
          />
        </Box>
        <Box
          as="a"
          href={google_play_link}
          target="_blank"
          rel="noopener noreferrer"
          textAlign="center"
          onClick={() =>
            dataLayer.push({
              event: 'link_app_android'
            })
          }
        >
          <Box
            as="img"
            src="https://res.cloudinary.com/loueragile/image/upload/v1601450698/web/jinka/Google.png"
            height={58}
            alt="Disponible sur Google Play"
          />
        </Box>
      </Box>
    </ModalSimple>
  )
}

export const LayoutModalCreateAd = () => {
  const [
    {
      ui: { modal_create_ad_open }
    },
    set_state
  ] = useImmerStore()

  if (!modal_create_ad_open) return null

  return (
    <ModalSimple
      onClose={() =>
        set_state(draft => {
          draft.ui.modal_create_ad_open = false
        })
      }
    >
      <Box textAlign="center" mt={60}>
        <Box
          as="img"
          src="https://res.cloudinary.com/loueragile/image/upload/v1601457561/web/jinka/Illus-fusee.svg"
          alt="Illustration fusée"
          width="228"
          height="135"
        />
      </Box>
      <Text className="fz-24" textAlign="center" color="n_primary" mt={20}>
        <strong>
          <Box as="span" color="n_secondary">
            Diffusez vos annonces
          </Box>{' '}
          <Box as="br" display={{ sm: 'block', xs: 'none' }} /> à nos millions d'utilisateurs
        </strong>
      </Text>

      <Box
        display={{ sm: 'flex', xs: 'block' }}
        alignItems="center"
        justifyContent="center"
        mt={33}
      >
        <strong>
          <Text fontSize={16} color="n_primary" textAlign="center">
            VOUS ÊTES UN
          </Text>
        </strong>
      </Box>

      <Box
        display="grid"
        gridTemplateColumns={{ sm: 'repeat(2, max-content)' }}
        gridGap={30}
        justifyContent="center"
        mt={10}
        mb={40}
      >
        <Box
          as="a"
          href="/pro"
          height={56}
          width={180}
          p="16px 30px"
          bg="n_secondary"
          color="white"
          style={{ borderRadius: 5, cursor: 'pointer' }}
          textAlign="center"
        >
          <Text fontSize={20}>Professionnel</Text>
        </Box>

        <Box
          as="a"
          href="https://particulier.jinka.fr"
          height={56}
          width={180}
          p="16px 30px"
          bg="n_secondary"
          color="white"
          style={{ borderRadius: 5, cursor: 'pointer' }}
          textAlign="center"
        >
          <Text fontSize={20}>Particulier</Text>
        </Box>
      </Box>
    </ModalSimple>
  )
}

export const LayoutModalAppChasseloc = () => {
  const [
    {
      ui: { modal_app_chasseloc_open }
    },
    set_state
  ] = useImmerStore()
  const google_play_link = useGooglePlayLink()
  const apple_store_link = useAppStoreLink()

  if (!modal_app_chasseloc_open) return null

  return (
    <ModalSimple
      onClose={() =>
        set_state(draft => {
          draft.ui.modal_app_chasseloc_open = false
        })
      }
    >
      <Box textAlign="center" mt={20}>
        <Box
          as="img"
          src="https://res.cloudinary.com/loueragile/image/upload/v1601457561/web/jinka/Illus-fusee.svg"
          alt="Illustration fusée"
          width="228"
          height="135"
        />
      </Box>
      <Text className="fz-24" textAlign="center" color="n_primary" mt={20}>
        <strong>
          <Box as="span" color="n_secondary">
            Téléchargez l'application
          </Box>{' '}
          pour faire appel à notre service de chasse immobilière
        </strong>
      </Text>
      <Text className="fz-20" textAlign="center" color="n_tertiary">
        Disponible dans la rubrique "Services" de l'application
      </Text>

      <Box
        // display={{ sm: 'flex', xs: 'block' }}
        display="none"
        alignItems="center"
        justifyContent="center"
        mt={20}
      >
        <Box display="flex" alignItems="center" justifyContent="center" color="n_primary">
          <Text fontSize={18}>Noté</Text>
          <Text fontSize={20} ml={10}>
            <strong>4.8</strong>
          </Text>
          <Box
            as="img"
            src="https://res.cloudinary.com/loueragile/image/upload/v1601450826/web/jinka/Stars.svg"
            alt="Étoile"
            ml="5px"
            width={18}
          />
          <Text fontSize={16} ml={10}>
            par nos utilisateurs&nbsp;!
          </Text>
        </Box>
      </Box>
      <Box
        display="grid"
        gridTemplateColumns={{ sm: 'repeat(2, max-content)', xs: '1fr' }}
        gridGap={30}
        justifyContent="center"
        my={40}
      >
        <Box
          as="a"
          href={apple_store_link}
          target="_blank"
          rel="noopener noreferrer"
          textAlign="center"
          onClick={() =>
            dataLayer.push({
              event: 'link_app_ios'
            })
          }
        >
          <Box
            as="img"
            src="https://res.cloudinary.com/loueragile/image/upload/v1601450698/web/jinka/Apple.png"
            height={58}
            alt="Télécharger dans l'App Store"
          />
        </Box>
        <Box
          as="a"
          href={google_play_link}
          target="_blank"
          rel="noopener noreferrer"
          textAlign="center"
          onClick={() =>
            dataLayer.push({
              event: 'link_app_android'
            })
          }
        >
          <Box
            as="img"
            src="https://res.cloudinary.com/loueragile/image/upload/v1601450698/web/jinka/Google.png"
            height={58}
            alt="Disponible sur Google Play"
          />
        </Box>
      </Box>
    </ModalSimple>
  )
}

export default LayoutModalAppInstall
