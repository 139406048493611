import React from 'react'
import { Link, useLocation } from 'react-router-dom'

const LayoutNavLink = ({ to, children, activeOnRoutes }) => {
  const { pathname } = useLocation()
  const active = activeOnRoutes.find(r => r.match(pathname)) !== undefined

  return active ? (
    <li className="active">
      <Link to={to}>{children}</Link>
    </li>
  ) : (
    <li>
      <Link to={to}>{children}</Link>
    </li>
  )
}

export default LayoutNavLink
