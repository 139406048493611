export const with_res_db_global_application = res_db_global_application => action => ({
  res_db_global_application,
  ...action
})

export const db_global_application_selector = state => state.db.global_application

const reducer = (state = {}, action) => {
  if (action.res_db_global_application) {
    const { id } = action.res_db_global_application
    return {
      ...state,
      [id]: action.res_db_global_application
    }
  }

  return state
}

export default reducer
