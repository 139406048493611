// some classical validators
import isEmail from 'validator/lib/isEmail'
import isEmpty from 'validator/lib/isEmpty'
import isFloat from 'validator/lib/isFloat'
import iban from 'iban'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { date_htob } from '../../../helpers/misc'

export const valid_required = v => (typeof v === 'string' ? !isEmpty(v) : Boolean(v))
export const valid_required_accept_zero = v => v === 0 || Boolean(v)
export const valid_required_array = v => v && v.length > 0
export const validate_if_set = validator => v => (valid_required(v) ? validator(v) : true)
export const valid_email = v => isEmail(v)
export const valid_float = options => v => isFloat(v, options)
export const valid_phone = country => v => {
  if (!v) {
    return false
  }
  const phoneNumber = parsePhoneNumberFromString(v, country)
  if (!phoneNumber) {
    return false
  }
  return phoneNumber.isValid()
}
export const valid_date = v => date_htob(v) !== null
export const valid_hour = v => /[0-2][0-9]:[0-5][0-9]/.test(v)
export const valid_zip = v => /^\d{5}$/.test(v)
export const valid_iban = v => iban.isValid(v)
// extract with [...document.querySelectorAll('#pair td:first-child')].map(x => x.innerText) from https://www.besv.fr/es@b/fr/base/listeDesPaysZoneSepa.html
const sepaCountryCode = [
  'AT',
  'BE',
  'BG',
  'CH',
  'CY',
  'CZ',
  'DE',
  'DK',
  'EE',
  'ES',
  'FI',
  'FR',
  // 'GB',
  'GI',
  'GR',
  'HR',
  'HU',
  'IE',
  'IS',
  'IT',
  'LI',
  'LT',
  'LU',
  'LV',
  'NO',
  'MC',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SE',
  'SI',
  'SK'
]
export const valid_sepa_iban = v => sepaCountryCode.indexOf(v.substring(0, 2)) !== -1
export const valid_bic = v => /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/.test(v)

export const filter_digit = v => v.replace(/[^\d]+/gi, '')
export const filter_uc = v => v.toUpperCase()
export const filter_alphanum = v => v.replace(/[^0-9a-zA-Z]+/gi, '')
