import React from 'react'
import styled from 'styled-components'
import Helmet from 'react-helmet'
import QueryString from 'querystring'
import Slick from 'react-slick'
import { Link } from 'react-router-dom'
import routes from '../../routes'
import { Box, Text } from '../Shared/Base'
import { qps_from_search } from '../../helpers/misc'

const LandingStyled = styled.main`
  color: var(--colorN_primary);

  .btn.is-medium {
    font-size: 1.8rem;
    padding: 16px 30px;
  }

  .h1-like {
    font-family: 'nunito';
    font-size: 4.4rem;
    line-height: 5.4rem;
    font-weight: 800;

    @media screen and (max-width: 1439px) {
      font-size: 3.6rem;
      line-height: 4.6rem;
    }

    @media screen and (max-width: 599px) {
      text-align: center;
    }
  }

  .h2-like {
    font-family: 'nunito';
    font-size: 3.6rem;
    line-height: 4.6rem;
  }

  .h2-likes {
    font-family: 'nunito';
    font-size: 4.2rem;
    line-height: 5.6rem;
  }
`

const FirstBox = styled(Box)`
  background: linear-gradient(180deg, rgba(162, 221, 255, 0.24) 0%, rgba(255, 255, 255, 0) 33.47%);
  position: relative;

  @media screen and (max-width: 1110px) {
    br {
      display: none;
    }
  }

  .illustration {
    @media screen and (max-width: 1140px) {
      width: 450px;
    }
    @media screen and (max-width: 1000px) {
      width: 400px;
    }
  }
`

const Slider = styled(Slick)`
  margin-top: 60px;

  .item {
    background-color: #f4faff;
    margin: 0 20px;
    overflow: hidden;
  }

  p.fz-16 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
`

const LandingAffiliation = ({ location }) => {
  const { partner_source } = routes.homepage_partner.route.match(location.pathname)

  return (
    <LandingStyled>
      <Helmet>
        <title>La meilleure application pour louer ou acheter un logement - Jinka</title>
        <meta
          name="description"
          content="Créez une alerte pour recevoir en premier toutes les nouvelles annonces du web avec vos critères."
        />
        <meta
          property="og:title"
          content="La meilleure application pour louer ou acheter un logement - Jinka"
        />
        <meta
          property="og:description"
          content="Créez une alerte pour recevoir en premier toutes les nouvelles annonces du web avec vos critères."
        />
        <meta property="og:url" content="https://www.jinka.fr/" />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/loueragile/image/upload/v1636016457/web/jinka/Image_OG.jpg"
        />
        <link rel="canonical" href="https://www.jinka.fr/" />
      </Helmet>

      <FirstBox as="section" maxWidth="100%" mx="auto" px={{ lg: 0, xs: 20 }} pt={160}>
        <Box
          maxWidth={1200}
          mx="auto"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          position="relative"
        >
          <Box width={{ lg: 520, xs: '100%' }}>
            <Box as="h1" className="h1-like">
              <Box as="span" color="n_secondary">
                La meilleure application
              </Box>{' '}
              pour louer ou acheter un logement
            </Box>
            <Text
              fontSize={24}
              lineHeight="3.6rem"
              mt={20}
              textAlign={{ sm: 'left', xs: 'center' }}
            >
              Soyez le premier à recevoir les annonces{' '}
              <Box as="br" display={{ sm: 'block', xs: 'none' }} /> de tous les sites immobiliers
              sur une seule <Box as="br" display={{ sm: 'block', xs: 'none' }} /> et unique
              application.
            </Text>
            <Text mt={35} fontSize={24}>
              <strong>En partenariat avec</strong>
            </Text>
            <Box
              display="flex"
              flexDirection={{ ms: 'row', xs: 'column' }}
              alignItems="center"
              mt={20}
            >
              <Box
                as="img"
                src={`https://loueragile-media.s3.eu-west-3.amazonaws.com/aff/${partner_source}.png`}
                alt=""
                height={60}
                mb={{ ms: 0, xs: 20 }}
              />
              <Link
                to={{
                  pathname: routes.sign.route.reverse({
                    action: 'partner'
                  }),
                  search: QueryString.stringify({
                    partner_source,
                    ...qps_from_search(location.search)
                  })
                }}
              >
                <Box as="button" className="btn is-n_primary is-big" ml={{ ms: 40, xs: 0 }}>
                  Inscription
                </Box>
              </Link>
            </Box>
          </Box>
          <Box
            as="img"
            src="https://res.cloudinary.com/loueragile/image/upload/f_auto/v1635408239/web/jinka/Screen-app.png"
            alt="Application"
            display={{ md: 'block', xs: 'none' }}
            ml={{ sm: 40, xs: 0 }}
            className="illustration"
          />
        </Box>
      </FirstBox>

      <Box backgroundColor="#F4FAFF" p={40} textAlign="center" mt={80}>
        <Text className="h2-like" mr={10}>
          <strong>Des milliers d’utilisateurs satisfaits</strong>
        </Text>

        <Box
          display={{ sm: 'flex', xs: 'block' }}
          alignItems="center"
          justifyContent="center"
          mt={{ sm: 0, xs: 10 }}
        >
          <Box display="flex" alignItems="center" justifyContent="center">
            <Text fontSize={24} mr={10}>
              Noté
            </Text>
            <Text fontSize={24}>
              <strong>4.8</strong>
            </Text>
            <Box
              as="img"
              src="https://res.cloudinary.com/loueragile/image/upload/v1601450826/web/jinka/Stars.svg"
              alt="Étoile"
              ml={10}
              mr={{ sm: 10, xs: 0 }}
            />
          </Box>
          <Box display="flex" alignItems="center" justifyContent="center" mr={{ sm: 10, xs: 0 }}>
            <Text fontSize={24}>sur les stores avec</Text>
          </Box>
          <Box
            className="fz-24"
            display={{ sm: 'flex', xs: 'block' }}
            alignItems="center"
            justifyContent="center"
            mt="5px"
            textAlign="center"
          >
            <Box as="p">
              <strong>+ de 10 000 avis !</strong>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box as="section" mt={{ ml: 120, xs: 60 }} px={{ lg: 0, xs: 20 }}>
        <Box
          display="flex"
          flexDirection={{ ml: 'row', xs: 'column' }}
          justifyContent="space-between"
          alignItems="center"
          maxWidth={1200}
          mx="auto"
        >
          <Box textAlign="center" className="fls" width={{ ml: '50%', xs: '100%' }}>
            <Box
              as="img"
              src="https://res.cloudinary.com/loueragile/image/upload/v1636014287/web/jinka/home-illus-01.png"
              alt=""
              className="fls"
            />
          </Box>
          <Box width={{ ml: '50%', xs: '100%' }} mt={{ ml: 0, xs: 30 }} px={{ ml: 50, xs: 0 }}>
            <Text fontSize={26} color="n_secondary" textAlign={{ ml: 'left', xs: 'center' }}>
              Notifications en temps réel
            </Text>
            <Box
              as="h2"
              className="h2-likes"
              fontSize={42}
              textAlign={{ ml: 'left', xs: 'center' }}
            >
              Soyez le premier alerté
            </Box>
            <Box
              as="hr"
              borderColor="decoration"
              maxWidth={100}
              mx={{ ml: '0', xs: 'auto' }}
              my={40}
            />
            <Text fontSize={24} lineHeight="3.6rem" textAlign={{ ml: 'left', xs: 'center' }}>
              Recevez les annonces en temps réel par e-mail ou via notre application pour être le
              premier à postuler. Magique !
            </Text>
            <Box textAlign={{ ml: 'left', xs: 'center' }}>
              <Link
                to={{
                  pathname: routes.sign.route.reverse({
                    action: 'partner'
                  }),
                  search: QueryString.stringify({
                    partner_source,
                    ...qps_from_search(location.search)
                  })
                }}
              >
                <Box as="button" className="btn is-n_primary is-big" mt={40}>
                  Inscription
                </Box>
              </Link>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box as="section" mt={{ ml: 120, xs: 60 }} px={{ lg: 0, xs: 20 }}>
        <Box
          display="flex"
          flexDirection={{ ml: 'row', xs: 'column-reverse' }}
          justifyContent="space-between"
          alignItems="center"
          maxWidth={1200}
          mx="auto"
        >
          <Box width={{ ml: '50%', xs: '100%' }} mt={{ ml: 0, xs: 30 }} px={{ ml: 50, xs: 0 }}>
            <Text fontSize={26} color="n_secondary" textAlign={{ ml: 'left', xs: 'center' }}>
              Tous les sites immobiliers
            </Text>
            <Box
              as="h2"
              className="h2-likes"
              fontSize={42}
              textAlign={{ ml: 'left', xs: 'center' }}
            >
              + 100 sites référencés
            </Box>
            <Box
              as="hr"
              borderColor="decoration"
              maxWidth={100}
              mx={{ ml: '0', xs: 'auto' }}
              my={40}
            />
            <Text fontSize={24} lineHeight="3.6rem" textAlign={{ ml: 'left', xs: 'center' }}>
              Nous cherchons pour vous sur plus d'une centaine de sites pour que vous ne loupiez pas
              l'annonce de vos rêves.
            </Text>
            <Box textAlign={{ ml: 'left', xs: 'center' }}>
              <Link
                to={{
                  pathname: routes.sign.route.reverse({
                    action: 'partner'
                  }),
                  search: QueryString.stringify({
                    partner_source,
                    ...qps_from_search(location.search)
                  })
                }}
              >
                <Box as="button" className="btn is-n_primary is-big" mt={40}>
                  Inscription
                </Box>
              </Link>
            </Box>
            <Box
              as="section"
              mt={60}
              px={{ lg: 0, xs: 20 }}
              display={{ sm: 'flex', xs: 'block' }}
              textAlign={{ sm: 'left', xs: 'center' }}
              alignItems="center"
            >
              <Box
                as="img"
                src="https://res.cloudinary.com/loueragile/image/upload/v1618991879/web/jinka/Icon-Agence.svg"
                width={70}
                alt=""
                mr={{ sm: 20, xs: 0 }}
                mb={{ sm: 0, xs: 20 }}
              />
              <Text
                fontSize={{ sm: '2.4rem', xs: '2.4rem' }}
                lineHeight={{ sm: '3.6rem', xs: '3.6rem' }}
              >
                Agent immobilier ? <br />
                <Box as="a" href="/pro" color="n_secondary" className="tdu">
                  Référencer vos annonces sur Jinka
                </Box>
              </Text>
            </Box>
          </Box>
          <Box textAlign="center" className="fls" width={{ ml: '50%', xs: '100%' }}>
            <Box
              as="img"
              src="https://res.cloudinary.com/loueragile/image/upload/v1635433947/web/jinka/home-illus-02.svg"
              alt=""
              className="fls"
            />
          </Box>
        </Box>
      </Box>

      <Box as="section" mt={{ ml: 120, xs: 60 }} px={{ lg: 0, xs: 20 }}>
        <Box
          display="flex"
          flexDirection={{ ml: 'row', xs: 'column' }}
          justifyContent="space-between"
          alignItems="center"
          maxWidth={1200}
          mx="auto"
        >
          <Box textAlign="center" className="fls" width={{ ml: '50%', xs: '100%' }}>
            <Box
              as="img"
              src="https://res.cloudinary.com/loueragile/image/upload/v1636017342/web/jinka/home-illus-03.png"
              alt=""
              className="fls"
            />
          </Box>
          <Box width={{ ml: '50%', xs: '100%' }} mt={{ ml: 0, xs: 30 }} px={{ ml: 50, xs: 0 }}>
            <Text fontSize={26} color="n_secondary" textAlign={{ ml: 'left', xs: 'center' }}>
              Fausses annonces
            </Text>
            <Box
              as="h2"
              className="h2-likes"
              fontSize={42}
              textAlign={{ ml: 'left', xs: 'center' }}
            >
              Protection anti-arnaques
            </Box>
            <Box
              as="hr"
              borderColor="decoration"
              maxWidth={100}
              mx={{ ml: '0', xs: 'auto' }}
              my={40}
            />
            <Text fontSize={24} lineHeight="3.6rem" textAlign={{ ml: 'left', xs: 'center' }}>
              Nos algorithmes analysent et repèrent les fausses annonces pour vous éviter de tomber
              dans le piège des arnaqueurs.
            </Text>
            <Box textAlign={{ ml: 'left', xs: 'center' }}>
              <Link
                to={{
                  pathname: routes.sign.route.reverse({
                    action: 'partner'
                  }),
                  search: QueryString.stringify({
                    partner_source,
                    ...qps_from_search(location.search)
                  })
                }}
              >
                <Box as="button" className="btn is-n_primary is-big" mt={40}>
                  Inscription
                </Box>
              </Link>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box as="section" mt={{ ml: 100, xs: 100 }} pb={100}>
        <Box textAlign="center" px={{ lg: 0, xs: 20 }}>
          <Box>
            <Box
              as="img"
              src="https://res.cloudinary.com/loueragile/image/upload/v1601461848/web/jinka/Quote.svg"
              alt=""
            />
          </Box>
          <Box as="h2" className="h2-like" mt={30}>
            Nos utilisateurs recommandent Jinka
          </Box>
          <Box className="fz-24" color="n_secondary">
            Le meilleur allié des locataires et acheteurs
            {/* Nos utilisateurs <Box as="br" display={{ sm: 'none', xs: 'block' }} /> recommandent
            Jinka */}
          </Box>
        </Box>

        <Slider
          slidesToShow={4}
          slidesToScroll={1}
          arrows={false}
          infinite={true}
          autoplay={true}
          pauseOnHover={true}
          swipe={false}
          responsive={[
            {
              breakpoint: 1400,
              settings: {
                slidesToShow: 3
              }
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2
              }
            },
            {
              breakpoint: 599,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: false,
                dots: true,
                swipe: true
              }
            }
          ]}
        >
          <Box>
            <Box borderRadius={10} p={30} className="item">
              <Box display="flex" alignItems="center">
                <Box
                  as="img"
                  src="https://res.cloudinary.com/loueragile/image/upload/v1601886080/web/jinka/Photo01.jpg"
                  borderRadius="100%"
                  width="65"
                  height="65"
                />
                <Box display="flex" flexDirection="column" ml={20} className="fz-18">
                  <strong>Camille R.</strong>
                  <Box
                    as="img"
                    src="https://res.cloudinary.com/loueragile/image/upload/v1601885711/web/jinka/Stars-testimonial.svg"
                    alt=""
                    mt="5px"
                  />
                </Box>
              </Box>
              <Text className="fz-16" mt={20}>
                L’application regroupe une grande majorité des annonces de location en une seule
                application et vous envoie des notifications en temps réel dès qu’une nouvelle
                annonce sort. L’application que j’ai le plus utilisée et qui m’a permis d’avoir mon
                appartement, merci !
              </Text>
            </Box>
          </Box>
          <Box>
            <Box borderRadius={10} p={30} className="item">
              <Box display="flex" alignItems="center">
                <Box
                  as="img"
                  src="https://res.cloudinary.com/loueragile/image/upload/v1601886081/web/jinka/Photo02.jpg"
                  borderRadius="100%"
                  width="65"
                  height="65"
                />
                <Box display="flex" flexDirection="column" ml={20} className="fz-18">
                  <strong>Kanie K.</strong>
                  <Box
                    as="img"
                    src="https://res.cloudinary.com/loueragile/image/upload/v1601885711/web/jinka/Stars-testimonial.svg"
                    alt=""
                    mt="5px"
                  />
                </Box>
              </Box>
              <Text className="fz-16" mt={20}>
                Je note rarement des avis, par manque de réflexe ou par « flemme » je n’en sais
                rien, mais cette application est vraiment géniale, elle répertorie en temps réel
                toutes les annonces sur tous les sites de petites annonces immobilières.
              </Text>
            </Box>
          </Box>
          <Box>
            <Box borderRadius={10} p={30} className="item">
              <Box display="flex" alignItems="center">
                <Box
                  as="img"
                  src="https://res.cloudinary.com/loueragile/image/upload/v1601886081/web/jinka/Photo03.jpg"
                  borderRadius="100%"
                  width="65"
                  height="65"
                />
                <Box display="flex" flexDirection="column" ml={20} className="fz-18">
                  <strong>David M.</strong>
                  <Box
                    as="img"
                    src="https://res.cloudinary.com/loueragile/image/upload/v1601885711/web/jinka/Stars-testimonial.svg"
                    alt=""
                    mt="5px"
                  />
                </Box>
              </Box>
              <Text className="fz-16" mt={20}>
                Super appli, dommage que je l’ai découverte seulement à la fin de mes recherches,
                mais tout est très instinctif, l’appli prévient des arnaques potentiels ce qui est
                un énorme plus. Et elle affine bien les recherches tout en proposant un choix plus
                large que sur le bon coin par exemple.
              </Text>
            </Box>
          </Box>
          <Box>
            <Box borderRadius={10} p={30} className="item">
              <Box display="flex" alignItems="center">
                <Box
                  as="img"
                  src="https://res.cloudinary.com/loueragile/image/upload/v1601886081/web/jinka/Photo04.jpg"
                  borderRadius="100%"
                  width="65"
                  height="65"
                />
                <Box display="flex" flexDirection="column" ml={20} className="fz-18">
                  <strong>Delphine H.</strong>
                  <Box
                    as="img"
                    src="https://res.cloudinary.com/loueragile/image/upload/v1601885711/web/jinka/Stars-testimonial.svg"
                    alt=""
                    mt="5px"
                  />
                </Box>
              </Box>
              <Text className="fz-16" mt={20}>
                Une application simple qui permet d’éviter les recherches de logement fastidieuses
                et chronophages. L’application rassemble pour vous toutes les offres correspondant à
                vos critères sur l’ensemble des sites internet existants.
              </Text>
            </Box>
          </Box>
        </Slider>
      </Box>
    </LandingStyled>
  )
}

export default LandingAffiliation
