import React from 'react'
import { qps_from_search } from '../../helpers/misc'
import { useFetchApi } from '../Shared/hooks'
import LoadingBar from '../Shared/LoadingBar'
import { Box } from '../Shared/Base'
import { dataLayer } from '../../store/analytics.middleware'

const NlUnsubscribe = ({ location, history }) => {
  const fetcher = useFetchApi()
  const qps = qps_from_search(location.search)
  const token = qps.token

  React.useEffect(() => {
    const init = async () => {
      await fetcher(`user/nl_unsubscribe?token=${token}`)
      dataLayer.push({
        event: 'eventGA',
        category: 'NlUnsubscribe'
      })
    }
    init()
  }, [fetcher, token])

  if (token) {
    return (
      <Box
        px={{ lg: 0, xs: 20 }}
        pt={{ sm: 60, xs: 30 }}
        pb={{ sm: 150, xs: 30 }}
        maxWidth={650}
        mx="auto"
      >
        <Box mt={{ md: 0, xs: 30 }}>
          <Box
            backgroundColor="bg_secondary"
            mb={30}
            className="fz-18"
            color="secondary"
            textAlign="center"
            borderRadius={5}
            p={20}
          >
            Bien reçu, vous ne recevrez plus de lettres d'information de notre part.
          </Box>
        </Box>
      </Box>
    )
  }

  return <LoadingBar />
}

export default NlUnsubscribe
