import React from 'react'

import routes from '../../routes'
import LoadingBar from '../Shared/LoadingBar'
import { useImmerStore } from '../../store'

const SignUserRedirector = ({ history }) => {
  const [
    {
      db: { me }
    }
  ] = useImmerStore()

  React.useEffect(() => {
    if (me.isOwner) {
      history.replace({
        pathname: routes.asowner_home.route.reverse(),
        search: history.location.search
      })
    } else {
      history.replace({
        pathname: routes.alert_list.route.reverse(),
        search: history.location.search
      })
    }
  }, [me, history])

  return <LoadingBar />
}

export default SignUserRedirector
