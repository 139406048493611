import React from 'react'
import styled from 'styled-components'
import Helmet from 'react-helmet'
import { Box, Text } from '../Shared/Base'

const LandingStyled = styled.main`
  color: var(--colorN_primary);

  .h2-like {
    font-size: 3.6rem;
    line-height: 4.6rem;

    @media screen and (max-width: 1439px) {
      font-size: 3.2rem;
      line-height: 4.2rem;
    }
  }
`

const LandingHomepage = () => {
  return (
    <LandingStyled>
      <Helmet>
        <title>Kit de presse - Jinka</title>
        <meta name="description" content="Kit de presse, logos, visuels de l'application." />
        <meta property="og:title" content="Kit de presse - Jinka" />
        <meta property="og:description" content="Kit de presse, logos, visuels de l'application." />
        <meta property="og:url" content="https://www.jinka.fr/kit" />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/loueragile/image/upload/v1618990667/web/jinka/Image_OG.jpg"
        />
        <link rel="canonical" href="https://www.jinka.fr/kit" />
      </Helmet>

      <Box as="section" maxWidth={1200} mx="auto" px={{ lg: 0, xs: 20 }} py={{ ml: 120, xs: 80 }}>
        <Box as="h2" className="h2-like">
          Kit de presse
        </Box>
        <Text className="fz-24" color="n_secondary">
          Jinka, la meilleure application pour louer ou acheter un logement
        </Text>
        <Box
          border="1px solid"
          borderColor="thin"
          display="inline-flex"
          mt={30}
          borderRadius={10}
          padding={20}
          backgroundColor="white"
        >
          <Box
            border="1px solid"
            borderColor="thin"
            borderRadius={200}
            display="inline-flex"
            alignItems="center"
            justifyContent="center"
            width={70}
            height={70}
            mr={20}
          >
            <Text fontSize={30} color="light">
              <strong>M</strong>
            </Text>
          </Box>
          <Box>
            <Text fontSize={18}>
              <strong>Contact presse</strong>
            </Text>
            <Text mt="5px">Marc Lebel</Text>
            <Text>
              E-mail :{' '}
              <Box as="a" href="mailto:marc@jinka.fr" color="n_tertiary" className="tdu">
                marc@jinka.fr
              </Box>
            </Text>
          </Box>
        </Box>
        <Box as="h3" className="fz-24" mt={{ ml: 60, xs: 30 }}>
          Logos
        </Box>
        <Box
          display="grid"
          gridTemplateColumns={{ sm: 'repeat(3, max-content)', xs: '1fr' }}
          gridGap={30}
          mt={30}
        >
          <Box
            as="a"
            download="Screen-01.png"
            href="https://res.cloudinary.com/loueragile/image/upload/v1613552853/web/jinka/screenshots/Logo-Jinka.png"
            target="_blank"
            rel="noopener noreferrer"
            backgroundColor="white"
          >
            <Box
              as="img"
              src="https://res.cloudinary.com/loueragile/image/upload/v1613552853/web/jinka/screenshots/Logo-Jinka.png"
              alt=""
              height={100}
              border="1px solid"
              borderColor="thin"
              borderRadius={5}
              padding={20}
            />
          </Box>
          <Box
            as="a"
            download="Screen-01.png"
            href="https://res.cloudinary.com/loueragile/image/upload/v1613552853/web/jinka/screenshots/Logo-Jinka-J.png"
            target="_blank"
            rel="noopener noreferrer"
            backgroundColor="white"
          >
            <Box
              as="img"
              src="https://res.cloudinary.com/loueragile/image/upload/v1613552853/web/jinka/screenshots/Logo-Jinka-J.png"
              alt=""
              height={100}
              border="1px solid"
              borderColor="thin"
              borderRadius={5}
              padding={20}
            />
          </Box>
          <Box
            as="a"
            download="Screen-01.png"
            href="https://res.cloudinary.com/loueragile/image/upload/v1642429139/web/jinka/screenshots/Logo-Jinka-white.png"
            target="_blank"
            rel="noopener noreferrer"
            backgroundColor="n_primary"
            borderRadius={5}
          >
            <Box
              as="img"
              src="https://res.cloudinary.com/loueragile/image/upload/v1642429139/web/jinka/screenshots/Logo-Jinka-white.png"
              alt=""
              height={100}
              border="1px solid"
              borderColor="n_primary"
              borderRadius={5}
              padding={20}
            />
          </Box>
        </Box>
        <Box as="h3" className="fz-24" mt={{ ml: 60, xs: 30 }}>
          Visuels application
        </Box>
        <Box
          // display="grid"
          gridTemplateColumns={{ ml: 'repeat(5, max-content)', xs: '1fr' }}
          gridGap={30}
          mt={30}
          display="none"
        >
          <a
            download="Screen-01.png"
            href="https://res.cloudinary.com/loueragile/image/upload/v1642431367/web/jinka/screenshots/Jinka-screen-01.png"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Box
              as="img"
              src="https://res.cloudinary.com/loueragile/image/upload/v1642431367/web/jinka/screenshots/Jinka-screen-01.png"
              alt=""
              height={{ sm: 340, xs: 'auto' }}
            />
          </a>
          <a
            download="Screen-01.png"
            href="https://res.cloudinary.com/loueragile/image/upload/v1642431367/web/jinka/screenshots/Jinka-screen-02.png"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Box
              as="img"
              src="https://res.cloudinary.com/loueragile/image/upload/v1642431367/web/jinka/screenshots/Jinka-screen-02.png"
              alt=""
              height={{ sm: 340, xs: 'auto' }}
            />
          </a>
          <a
            download="Screen-01.png"
            href="https://res.cloudinary.com/loueragile/image/upload/v1642432425/web/jinka/screenshots/Jinka-screen-03.png"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Box
              as="img"
              src="https://res.cloudinary.com/loueragile/image/upload/v1642432425/web/jinka/screenshots/Jinka-screen-03.png"
              alt=""
              height={{ sm: 340, xs: 'auto' }}
            />
          </a>
        </Box>
        <Box
          display="grid"
          gridTemplateColumns={{ ml: 'repeat(3, max-content)', xs: '1fr' }}
          gridGap={30}
          mt={40}
        >
          <Box
            as="a"
            download="Screen-01.png"
            href="https://res.cloudinary.com/loueragile/image/upload/v1642497499/web/jinka/screenshots/Jinka-visuel-01.jpg"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Box
              as="img"
              src="https://res.cloudinary.com/loueragile/image/upload/v1642497499/web/jinka/screenshots/Jinka-visuel-01.jpg"
              alt=""
              height={{ sm: 300, xs: 'auto' }}
              border="1px solid"
              borderColor="thin"
              borderRadius={5}
              padding={10}
            />
          </Box>
          <Box
            as="a"
            download="Screen-01.png"
            href="https://res.cloudinary.com/loueragile/image/upload/v1675952580/web/jinka/screenshots/Jinka-visuel-03.jpg"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Box
              as="img"
              src="https://res.cloudinary.com/loueragile/image/upload/v1675952580/web/jinka/screenshots/Jinka-visuel-03.jpg"
              alt=""
              height={{ sm: 300, xs: 'auto' }}
              border="1px solid"
              borderColor="thin"
              borderRadius={5}
              padding={10}
            />
          </Box>
        </Box>
      </Box>
    </LandingStyled>
  )
}

export default LandingHomepage
