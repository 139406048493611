import React from 'react'
import styled from 'styled-components'
import { Text, Box } from '../Shared/Base'
import { IconClose } from '../Shared/Svg/Icons'

const BoxTop = styled(Box)`
  background-image: url(https://res.cloudinary.com/loueragile/image/upload/v1602604348/web/jinka/Logos-banner-bg.png);
  background-repeat: repeat-y;
  background-position: center center;
  background-color: #9b3aa0;

  @media screen and (min-width: 960px) {
    br {
      display: none;
    }
  }
`

const LayoutMobileBanner = () => {
  const [hidden, set_hidden] = React.useState(false)
  const onClose = React.useCallback(() => {
    set_hidden(true)
    window.localStorage &&
      window.localStorage.setItem &&
      window.localStorage.setItem('LA_hide_jinka_banner', '1')
  }, [])
  const previsouly_hidden =
    window.localStorage &&
    window.localStorage.getItem &&
    window.localStorage.getItem('LA_hide_jinka_banner')

  if (hidden || previsouly_hidden) return null

  return (
    <BoxTop p={10} px={20} backgroundColor="n_secondary" display="none">
      <Box
        maxWidth={1200}
        mx="auto"
        position="relative"
        alignItems="center"
        justifyContent="center"
        flexDirection={{ md: 'row', xs: 'column' }}
      >
        <Box
          as="img"
          src="https://res.cloudinary.com/loueragile/image/upload/v1602606567/web/jinka/Logos-banner.svg"
          width={{ md: '20%', xs: '55%' }}
          height={{ md: '20%', xs: '55%' }}
          mr={{ md: 50, xs: 0 }}
          alt="LouerAgile - Jinka"
          mt={{ md: 0, xs: 10 }}
        />
        <Text className="fz-20" mb={10} color="white" mt="8px" textAlign="center">
          Nouveau nom, plus de possibilités ! <br />
          <Box
            href="https://blog.jinka.fr/loueragile-devient-jinka/"
            as="a"
            color="white"
            className="tdu"
            target="_blank"
            rel="noopener noreferrer"
          >
            En savoir plus
          </Box>
        </Text>
        <Box
          as="button"
          position="absolute"
          top={{ md: 12, xs: '5px' }}
          right={{ md: 10, xs: '-5px' }}
          onClick={onClose}
        >
          <IconClose width={20} height={20} fill="white" />
        </Box>
      </Box>
    </BoxTop>
  )
}

export default LayoutMobileBanner
