import { withStateHandlers } from 'recompose'
import React from 'react'

export const get_first_error = (validators, value, context) => {
  if (!validators) return null

  for (let name in validators) {
    if (!validators[name](value, context)) {
      return name
    }
  }

  return null
}

export const is_valid = (validators, value, context) =>
  get_first_error(validators, value, context) === null

const emptyContext = {}
const FormField = ({
  validators,
  value,
  children,
  showRequiredError,
  context = emptyContext,
  ...props
}) => {
  const children_props = { ...props, value }

  const error = get_first_error(validators, value, context)
  children_props.error = error === 'required' && !showRequiredError ? null : error

  return children(children_props)
}

export const FocusableField = withStateHandlers(
  { focused: false },
  {
    onFocus: (_, { onFocus }) => event => {
      if (onFocus) onFocus(event)
      return {
        focused: true
      }
    },
    onBlur: (_, { onBlur }) => () => {
      if (onBlur) onBlur()
      return {
        focused: false
      }
    }
  }
)(FormField)

const reducer_values = (state, action) => ({ ...state, [action.target_name]: action.target_value })
const reducer_form_state = (state, action) => {
  switch (action.type) {
    case 'init_submit': {
      return { submittedOnce: true, loading: true, globalError: null }
    }

    case 'submit_invalid': {
      return { ...state, loading: false, globalError: action.globalError || null }
    }

    case 'submit_valid': {
      return { ...state, loading: false }
    }

    default:
      throw Error('action unknown')
  }
}

export const useFormState = ({
  onSubmitSuccess,
  onSubmitError,
  initial_values,
  reset_loading_after_submit_success = false,
  validators,
  filters
}) => {
  const [values, dispatch_values] = React.useReducer(reducer_values, initial_values)
  const [form_state, dispatch_form_state] = React.useReducer(reducer_form_state, {
    submittedOnce: false,
    loading: false,
    globalError: false
  })
  const context = React.useMemo(() => {
    return { values }
  }, [values])

  const onChange = React.useCallback(
    event => {
      const { name, value } = event.target
      const filtered_value = filters && filters[name] ? filters[name](value) : value

      // insure this field is controlled by this form
      if (initial_values[name] !== undefined) {
        dispatch_values({ type: 'change', target_name: name, target_value: filtered_value })
      }
    },
    [filters, initial_values]
  )

  const onChangeByNameValue = React.useCallback(
    (name, value) => {
      onChange({ target: { name, value } })
    },
    [onChange]
  )

  const onSubmit = React.useCallback(
    async event => {
      if (event) event.preventDefault()

      dispatch_form_state({ type: 'init_submit' })

      const errors = []
      const allvalid = Object.keys(values).reduce((acc, el) => {
        const has_validators = Boolean(validators && validators[el])

        if (has_validators) {
          const error_field = get_first_error(validators[el], values[el], context)
          if (error_field !== null) {
            errors.push({ field: el, value: error_field })
          }
          return acc && error_field === null
        }

        return acc
      }, true)

      if (allvalid) {
        try {
          await onSubmitSuccess(values)
          if (reset_loading_after_submit_success) dispatch_form_state({ type: 'submit_valid' })
        } catch (err) {
          if (err.name === 'FetchError' && err.status === 400) {
            dispatch_form_state({ type: 'submit_invalid', globalError: err.errorMessage })
          } else {
            throw err
          }
        }
      } else {
        // XXX scroll on error simple
        setTimeout(() => {
          const $els = document.getElementsByClassName('error-scrollable')
          if ($els[0] && $els[0].offsetTop) {
            window.scrollTo(0, $els[0].offsetTop - 100)
          }
        }, 200)
        dispatch_form_state({ type: 'submit_invalid' })
        if (onSubmitError) onSubmitError(errors)
      }
    },
    [
      values,
      validators,
      context,
      onSubmitSuccess,
      reset_loading_after_submit_success,
      onSubmitError
    ]
  )

  const { submittedOnce, loading, globalError } = form_state

  return {
    values,
    context,
    submittedOnce,
    loading,
    globalError,
    onChange,
    onChangeByNameValue,
    validators,
    onSubmit
  }
}

export default FormField
