export const with_res_db_tenancy_draft = res_db_tenancy_draft => action => ({
  res_db_tenancy_draft,
  ...action
})

export const db_tenancy_draft_selector = state => state.db.tenancy_draft

const reducer = (state = {}, action) => {
  if (action.res_db_tenancy_draft) {
    const { id } = action.res_db_tenancy_draft
    return {
      ...state,
      [id]: action.res_db_tenancy_draft
    }
  }

  return state
}

export default reducer
