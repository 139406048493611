import React from 'react'
// import { Box } from '../Base'

// Global
export const IconChevronLeft = ({ height, width, fill }) => (
  <svg height={height} width={width} viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M8.707.293a1,1,0,0,0-1.414,0l-5,5a1,1,0,0,0,0,1.414l5,5a1,1,0,0,0,1.414-1.414L4.414,6,8.707,1.707A1,1,0,0,0,8.707.293Z"
        fill={fill}
      />
    </g>
  </svg>
)

export const IconChevronRight = ({ height, width, fill }) => (
  <svg height={height} width={width} viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M9.707,5.293l-5-5A1,1,0,0,0,3.293,1.707L7.586,6,3.293,10.293a1,1,0,1,0,1.414,1.414l5-5A1,1,0,0,0,9.707,5.293Z"
        fill={fill}
      />
    </g>
  </svg>
)

export const IconClose = ({ height, width, fill }) => (
  <svg height={height} width={width} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M14.7,1.3c-0.4-0.4-1-0.4-1.4,0L8,6.6L2.7,1.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4L6.6,8l-5.3,5.3 c-0.4,0.4-0.4,1,0,1.4C1.5,14.9,1.7,15,2,15s0.5-0.1,0.7-0.3L8,9.4l5.3,5.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L9.4,8l5.3-5.3C15.1,2.3,15.1,1.7,14.7,1.3z"
        fill={fill}
      />
    </g>
  </svg>
)

export const IconHeart = ({ height, width, fill }) => (
  <svg
    height={height}
    width={width}
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    className="heart"
  >
    <g>
      <path
        d="M11.6,0C10.1,0,8.8,0.8,8,2C7.2,0.8,5.9,0,4.4,0C2,0,0,2,0,4.4c0,4.4,8,10.9,8,10.9s8-6.5,8-10.9 C16,2,14,0,11.6,0z"
        fill={fill}
      />
    </g>
  </svg>
)

export const IconVisibility = ({ height, width, stroke }) => (
  <svg
    height={height}
    width={width}
    viewBox="0 0 26 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
  >
    <path stroke={stroke} d="M13 15.5a3.5 3.5 0 100-7 3.5 3.5 0 000 7z" />
    <path
      stroke={stroke}
      d="M24.376 11.672C23.213 10.352 18.562 5.5 13 5.5c-5.562 0-10.213 4.852-11.376 6.172a.5.5 0 000 .656C2.787 13.648 7.438 18.5 13 18.5c5.562 0 10.213-4.852 11.376-6.172a.5.5 0 000-.656v0z"
    />
  </svg>
)

export const IconVisibilityOff = ({ height, width, stroke }) => (
  <svg
    height={height}
    width={width}
    viewBox="0 0 26 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
  >
    <path
      stroke={stroke}
      d="M20.88 8A23.271 23.271 0 0125 11.998S19.674 19 13.103 19A10.268 10.268 0 0110 18.5M5.251 16A23.747 23.747 0 011 12.01S6.516 5 13.321 5c.904.004 1.804.122 2.679.35M9 12a4 4 0 014-4M17 12a4 4 0 01-4 4M23 2L3 22"
    />
  </svg>
)

export const IconAttachFile = ({ height, width, stroke }) => (
  <svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g strokeLinecap="square" strokeWidth="1">
      <path
        d="M18,4v12.5 c0,3.6-2.9,6.5-6.5,6.5h0C7.9,23,5,20.1,5,16.5v-11C5,3,7,1,9.5,1h0C12,1,14,3,14,5.5v10c0,1.4-1.1,2.5-2.5,2.5h0 C10.1,18,9,16.9,9,15.5V7"
        fill="none"
        stroke={stroke}
      />
    </g>
  </svg>
)

export const IconGarbage = ({ height, width, stroke }) => (
  <svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g stroke={stroke} strokeLinecap="square" strokeWidth="1">
      <path
        d="M20,9l-.867,12.142A2,2,0,0,1,17.138,23H6.862a2,2,0,0,1-1.995-1.858L4,9"
        fill="none"
        stroke={stroke}
      />
      <line fill="none" x1="1" x2="23" y1="5" y2="5" />
      <path d="M8,5V2A1,1,0,0,1,9,1h6a1,1,0,0,1,1,1V5" fill="none" />
    </g>
  </svg>
)

export const IconTrain = ({ height, width, fill }) => (
  <svg height={height} width={width} viewBox="0 0 18 21" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 0H3C1.346 0 0 1.346 0 3v14a1 1 0 001 1h3.333L3 21h12l-1.333-3H17a1 1 0 001-1V3c0-1.654-1.346-3-3-3zM4 15a1 1 0 110-2 1 1 0 010 2zm10 0a1 1 0 110-2 1 1 0 010 2zm2-6a1 1 0 01-1 1h-5V2h5a1 1 0 011 1v6zM8 2H3a1 1 0 00-1 1v6a1 1 0 001 1h5V2z"
      fill={fill}
    />
  </svg>
)

export const IconPlace = ({ height, width, fill }) => (
  <svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g fill="#545454">
      <path
        d="M12,0C7.6,0,3,3.4,3,9c0,5.3,8,13.4,8.3,13.7c0.2,0.2,0.4,0.3,0.7,0.3s0.5-0.1,0.7-0.3C13,22.4,21,14.3,21,9 C21,3.4,16.4,0,12,0z M12,12c-1.7,0-3-1.3-3-3s1.3-3,3-3s3,1.3,3,3S13.7,12,12,12z"
        fill={fill}
      />
    </g>
  </svg>
)

export const IconInfo = ({ height, width, fill }) => (
  <svg
    height={height}
    width={width}
    viewBox="0 0 16 15"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
  >
    <path fill={fill} d="M8 5a1 1 0 100-2 1 1 0 000 2z" />
    <path stroke={fill} d="M8 14.5a7 7 0 100-14 7 7 0 000 14zM8 11.5v-4" />
  </svg>
)
