import React from 'react'
import styled from 'styled-components'

import { Box } from '../Base'
import { Hidden } from '@material-ui/core'

const StyledNavLeft = styled(Box)`
  font-size: 1.8rem;
  color: var(--colorN_primary);
  border-right: 1px solid var(--colorThin);
  padding-top: 60px;

  ul {
    margin-left: -20px;
    margin-top: -20px;
  }

  li {
    margin-top: 20px;

    &:first-child {
      margin-top: 10px;
    }

    &.active {
      color: var(--colorSecondary);
      background-color: white;
      border-radius: 5px;
      box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.05);

      a {
        color: var(--colorN_secondary);

        &:hover {
          color: var(--colorN_secondary);
        }
      }
    }

    a {
      display: inline-block;
      color: var(--colorText);
      display: block;
      padding: 15px 0;
      padding-left: 20px;
    }
  }
`

const LayoutWithLeftNav = ({ children, navigation }) => {
  return (
    <Box
      as="section"
      display="grid"
      gridTemplateColumns={{ md: '23% 77%', xs: '1fr' }}
      gridTemplateRows={{ md: '1fr', xs: 'auto' }}
      px={{ lg: 0, xs: 20 }}
      width="100%"
      minHeight="100%"
      maxWidth={1100}
      mx="auto"
    >
      <Hidden smDown>
        <StyledNavLeft as="nav" role="navigation" pr={{ lg: 70, xs: 50 }} mr={{ lg: 70, xs: 50 }}>
          {navigation}
        </StyledNavLeft>
      </Hidden>
      <Box as="main" pt={{ md: 60, xs: 30 }} pb={100} className="main" width="100%">
        {children}
      </Box>
    </Box>
  )
}

export default LayoutWithLeftNav
