import React from 'react'
import { connect } from 'react-redux'
import { useLocation } from 'react-router'
import styled from 'styled-components'

import { qps_from_search } from '../../helpers/misc'
import routes from '../../routes'
import { router_params_selector_creator } from '../../store/reducers/router'
import { Box } from '../Shared/Base'
import { useAppStoreLink, useGooglePlayLink } from '../Shared/hooks'

const BoxTop = styled(Box)`
  .close {
    position: absolute;
    top: 15px;
    right: 15px;
  }
`

const LayoutMobileBanner = ({ ad_id }) => {
  const location = useLocation()
  const qps = qps_from_search(location.search)
  const google_play_link = useGooglePlayLink()
  const apple_store_link = useAppStoreLink()

  const params_ad_id = ad_id || qps.ad
  const params_context_token = qps.token || ''

  const link = React.useMemo(
    () =>
      `${
        process.env.REACT_APP_MOBILE_BASE_URL
      }/ad?id=${params_ad_id}&context_alert_token=${params_context_token}`,
    [params_ad_id, params_context_token]
  )

  return (
    <BoxTop
      p={20}
      borderBottom="1px solid"
      borderColor="thin"
      position="relative"
      display={{ md: 'none', xs: 'block' }}
      className="la_webview_hide"
      backgroundColor="#EDEDED"
    >
      <Box
        as="a"
        href={link}
        rel="noopener noreferrer"
        color="text"
        display="flex"
        alignItems="center"
      >
        <Box as="span" className="fz-18 fls" style={{ textDecorationLine: 'underline' }}>
          <strong>Ouvrir dans l'application Jinka</strong>
        </Box>
      </Box>

      <Box display="grid" gridTemplateColumns="repeat(2, max-content)" gridGap={20} mt={15}>
        <Box
          as="a"
          href={apple_store_link}
          target="_blank"
          rel="noopener noreferrer"
          textAlign="center"
        >
          <Box
            as="img"
            src="https://res.cloudinary.com/loueragile/image/upload/v1594112762/web/landing/Homepage/AppStore.png"
            height={38}
            alt=""
          />
        </Box>
        <Box
          as="a"
          href={google_play_link}
          target="_blank"
          rel="noopener noreferrer"
          textAlign="center"
        >
          <Box
            as="img"
            src="https://res.cloudinary.com/loueragile/image/upload/v1594112762/web/landing/Homepage/GoogleStore.png"
            height={38}
            alt=""
          />
        </Box>
      </Box>
    </BoxTop>
  )
}

const route_ad_id_selector = router_params_selector_creator({
  key: 'id',
  routes: [routes.ad.route]
})

export default connect((state, props) => ({
  ad_id: route_ad_id_selector(state, props)
}))(LayoutMobileBanner)
