import { css } from 'styled-components'

const Misc = css`
  .notistack-root {
    margin-bottom: 20px;
  }
  .success {
    background-color: var(--colorTertiary) !important;
    justify-content: center !important;
  }
`

export default Misc
