import NProgress from 'nprogress'
import React from 'react'
import { createGlobalStyle } from 'styled-components'

export const LoadingBarGlobalStyle = createGlobalStyle`
  /* Make clicks pass-through */
  #nprogress {
    pointer-events: none;
  }

  #nprogress .bar {
    background: #1ab2d8;

    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;

    width: 100%;
    height: 1px;
  }

  /* Remove these to get rid of the spinner */
  #nprogress .spinner {
    display: block;
    position: fixed;
    z-index: 1031;
    top: 15px;
    right: 15px;
  }

  #nprogress .spinner-icon {
    width: 18px;
    height: 18px;
    box-sizing: border-box;

    border: solid 1px transparent;
    border-top-color: #1ab2d8;
    border-left-color: #1ab2d8;
    border-radius: 50%;

    animation: nprogress-spinner 400ms linear infinite;
  }

  .nprogress-custom-parent {
    overflow: hidden;
    position: relative;
  }

  .nprogress-custom-parent #nprogress .spinner,
  .nprogress-custom-parent #nprogress .bar {
    position: absolute;
  }

  @keyframes nprogress-spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

let started = 0
const LoadingBar = () => {
  React.useEffect(() => {
    started++
    NProgress.configure({ showSpinner: false, minimum: 0.2 })
    NProgress.start()

    return () => {
      started--
      if (started === 0) {
        NProgress.done()
      }
    }
  }, [])

  return null
}

export default LoadingBar
