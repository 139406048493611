import tenancy_draft from './tenancy_draft'
import tenement from './tenement'
import adpremium from './adpremium'
import ad_application from './ad_application'
import global_application from './global_application'
import { combineReducers } from 'redux'

export default combineReducers({
  tenancy_draft,
  tenement,
  adpremium,
  ad_application,
  global_application
})
