import { IconHeart } from '../Shared/Svg/Icons'
import React from 'react'
import styled from 'styled-components'
import { Box } from '../Shared/Base'
import { dataLayer } from '../../store/analytics.middleware'
import { useAppStoreLink, useGooglePlayLink } from '../Shared/hooks'

const IconJinka = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="27"
    fill="none"
    viewBox="0 0 19 27"
    className="jinka"
  >
    <path
      fill="url(#paint0_linear)"
      d="M8.605 0c-.839 0-1.639.323-2.234.924L1.013 6.242C-.202 7.45-.34 9.348.687 10.562a3.114 3.114 0 002.266 1.118 3.195 3.195 0 002.348-.908l5.523-5.488a4.334 4.334 0 013.073-1.265h.074L10.848.913A3.19 3.19 0 008.604 0z"
    />
    <path
      fill="url(#paint1_linear)"
      d="M5.524 10.023c.378-.744.301-1.721.91-4.853.567-2.92 1.928-4.048 3.572-4.85a3.196 3.196 0 00-1.4-.32c-.84 0-1.64.323-2.235.924L1.013 6.242C-.202 7.45-.34 9.348.687 10.562a3.114 3.114 0 002.266 1.118c.405.01.808-.05 1.183-.183.779-.675 1.163-1.032 1.388-1.474z"
    />
    <path
      fill="#33547B"
      className="J"
      d="M18.04 9.226c0-1.18-.802-1.943-1.488-2.629a2.805 2.805 0 00-1.98-.818c-.728 0-1.419.283-1.93.792l-1.927 1.914 1.872 1.86v6.828c0 .927-.36 1.79-1.022 2.446a3.475 3.475 0 01-5.652-1.099 2.738 2.738 0 00-2.523-1.654c-.352 0-.71.069-1.062.212-1.39.606-2.033 2.197-1.45 3.571 1.397 3.288 4.63 5.417 8.23 5.425a8.867 8.867 0 006.309-2.608 8.793 8.793 0 002.615-6.266l.008-7.974z"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="7.795"
        x2="6.339"
        y1="7.075"
        y2="6.148"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CB6CCD" />
        <stop offset="1" stopColor="#B061B1" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="8.907"
        x2="1.172"
        y1=".786"
        y2="10.867"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F098F2" />
        <stop offset=".417" stopColor="#F7C3F8" stopOpacity=".583" />
        <stop offset="1" stopColor="#BB56BD" />
      </linearGradient>
    </defs>
  </svg>
)

const FooterStyled = styled.footer`
  background-color: #714072;
  color: white;
  height: 100%;

  svg {
    fill: white;
  }

  .J {
    fill: white;
  }

  ._homepage & svg,
  ._homepage & .J,
  ._neuf & svg,
  ._neuf & .J,
  ._agence & svg,
  ._agence & .J,
  ._mobilipass & svg,
  ._mobilipass & .J,
  ._chasseur-location & svg,
  ._chasseur-location & .J,
  ._rent & svg,
  ._rent & .J {
    fill: white;
  }

  a {
    color: white;
  }

  ._homepage &,
  ._neuf &,
  ._agence &,
  ._mobilipass &,
  ._chasseur-location &,
  ._rent & {
    background-color: #714072;
    color: white;

    a {
      color: white;
    }
  }

  .apps {
    background-color: #855c87;

    ._homepage &,
    ._neuf &,
    ._agence &,
    ._mobilipass &,
    ._chasseur-location &,
    ._rent & {
      background-color: #855c87;
    }
  }

  ._alert_loading &,
  ._unsubscribe & {
    display: none;
  }

  @media screen and (max-width: 599px) {
    ._alert_create &,
    ._alert_result & {
      display: none;
    }
  }

  ._alert_result & {
    padding-bottom: 130px;

    @media screen and (min-width: 960px) {
      padding-bottom: 0;
    }
  }

  @media screen and (min-width: 599px) {
    br {
      display: none;
    }
  }
`

const FooterSL = ({ layout_mw, hide_stores }) => {
  const google_play_link = useGooglePlayLink()
  const apple_store_link = useAppStoreLink()

  return (
    <FooterStyled className="la_webview_hide">
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
        px={{ lg: 0, xs: 20 }}
        maxWidth={layout_mw}
        mx="auto"
        py={30}
        height="100%"
      >
        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection="column"
          alignItems={{ md: 'flex-start', xs: 'center' }}
          height="100%"
          flex={1}
        >
          <Box
            display="flex"
            flexDirection={{ md: 'row', xs: 'column' }}
            alignItems="center"
            mb={{ md: 0, xs: 30 }}
          >
            <IconJinka />
            <Box as="p" ml={10} className="fz-16" textAlign={{ md: 'left', xs: 'center' }}>
              1 app, tous les sites immobiliers
            </Box>
          </Box>
          <Box mt="auto" textAlign={{ md: 'left', xs: 'center' }} className="fz-14">
            <Box as="p">2016-2024 - Jinka - Tous droits réservés</Box>
            <Box as="p">
              <Box as="a" className="tdu" href="/cgu">
                CGU
              </Box>{' '}
              -{' '}
              <Box
                as="a"
                className="tdu"
                href="https://www.iubenda.com/privacy-policy/31052839"
                target="_blank"
                rel="noopener noreferrer"
              >
                Politique de confidentialité
              </Box>{' '}
              <Box as="span" display={{ sm: 'inline-block', xs: 'none' }}>
                -
              </Box>{' '}
              <br />
              <Box
                as="a"
                className="tdu"
                href="https://www.iubenda.com/privacy-policy/31052839/cookie-policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Politique de cookies
              </Box>
            </Box>
            <Box
              as="p"
              display="flex"
              alignItems="center"
              justifyContent={{ md: 'flex-start', xs: 'center' }}
            >
              <Box as="span" mr={10}>
                Conçu et développé en France avec️
              </Box>{' '}
              <IconHeart width={12} height={12} />
            </Box>
          </Box>
        </Box>
        <Box display={{ md: 'flex', xs: 'none' }}>
          <Box display="flex" alignItems="flex-start" flexDirection="column" ml={30}>
            {/* <Box
              as="a"
              className="tdh"
              target="_blank"
              rel="noopener noreferrer"
              href="https://blog.jinka.fr/"
              mt={10}
            >
              Blog
            </Box> */}
            {/* <Box as="a" className="tdh" mt={15} href="/achat-logement-neuf-idf">
              Neuf
            </Box> */}
            {/* <Box
              as="a"
              className="tdh"
              target="_blank"
              rel="noopener noreferrer"
              mt={10}
              href="https://jinkamatch.framer.website/"
            >
              Chasseur achat
            </Box> */}
          </Box>
        </Box>
        <Box display={{ md: 'flex', xs: 'none' }}>
          <Box display="flex" alignItems="flex-start" flexDirection="column" ml={30}>
            {/* <Box
              as="a"
              className="tdh"
              target="_blank"
              rel="noopener noreferrer"
              href="https://blog.jinka.fr/"
              mt={10}
            >
              Blog
            </Box> */}
            {/* <Box as="a" className="tdh" mt={15} href="/achat-logement-neuf-idf">
              Neuf
            </Box> */}
            <Box as="a" className="tdh" mt={0} href="/pro">
              Agence
            </Box>
            <Box as="a" className="tdh" mt={20} href="/kit">
              Presse
            </Box>
            <Box
              as="a"
              className="tdh"
              target="_blank"
              rel="noopener noreferrer"
              mt={20}
              href="https://www.welcometothejungle.com/fr/companies/jinka"
            >
              Recrutement
            </Box>
            <Box
              as="a"
              className="tdh"
              target="_blank"
              rel="noopener noreferrer"
              // href="https://loueragile.zendesk.com/hc/fr/requests/new"
              href="mailto:contact@jinka.fr"
              mt={20}
            >
              Contact
            </Box>
            <Box as="a" className="tdh" mt={20} href="/chasseur-location">
              Chasseur location
            </Box>
          </Box>
        </Box>
        <Box display={{ md: 'flex', xs: 'none' }}>
          <Box display="flex" alignItems="flex-start" flexDirection="column" ml={30}>
            <Box
              as="a"
              className="tdh"
              target="_blank"
              rel="noopener noreferrer"
              mt={0}
              href="https://twitter.com/jinka_app"
            >
              Twitter
            </Box>
            <Box
              as="a"
              className="tdh"
              target="_blank"
              rel="noopener noreferrer"
              mt={20}
              href="https://www.linkedin.com/in/marclebel/"
            >
              Linkedin
            </Box>
            <Box
              as="a"
              className="tdh"
              target="_blank"
              rel="noopener noreferrer"
              mt={20}
              href="https://www.instagram.com/jinka_app/"
            >
              Instagram
            </Box>
            <Box
              as="a"
              className="tdh"
              target="_blank"
              rel="noopener noreferrer"
              mt={20}
              href="https://www.tiktok.com/@jinka_app"
            >
              TikTok
            </Box>
          </Box>
        </Box>
        {!hide_stores && (
          <Box
            display={{ md: 'flex', xs: 'none' }}
            flexDirection="column"
            borderRadius={10}
            p={20}
            ml={60}
            className="apps"
          >
            <Box
              as="a"
              href={apple_store_link}
              target="_blank"
              rel="noopener noreferrer"
              textAlign="center"
              onClick={() => {
                dataLayer.push({
                  event: 'eventGA',
                  category: 'CTAInstall',
                  action: 'DirectFooter'
                })
                dataLayer.push({
                  event: 'link_app_ios'
                })
              }}
            >
              <Box
                as="img"
                src="https://res.cloudinary.com/loueragile/image/upload/v1601450698/web/jinka/Apple.png"
                height={58}
                alt="Télécharger dans l'App Store"
              />
            </Box>
            <Box
              as="a"
              href={google_play_link}
              target="_blank"
              rel="noopener noreferrer"
              textAlign="center"
              onClick={() => {
                dataLayer.push({
                  event: 'eventGA',
                  category: 'CTAInstall',
                  action: 'DirectFooter'
                })
                dataLayer.push({
                  event: 'link_app_android'
                })
              }}
              mt={20}
            >
              <Box
                as="img"
                src="https://res.cloudinary.com/loueragile/image/upload/v1601450698/web/jinka/Google.png"
                height={58}
                alt="Disponible sur Google Play"
              />
            </Box>
          </Box>
        )}
      </Box>
    </FooterStyled>
  )
}

const LayoutFooter = ({ layout_mw, hide_stores }) => (
  <FooterSL layout_mw={layout_mw} hide_stores={hide_stores} />
)
export default LayoutFooter
