import React from 'react'
import styled from 'styled-components'
import { Box } from '../Shared/Base'

const CGUPage = styled.main`
  padding-bottom: 100px;

  p {
    font-size: 1.6rem;
    line-height: 2.4rem;

    + p {
      margin-top: 20px;
    }
  }

  a {
    color: var(--colorN_tertiary);
    text-decoration: underline;

    &:hover {
      color: var(--colorN_tertiary);
    }
  }
`

const CGU = () => {
  return (
    <CGUPage>
      <Box maxWidth={1200} mx="auto" my={30} px={{ lg: 0, xs: 20 }}>
        <Box as="h2" className="h2-like" mb={30}>
          Conditions Générales d’Utilisation
        </Box>
        <p>
          Les présentes conditions générales d’utilisation Jinka (ci-après désignées les « 
          <strong>Conditions Générales</strong> ») régissent les relations entre toute personne
          physique qui s’est inscrite sur le site Internet disponible à l’adresse URL{' '}
          <a href="http://www.jinka.fr">www.jinka.fr</a> (ci-après désignée l’« 
          <strong>Utilisateur</strong> ») et la société Babel France, société par actions simplifiée
          au capital de 1.420,80 euros, immatriculée au registre du commerce et des sociétés de
          Versaillessous le numéro 840936 744, dontle siège social est situé au 25, rue Etienne
          Peroux – 78600 Maisons-Laffite, représentée par Monsieur Marc Lebel, en sa qualité de
          Président, dûment habilité aux fins des présentes (ci-après désignée «{' '}
          <strong>Jinka</strong> »). Jinka estimmatriculée à l’ORIAS sous le numéro 18006713 en tant
          que courtier en assurance;
        </p>
        <p>
          La création d’un compte Utilisateur sur le site Internet{' '}
          <a href="http://www.jinka.fr">www.jinka.fr</a> implique l’acceptation pleine et entière
          des Conditions Générales, dont l’Utilisateur reconnaît avoir pris connaissance, les avoir
          comprises et acceptées en parfaite connaissance de cause.
        </p>
        <p>
          L’Utilisateur peut à tout moment les consulter, les reproduire, les stocker sur son
          ordinateur, ou sur un autre support, se les envoyer par courrier électronique ou les
          imprimer sur papier de manière à les conserver.
        </p>
        <p>
          Jinka et l’Utilisateur sont ci-après dénommés individuellement une «{' '}
          <strong>Partie</strong> » etcollectivement les « <strong>Parties</strong> »
        </p>

        <Box as="h3" className="h3-like" mt={30} mb={30}>
          Article 1 Définitions
        </Box>
        <p>
          Les termes et expressions dont la première lettre de chaque mot est en majuscule ont, au
          sein du Contrat, la signification qui leur est attribuée ci-après, qu’ils soient utilisés
          au singulier ou au pluriel.
        </p>
        <p>
          <strong>1.1</strong> <strong>« Candidat »</strong> désigne l’Utilisateur inscrit sur la
          Plateforme Louer Agile afin de consulter les liens vers les annonces immobilières qu’elle
          a rassemblés et qui correspondent à sa recherche immobilière.
        </p>
        <p>
          <strong>1.2</strong> <strong>« Force Majeure »</strong> désigne un évènement extérieur aux
          Parties, imprévisible et irrésistible, tel que défini par la jurisprudence des tribunaux
          française, en ce compris : guerre (déclarée ou non) ; acte terroriste ; invasion ;
          rébellion ; blocus ; sabotage ou acte de vandalisme ; grève ou conflit social, total ou
          partiel, externe à chacune des Parties ; intempérie (notamment inondations, orages et
          tempêtes) ; évènement déclaré « catastrophe naturelle » ; incendie ; épidémie ; invasion
          de zombies ; blocage des moyens de transport ou d’approvisionnement (notamment en
          énergie) ; défaillance dans la fourniture de l’énergie électrique, du chauffage, de l’air
          conditionné, des réseaux de télécommunications, du transport des données ; défaillance de
          satellites.
        </p>
        <p>
          <strong>1.3</strong> <strong>« Plateforme Jinka »</strong>  désigne le site Internet édité
          par Jinka disponible à l’adresse URL www.jinka.fr qui présente au Candidat les liens vers
          les annonces immobilières qui correspondent à sa recherche.
        </p>
        <p>
          <strong>1.4</strong> <strong>« Services »</strong> désigne les prestations proposées sur
          la Plateforme Jinka.
        </p>

        <Box as="h3" className="h3-like" mt={30} mb={30}>
          ARTICLE 2 OBJET
        </Box>
        <p>
          Les Conditions Générales ont pour objet de définir les conditions d’utilisation des
          Services.
        </p>

        <Box as="h3" className="h3-like" mt={30} mb={30}>
          Article 3 Inscription sur Jinka
        </Box>
        <p>
          L’Utilisateur doit être âgé d’au moins dix-huit (18) ans et être capable juridiquement de
          contracter et d’utiliser la Plateforme Jinka conformément aux stipulations des Conditions
          Générales.
        </p>
        <p>
          L’Utilisateur garantit qu’il est une personne physique qui agit pour son propre compte et
          non pour le compte d’une personne morale ou à des fins professionnelles.
        </p>
        <p>
          En vue de son inscription sur la Plateforme Jinka, l’Utilisateur doit renseigner les
          champs du formulaire d’inscription au moyen de données complètes et exactes.
        </p>
        <p>
          Afin de vérifier les informations fournies et de valider l’inscription de l’Utilisateur,
          il reçoit instantanément un lien hypertexte par courrier électronique à l’adresse qu’il a
          communiquée à Jinka afin de lui permettre de créer son mot de passe et finaliser son
          inscription (pour des raisons de sécurité et de confidentialité, il est recommandé à
          l’Utilisateur de choisir des mots de passe composés de plusieurs types de caractères, et
          de le modifier régulièrement).
        </p>
        <p>
          En cas de déclaration incomplète ou erronée, le compte de l’Utilisateur peut être fermé,
          de plein droit, sans préavis et sans formalité préalable. En tout état de cause,
          l’Utilisateur consent à garantir Jinka ou Louer Agile Immo de toutes les conséquences qui
          pourraient découler de cette déclaration incomplète ou erronée.
        </p>
        <p>
          Le compte personnel de chaque Utilisateur lui permet de mettre à jour les données le
          concernant.
        </p>
        <p>
          L’identifiant et le mot de passe sont uniques et personnels. Ils ne doivent pas être
          divulgués à des tiers. Toute utilisation de la Plateforme Louer Agile réalisée au moyen de
          l’identifiant et du mot de passe d’un Utilisateur sera réputée avoir été réalisée par
          ledit Utilisateur. En cas de divulgation de son identifiant et mot de passe, l’Utilisateur
          doit contacter dans les plus brefs délais Louer Agile ou Louer Agile Immo à l’adresse
          suivante <a href="mailto:contact@jinka.fr">contact@jinka.fr</a> afin que ceux-ci soient
          désactivés.{' '}
        </p>

        <Box as="h3" className="h3-like" mt={30} mb={30}>
          Article 4 Contenu des services
        </Box>
        <p>
          <strong>4.1 Services offerts par Louer Agile aux Candidats</strong>
        </p>
        <p>
          <i>4.1.1 Services offerts par Louer Agile aux Candidats</i>
        </p>
        <p>La Plateforme Jinka intègre un outil de recherche d’annonces immobilières.</p>
        <p>
          Afin de pouvoir trouver une annonce immobilière qui correspond à sa recherche, le Candidat
          doit créer une alerte en renseignant les informations suivantes sur le formulaire dédié :
        </p>
        <p>
          (i) localisation du bien recherché ; <br />
          (ii) loyer maximum ; <br />
          (iii) surface minimum ; <br />
          (iv) nombre de pièces minimum ; <br />
          (v) étage souhaité ; <br />
          (vi) annonce publiée par un particulier ou une agence ; <br />
          (vii) situation du logement actuel ; <br />
        </p>
        <p>
          Dès que l’alerte aura été créée par le Candidat, la Plateforme traduira en temps réel la
          requête du Candidat afin d’identifier les annonces immobilières, correspondant à sa
          recherche, qui sont disponibles sur des sites d’annonces immobilières.
        </p>
        <p>
          Les annonces identifiées lors de cette recherche sont présentées au Candidat, de la plus
          récente à la plus ancienne, en réunissant les doublons en un seul élément.
        </p>
        <p>
          Lors de la soumission de sa requête, la Plateforme Jinka précisera au Candidat les chances
          de succès de sa recherche.
        </p>
        <p>
          La recherche du Candidat est automatiquement renouvelée, à intervalles réguliers, sans
          intervention de sa part, afin d’identifier les nouvelles annonces rendues disponibles en
          ligne. Lorsque de nouvelles annonces sont identifiées par l’une de ces recherches
          subséquentes, les liens correspondants seront envoyés par la Plateforme Jinka au Candidat,
          sur l’adresse e-mail qu’il aura mentionnée ou par notification sur l'application mobile
          Jinka.
        </p>
        <p>
          Le Candidat aura la possibilité de partager les résultats de la recherche avec tous tiers.
        </p>
        <p>
          Pour accéder à l’intégralité des annonces immobilières et y postuler, le Candidat devra
          cliquer sur les liens hypertextes permettant d’accéder directement au site Internet tiers
          de l’éditeur de l’annonce concernée.
        </p>
        <p>
          Le Candidat reconnaît que la Plateforme Jinka se contente, via une transmission de la
          requête du Candidat à plusieurs sites tiers, de lister des liens hypertextes pointant vers
          des annonces immobilières correspondant à sa recherche, lesquels permettent d’accéder à
          des sites Internet tiers, éditeurs desdites annonces.
        </p>

        <Box as="h3" className="h3-like" mt={30} mb={30}>
          Article 5 Disponibilité de la Plateforme Jinka
        </Box>
        <p>La Plateforme Jinka est accessible à distance, par le réseau Internet.</p>
        <p>
          L’Utilisateur fait son affaire personnelle de la mise en place des moyens informatiques et
          de télécommunications permettant l’accès à la Plateforme Jinka. Ils conservent à leur
          charge les frais de télécommunication lors de l’accès à Internet lors de l’utilisation de
          la Plateforme Jinka.
        </p>
        <p>
          Jinka met en œuvre leurs meilleurs efforts afin que la Plateforme Jinka soit accessible
          vingt-quatre heures sur vingt-quatre (24 h/24), sept jours sur sept (7 j/7).
        </p>
        <p>
          L’Utilisateur reconnaît expressément qu’il est averti des aléas techniques qui peuvent
          affecter le réseau Internet et entraîner des ralentissements ou des indisponibilités
          rendant la connexion impossible. Jinka ne peut être tenu responsable des difficultés
          d’accès à la Plateforme Jinka dus à des perturbations du réseau Internet.
        </p>
        <p>
          Jinka se réserve le droit, sans préavis ni indemnité, de suspendre temporairement l’accès
          à ses services lors de la survenance de pannes éventuelles ou de toute opération de
          maintenance nécessaire à son bon fonctionnement.
        </p>
        <p>
          Jinka se réservele droit d’apporter toute modification ou amélioration qu’elle jugera
          nécessaire.
        </p>

        <Box as="h3" className="h3-like" mt={30} mb={30}>
          Article 6 Protection des données à caractère personnel
        </Box>
        <p>
          <strong>6.1 Collecte des données à caractère personnel</strong>
        </p>
        <p>
          Jinka traite les données à caractère personnel des Utilisateurs pour leur propre compte,
          ces données étant (i) communiquées par les Utilisateurs au moment de leur inscription sur
          la Plateforme Jinka, (ii) lors de l’accès aux Services proposés sur la Plateforme Jinka ou
          (iii) ultérieurement via leur compte personnel.
        </p>
        <p>
          Les informations pour lesquelles une communication est obligatoire sont identifiées comme
          telles lors de l’inscription d’un Utilisateur, de même que lors de la mise en œuvre des
          Services.
        </p>
        <p>
          Sauf sur demande de l’Utilisateur ou avec son consentement exprès et dans le strict
          respect de ses directives, Jinka ne procède à aucun traitement de données à caractère
          personnel autre que ceux décrits dans le présent Article 8.
        </p>
        <p>
          <strong>Bases légales des traitements mis en œuvre</strong>
        </p>
        <p>
          Conformément à la législation applicable en matière de protection des données à caractère
          personnel, les traitements de données à caractère personnel mis en œuvre par Jinka
          s’appuient sur l’une des bases légales visées ci-après.
        </p>
        <p>
          <i>6.2.1 L’exécution des relations contractuelles de Jinka Utilisateurs</i>
        </p>
        <p>
          Afin de bénéficier des Services qu’elle propose, les Utilisateurs ont dû accepter les
          Conditions Générales.
        </p>
        <p>
          Ce document formalise une relation contractuelle entre chacun des Utilisateurs et Jinka,
          et sert de base juridique de la collecte et du traitement de leurs données à caractère
          personnel.
        </p>
        <p>
          <i>6.2.2 Consentement des Utilisateurs</i>
        </p>
        <p>
          Lorsque les Utilisateurs naviguent sur la Plateforme Jinka, Jinka peut être amenée à
          installer divers cookies sur l’espace de stockage de leur terminal (p. ex. ordinateur,
          appareil mobile).
        </p>
        <p>
          Avant d’installer des cookies sur le terminal de l’Utilisateur, Jinka recueille le
          consentement de ce dernier par le biais d’un bandeau-cookie.
        </p>
        <p>
          Lorsque les utilisateurs remplissent et valident le formulaire de demande de partenariat,
          ils acceptent d’être recontacté par un partenaire spécialisé susceptible de répondre à sa
          demande.
        </p>
        <p>
          <i>6.2.3 Respect d’une obligation légale à laquelle Jinka est soumise</i>
        </p>
        <p>
          Les traitements de données à caractère personnel des Utilisateurs mis en œuvre par Jinka
          peut être imposés par une obligation légale lui incombant (p. ex. le décret n° 2011-219 du
          25 février 2011 relatif à la conservation des données permettant d’identifier toute
          personne ayant contribué à la création d’un contenu mis en ligne).
        </p>
        <p>
          <i>6.2.4 Intérêts légitimes de Jinka</i>
        </p>
        <p>
          Dans certaines hypothèses, Jinka peut disposer d’un intérêt légitime l’autorisant à
          traiter les données à caractère personnel des Utilisateurs (p. ex. traitement visant à
          prévenir la fraude).
        </p>
        <p>
          Dans ce cadre, Jinka veille à ce que le traitement mis en œuvre soit nécessaire à la
          réalisation de son intérêt légitime et qu’il ne porte pas atteinte aux intérêts et droits
          fondamentaux des Utilisateurs.
        </p>
        <p>
          <strong>6.3 Catégories des données a caractère personnel collectées</strong>
        </p>
        <p>
          Les Utilisateurs sont informés sur chaque formulaire de collecte de données à caractère
          personnel du caractère obligatoire ou facultatif des réponses par la présence d’un
          astérisque.
        </p>
        <p>
          Dans le cas où des réponses sont obligatoires, Jinka expose aux Utilisateurs les
          conséquences d’une absence de réponse.
        </p>
        <p>
          Les catégories données à caractère personnel collectées dans le cadre de l’utilisation de
          la Plateforme sont les suivantes :
        </p>
        <p>
          (i) identification (p. ex. nom, prénom, civilité, pseudonyme, pseudo réseaux sociaux) ;{' '}
          <br />
          (ii) coordonnées (p. ex. téléphone, adresse e-mail, adresse postale) ; <br />
          (iii) vie professionnelle (p.ex. profession) ; <br />
          (iV) données bancaires, lorsque nécessaire ; <br />
          (v) vie personnelle et habitudes de vie (p. ex. habitudes d’achat) ; <br />
          (vi) données de connexion (p. ex. logs).
        </p>
        <p>
          Jinka ne traite pas de données sensibles au sens de l’article 9 du Règlement (UE) 2016/679
          relatif à la protection des personnes physiques à l'égard du traitement des données à
          caractère personnel et à la libre circulation de ces données (ci-après désigné le «{' '}
          <strong>RGPD</strong> »).
        </p>
        <p>
          <strong>6.4 Durées de conservation des données a caractère personnel </strong>
        </p>
        <p>
          Les données à caractère personnel des Utilisateurs sont conservées pendant une durée de
          trois (3) ans à compter de leur collecte.
        </p>
        <p>
          Les statistiques de mesures d’audience ne sont pas conservées pour une durée supérieure à
          treize (13) mois.
        </p>
        <p>
          Toutefois, à l’issue des délais précités, en ce compris, en tant que de besoin, à compter
          de la demande de suppression de l’un des Utilisateurs, ses données à caractère personnel
          pourront faire l’objet d’un archivage intermédiaire afin que Jinka puisse satisfaire à ses
          obligations légales de conservation :
        </p>
        <p>
          (i) le mandat conclu sera conservé dix (10) ans à compter de sa conclusion ; <br />
          (ii) les documents bancaires seront conservés cinq (5) ans à compter de leur communication
          ; <br />
          (iii) les documents relatifs à la gestion de la facturation seront conservés dix (10) ans.
        </p>
        <p>
          Certaines données pourront être archivées au-delà des durées prévues (i) en cas de
          procédure contentieuse afin de permettre d’établir la réalité des faits litigieux ; et/ou
          (ii) pour les besoins de la recherche, de la constatation et de la poursuite des
          infractions pénales dans le seul but de permettre, en tant que de besoin, la communication
          de ces données à l’autorité judiciaire.
        </p>
        <p>
          L’archivage implique que ces données soient anonymisées et ne soient plus consultables en
          ligne mais soient extraites et conservées sur un support autonome et sécurisé.
        </p>
        <p>
          Passés les délais fixés dans le présent Article 7, les données à caractère personnel des
          Utilisateurs sont supprimées.
        </p>
        <p>
          <strong>6.5 Finalités des traitements de données à caractère personnel </strong>
        </p>
        <p>
          Les traitements de données à caractère personnel des Utilisateurs par Jinka sont
          nécessaires pour lui permettre d’accomplir les finalités suivantes :
        </p>
        <p>
          (i) l’accès et l’utilisation de la Plateforme Jinka ; <br />
          (ii) l’analyse statistique et les informations marketing ; <br />
          (iii) lorsque la loi l’exige ou dans le cadre d'une procédure judiciaire ou d’un litige ;{' '}
          <br />
          (iv) la gestion des demandes d’exercice des droits énumérés à l’article 7.6 ci-dessous ;{' '}
          <br />
          (v) toute autre utilisation permettant l’amélioration de la Plateforme Jinka et/ou de tout
          autre Service.
          <br />
          (vi) la mise en relation avec des partenaires spécialisés susceptibles de répondre à la
          demande des Utilisateurs.
        </p>
        <p>
          <strong>6.6 Droits des Utilisateurs </strong>
        </p>
        <p>
          Les Utilisateurs disposent d’un droit d’accès, de modification, d’opposition, de
          limitation, de portabilité, de rectification, de définir des directives relatives au sort
          de leurs données après leur décès et de suppression de leurs données à caractère
          personnel, ces derniers étant conditionnés au respect des règles suivantes :
        </p>
        <p>
          (i) la demande émane de la personne elle-même et est accompagnée d’une copie d’un titre
          d’identité, à jour ; <br />
          (ii) la demande doit être formulée par écrit à l’adresse suivante :{' '}
          <strong>Babel France – 25 rue Etienne Péroux 78600 Maisons-Laffitte</strong>.
        </p>
        <p>
          Au visa du droit à la portabilité des données, les informations demandées par
          l’Utilisateur lui seront fournies sous une forme électronique, sauf demande expresse
          contraire.
        </p>
        <p>
          S’agissant du droit à la suppression des données personnelles des Utilisateurs, ce dernier
          ne sera pas applicable dans les cas où le traitement est mis en œuvre pour répondre à une
          obligation légale.
        </p>
        <p>
          <strong>6.7 Données à caractère personnel partagées avec des tiers</strong>
        </p>
        <p>
          Toutes les données à caractère personnel collectées et traitées par Jinka sont strictement
          confidentielles.
        </p>
        <p>
          Jinka pourra être amenée à transférer de manière temporaire et sécurisée à des tiers
          certaines données à caractère personnel des Utilisateurs seulement lorsque cela est
          nécessaire :
        </p>
        <p>
          (i) à l’exploitation et à la maintenance de la Plateforme Jinka (p. ex. hébergeur de la
          Plateforme Jinka) ; <br />
          (ii) afin de répondre à une injonction des autorités légales.
        </p>
        <p>
          A ce titre, Jinka veille à ce que l’ensemble de ses partenaires destinataires de données à
          caractère personnel des Utilisateurs placent la protection des données à caractère
          personnel au cœur de leur mission et s’engagent à prendre toutes mesures afin d’assurer la
          sécurité et la confidentialité des données personnelles.
        </p>
        <p>
          En sus, lorsque le tiers concerné est situé en dehors de l’Union européenne, ou dans un
          pays ne disposant pas d’une réglementation adéquate au sens du RGPD, Jinka encadre sa
          relation avec ce tiers en adoptant un dispositif contractuel approprié.
        </p>
        <p>
          Au titre de la mission de mise en relation de Jinka, les données communiquées par les
          Utilisateurs suite à leur demande de renseignement formulée sur l’application Jinka, sont
          transmises à des professionnels susceptibles de répondre à leur demande d’information. Une
          fois le formulaire de contact rempli et envoyé, l’Utilisateur recevra de la part de Jinka
          un email lui indiquant l’identité du professionnel ayant reçu son contact. Ce
          professionnel prendra alors directement contact avec l’Utilisateur pour lui proposer le
          meilleur accompagnement pour trouver sa solution d’investissement.
        </p>

        <Box as="h3" className="h3-like" mt={30} mb={30}>
          Article 7 Propriété intellectuelle
        </Box>
        <p>La Plateforme Jinka est la propriété exclusive de Babel France.</p>
        <p>
          Jinka concède à l’Utilisateur un droit personnel, gratuit, non exclusif, non cessible et
          non transférable d’utilisation de la Plateforme Jinka, pendant toute la durée de son
          compte Utilisateur et pour le Monde entier, dans les limites définies aux Conditions
          Générales.
        </p>
        <p>
          Tout contenu qui n’a pas été mis en ligne par l’Utilisateur, notamment les données,
          fonctionnalités, textes, noms, dénominations, images, photographies, éléments graphiques,
          marques, sons, est la propriété exclusive de Jinka. Aucune des stipulations des Conditions
          Générales ne saurait être interprétée comme un quelconque transfert de l’un de ces droits
          au profit de l’Utilisateur. Aucun de ces éléments ne peut être téléchargé, copié, modifié,
          supprimé, distribué, transmis, diffusé, vendu, loué, concédé ou exploité de quelque
          manière que ce soit, sans le consentement exprès, préalable et écrit de Jinka ou Jinka
          Immo.
        </p>

        <Box as="h3" className="h3-like" mt={30} mb={30}>
          Article 8 Engagements des Utilisateurs
        </Box>
        <p>L’Utilisateur s’engage à :</p>
        <p>
          (i) disposer d’un navigateur Internet à jour ainsi que d’une connexion Internet haut débit
          dont les frais restent à sa charge ; <br />
          (ii) mettre en œuvre toutes les mesures nécessaires pour assurer la confidentialité de ses
          codes relatifs à son compte Utilisateur sur la Plateforme Jinka ; <br />
          (iii) ne pas charger ou transmettre sur la Plateforme Jinka ou utiliser tout équipement,
          logiciel ou routine qui contienne des virus, chevaux de Troie, vers, bombes à retardement
          ou autres programmes et procédés destinés à endommager, interférer ou tenter d’interférer
          avec le fonctionnement normal de la Plateforme Jinka, ou s’approprier la Plateforme Jinka,
          ou encore recourir à n’importe quel moyen pour causer une saturation des systèmes de Jinka
          ou porter atteinte aux droits de tiers ; <br />
          (iv) ne pas transmettre par l’intermédiaire de la Plateforme Jinka tout contenu illégal,
          menaçant, humiliant, diffamatoire, obscène, haineux, pornographique ou blasphématoire, ou
          tout autre message qui pourrait constituer un crime ou délit, donner lieu à une
          responsabilité civile, violer ou encourager la législation, ou un contenu qui pourrait
          être utilisé à toute fin contraire à la loi ou aux Conditions Générales ; <br />
          (v) ne pas utiliser la Plateforme Jinka de manière à ce que, du point de vue de Jinka, les
          performances ou les fonctionnalités de la Plateforme Jinka, ou de tout autre système
          informatique ou réseau utilisé par Jinkaou par un quelconque tiers, soient impactés
          négativement ou que les Utilisateurs de la Plateforme Jinka soient négativement affectés.
        </p>
        <p>
          Jinka ne pourra voir sa responsabilité engagée par un manquement par l’Utilisateur à la
          législation applicable.
        </p>

        <Box as="h3" className="h3-like" mt={30} mb={30}>
          Article 9 Responsabilité
        </Box>
        <p>
          Jinka n’accorde aucune garantie qui n’est pas expressément mentionnée dans les Conditions
          Générales. En particulier, Jinka ne garantit pas l’absence de bogues et, par conséquent,
          ne garantit pas que l’utilisation de la Plateforme Jinka sera ininterrompue et sans
          erreur.
        </p>
        <p>
          Dans la mesure permise par la loi, Jinka ne pourra être tenue pour responsable en cas de
          manquement de l’Utilisateur à ses obligations, notamment mais sans limitation en cas de
          mauvaise utilisation des Services ou en cas de mauvais fonctionnement des équipements ou
          d'accès au réseau Internet de l’Utilisateur, d’interruption des Services du fait du
          caractère illicite de l’utilisation des Services.{' '}
        </p>
        <p>
          Jinka ne peut être tenue responsable de tout dommage causé par un prestataire de services
          externe (p. ex. hébergeur de la Plateforme Jinka) ou une cause externe, et en particulier,
          sans que cette liste soit exhaustive, de tout ralentissement ou interruption du réseau de
          télécommunications n’appartenant pas à Jinka, de l’interruption de l’approvisionnement en
          électricité, d’une intrusion ou de la maintenance frauduleuse d’un tiers sur le système
          malgré la mise en place de mesures de sécurité conformes aux données techniques actuelles.
        </p>
        <p>
          Jinka n’est pas responsable de la qualité de la transmission des données, des temps
          d'accès, des éventuelles restrictions d’accès sur les réseaux et/ou serveurs connectés au
          réseau Internet.
        </p>

        <Box as="h3" className="h3-like" mt={30} mb={30}>
          Article 10 Durée
        </Box>
        <p>
          Les Conditions générales sont conclues pour une période indéterminée à compter de
          l’activation du compte de l’Utilisateur sur la Plateforme Jinka.
        </p>
        <p>
          L’Utilisateur peut résilier les Conditions Générales à tout moment en supprimant son
          compte Utilisateur sur la Plateforme Jinka.
        </p>
        <p>Ainsi, l’Utilisateur ne pourra plus accéder aux Services.</p>

        <Box as="h3" className="h3-like" mt={30} mb={30}>
          Article 11 Stipulations diverses
        </Box>
        <p>
          <strong>11.1 Force Majeure</strong>
        </p>
        <p>
          Chacune des Parties ne saurait voir sa responsabilité engagée pour le cas où l’exécution
          de ses obligations serait retardée, restreinte ou rendue impossible du fait de la
          survenance d’un cas de Force Majeure. Il est expressément convenu entre les Parties que
          les stipulations du présent Article 11.3 ne sont pas applicables aux obligations de payer.
        </p>
        <p>
          Dans l’hypothèse de la survenance d’une Force Majeure, l’exécution des obligations de
          chaque Partie est suspendue. Si la Force Majeure se poursuit pendant plus d’un (1) mois,
          les Conditions Générales pourront être résiliées à la demande de la Partie la plus
          diligente sans pour autant que la responsabilité d’une Partie puisse être engagée à
          l’égard de l’autre. Chacune des Parties supporte la charge de tous les frais qui lui
          incombent et qui résultent de la survenance de la Force Majeure.
        </p>
        <p>
          <strong>11.2 Modification des Conditions Générales</strong>
        </p>
        <p>
          Jinka se réserve le droit d’apporter, à tout moment, toute modification aux présentes
          Conditions Générales qu’elle juge nécessaire et utile.
        </p>
        <p>
          En cas de modification des Conditions Générales, Jinka s’engage à ce que l’Utilisateur
          accepte les nouvelles conditions générales lorsqu’il accède à nouveau à la Plateforme
          Jinka.
        </p>
        <p>
          <strong>11.3 Notification d’un contenu illégal </strong>
        </p>
        <p>
          Conformément aux dispositions de l’article 5, I, 7° de la loi n° 2004-575 du 21 juin 2004,
          Jinka n’est pas soumise à une obligation générale de surveillance des informations
          hébergées sur la Plateforme Jinka.
        </p>
        <p>
          Dans l’hypothèse où l’Utilisateur constaterait que des contenus postés sur la Plateforme
          Jinka et/ou rendus accessibles via un lien hypertexte violent les dispositions légales et
          réglementaires en vigueur, et notamment constituent des contenus manifestement illicites
          (diffamatoires, dénigrants ou portant atteinte à des droits de propriété intellectuelle),
          il pourra en informer Jinka en envoyant un e-mail à l’adresse e-mail suivante :{' '}
          <a href="mailto:contact@jinka.fr">contact@jinka.fr</a>.
        </p>
        <p>
          L’Utilisateur doit fournir à Jinka toutes les informations nécessaires pour permettre à
          Jinka d’identifier le contenu litigieux, à savoir :
        </p>
        <p>
          (i) ses nom, prénom(s), profession, domicile, nationalité, date et lieu de naissance ;{' '}
          <br />
          (ii) la description des faits contestés et leur localisation précise ; <br />
          (iii) les raisons pour lesquelles le contenu devrait être supprimé, y compris le fondement
          juridique et factuel.
        </p>
        <p>
          <strong>11.4 Convention de preuve</strong>
        </p>
        <p>
          Les registres informatisés seront conservés dans les systèmes informatiques de Jinka dans
          des conditions raisonnables de sécurité et seront considérés comme les preuves des
          échanges et/ou des actions réalisées par l’Utilisateur, ce que ce dernier déclare accepter
          expressément.
        </p>
        <p>
          <strong>11.5 Renonciation</strong>
        </p>
        <p>
          Le fait que l’une ou l’autre des Parties n’exerce pas l’un quelconque de ses droits au
          titre des présentes ne saurait emporter renonciation de sa part à son exercice, une telle
          renonciation ne pouvant procéder que d’une déclaration expresse de la Partie concernée.
        </p>
        <p>
          <strong>11.6 Invalidité partielle</strong>
        </p>
        <p>
          Dans l’hypothèse où une ou plusieurs stipulations des Conditions Générales seraient
          considérées comme non valides par une juridiction compétente, les autres clauses
          conserveront leur portée et effet.
        </p>
        <p>
          La stipulation considérée comme invalide sera remplacée par une stipulation dont le sens
          et la portée seront le plus proches possibles de la clause ainsi invalidée, tout en
          restant conforme à la législation applicable et à la commune intention des Parties.
        </p>
        <p>
          <strong>11.7 Opposition au démarchage téléphonique</strong>
        </p>
        <p>
          L’Utilisateur peut s’opposer à recevoir de la prospection commerciale par voie
          téléphonique en s’inscrivant gratuitement sur la liste d’opposition au démarchage
          téléphonique sur le site internet www.bloctel.gouv.fr ou par courrier postal à : Société
          Opposetel, Service Bloctel, 6, rue Nicolas Siret, 10000 Troyes.{' '}
        </p>
        <p>
          L’inscription sur la liste d’opposition au démarchage téléphonique est valable pour une
          période de trois (3) ans renouvelable.
        </p>
        <p>
          Cette inscription interdit à un professionnel tout démarchage téléphonique, sauf en cas de
          relations contractuelles préexistantes.
        </p>

        <Box as="h3" className="h3-like" mt={30} mb={30}>
          Article 12 Applicable Law – Litigation
        </Box>
        <p>
          <strong>12.1 Loi applicable</strong>
        </p>
        <p>Le Contrat est régi par le droit français.</p>
        <p>
          <strong>12.2 Contentieux</strong>
        </p>
        <p>
          L’Utilisateur peut recourir gratuitement à la médiation pour tout litige contractuel à
          condition d’avoir préalablement saisi Jinka d’une réclamation qui n’a pas permis de
          résoudre ledit litige.
        </p>
      </Box>
    </CGUPage>
  )
}

export default CGU
