import React from 'react'
import { useLocation } from 'react-router-dom'
import { qps_from_search } from '../../helpers/misc'
import { Box, Text } from '../Shared/Base'

const SettingsAppRedirect = () => {
  const location_search = useLocation().search
  const path = qps_from_search(location_search).path || '/'

  const app_url = `${process.env.REACT_APP_MOBILE_BASE_URL}${path}`

  React.useEffect(() => {
    if (process.env.REACT_APP_MOBILE_BASE_URL && document && document.location) {
      document.location.href = app_url
    }
  }, [app_url])

  return (
    <Box maxWidth={770} mx="auto" px={20} my={60}>
      <Box
        display="flex"
        flexDirection={{ sm: 'row', xs: 'column' }}
        alignItems={{ sm: 'center', xs: 'center' }}
        mb={40}
      >
        <img
          src="https://res.cloudinary.com/loueragile/image/upload/v1588063312/web/alertes/Avatar.png"
          alt=""
          width="62"
          height="62"
        />
        <Text
          className="fz-18"
          ml={{ sm: 30, xs: 0 }}
          mt={{ sm: 0, xs: 20 }}
          textAlign={{ sm: 'left', xs: 'center' }}
        >
          <strong>Continuez sur l'application</strong>
          <br />
          Ce lien est destiné à être ouvert sur un téléphone avec l'application Jinka
          <br />
          <br />
          <a href={app_url} className="btn btn-simple tdu">
            Ouvrir Jinka
          </a>{' '}
        </Text>
      </Box>
    </Box>
  )
}

export default SettingsAppRedirect
