import React from 'react'
import FormField, { FocusableField } from '../../Shared/Form/Field'
import { Box } from '../../Shared/Base'
import FormInputLocalisation from '../../Shared/Form/InputLocalisation'
import {
  FormTextFieldInput,
  FormSelectNativeInput,
  FormMaskedInput,
  FormCheckboxInput
} from '../../Shared/Form/Input'
import { options_from_hashmap } from '../../../helpers/misc'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import { useImmerStore } from '../../../store'

export const form_options = {
  floor: options_from_hashmap({
    '0': 'Rez-de-chaussée uniquement',
    '1': 'Pas de rez-de-chaussée',
    '-1': 'Dernier étage'
  }),
  lift: options_from_hashmap({
    '1': 'A partir du 1er étage',
    '2': 'A partir du 2ème étage',
    '3': 'A partir du 3ème étage',
    '4': 'A partir du 4ème étage',
    '5': 'A partir du 5ème étage'
  }),
  owner_type: options_from_hashmap({
    Particulier: 'Un particulier uniquement',
    Agence: 'Une agence uniquement'
  }),
  tenement_type: options_from_hashmap({
    Appartement: 'Appartement',
    Maison: 'Maison'
  }),
  photos: options_from_hashmap({
    '1': 'Oui photo obligatoire'
  }),

  room_min: options_from_hashmap({
    '1': 'Studio ou +',
    '2': '2 pièces min.',
    '3': '3 pièces min.',
    '4': '4 pièces min.',
    '5': '5 pièces min.'
  }),
  furnished: options_from_hashmap({
    '0': 'Non',
    '1': 'Oui'
  }),
  bedroom_min: options_from_hashmap({
    '1': '1',
    '2': '2',
    '3': '3',
    '4': '4'
  }),

  buy_type: options_from_hashmap({
    old: 'Ancien',
    new: 'Neuf'
  })
}

export const FormWhereField = React.memo(
  ({ showRequiredError, value, agglomeration, validators, onChangeByNameValue }) => {
    const [{ db }] = useImmerStore()
    const config = React.useMemo(() => {
      if (db.config && db.config.agglomerations) {
        const config_agglo = db.config.agglomerations.find(x => x.value === agglomeration)

        if (config_agglo) {
          return {
            placeholderInput: config_agglo.placeholderInput
          }
        }
      }

      return {
        placeholderInput: ''
      }
    }, [agglomeration, db.config])

    return (
      <Box className="field">
        <FormField
          value={value}
          onChangeValue={value => onChangeByNameValue('where', value)}
          showRequiredError={showRequiredError}
          validators={validators}
          isMulti
          label="Votre zone de recherche"
          placeholder={config.placeholderInput}
          agglomeration={agglomeration}
        >
          {FormInputLocalisation}
        </FormField>
      </Box>
    )
  }
)

export const FormTextMemoField = React.memo(
  ({ name, showRequiredError, type, value, validators, label, placeholder }) => {
    return (
      <Box className="field">
        <FocusableField
          type={type}
          showRequiredError={showRequiredError}
          name={name}
          value={value}
          validators={validators}
          label={label}
          placeholder={placeholder}
        >
          {FormTextFieldInput}
        </FocusableField>
      </Box>
    )
  }
)

export const FormPriceMemoField = React.memo(
  ({ name, showRequiredError, value, validators, label, placeholder, onChangeByNameValue }) => {
    const numberMask = createNumberMask({
      prefix: '',
      suffix: '',
      thousandsSeparatorSymbol: ' '
    })
    return (
      <Box className="field">
        <FocusableField
          type="text"
          showRequiredError={showRequiredError}
          name={name}
          value={value}
          validators={validators}
          label={label}
          placeholder={placeholder}
          mask={numberMask}
          onChange={e => {
            e.stopPropagation()
            onChangeByNameValue(name, e.target.value.replace(/\s/g, ''))
          }}
        >
          {FormMaskedInput}
        </FocusableField>
      </Box>
    )
  }
)

export const FormSelectMemoField = React.memo(
  ({
    name,
    value,
    label,
    options,
    noValueLabel,
    hasPlaceholderStyleOnNoValue,
    disableNoValueOption,
    validators,
    showRequiredError,
    gridColumn
  }) => {
    return (
      <Box className="field" gridColumn={gridColumn}>
        <FormField
          name={name}
          value={value}
          label={label}
          options={options}
          noValueLabel={noValueLabel}
          hasPlaceholderStyleOnNoValue={hasPlaceholderStyleOnNoValue}
          validators={validators}
          showRequiredError={showRequiredError}
          disableNoValueOption={disableNoValueOption}
        >
          {FormSelectNativeInput}
        </FormField>
      </Box>
    )
  }
)

export const FormSelectMemoFieldOptional = ({ form, name, label }) => {
  return (
    <FormSelectMemoField
      showRequiredError={form.submittedOnce}
      name={name}
      value={form.values[name]}
      validators={form.validators[name]}
      label={label}
      noValueLabel="Peu importe"
      hasPlaceholderStyleOnNoValue={false}
      options={form_options[name]}
    />
  )
}

export const FormCheckboxMemo = React.memo(({ form, name, label }) => {
  const { onChangeByNameValue } = form
  const onChange = React.useCallback(
    ({ target: { value } }) => {
      if (value) onChangeByNameValue(name, '1')
      else onChangeByNameValue(name, '')
    },
    [onChangeByNameValue, name]
  )
  return (
    <FormField label={label} name={name} value={form.values[name]} onChange={onChange}>
      {FormCheckboxInput}
    </FormField>
  )
})
