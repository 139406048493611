import React from 'react'
import styled from 'styled-components'
import Helmet from 'react-helmet'
import { Box, Text } from '../Shared/Base'

const LandingStyled = styled.main`
  color: var(--colorN_primary);

  .h2-like {
    font-size: 3.6rem;
    line-height: 4.6rem;

    @media screen and (max-width: 1439px) {
      font-size: 3.2rem;
      line-height: 4.2rem;
    }
  }
`

const LandingHomepage = () => {
  return (
    <LandingStyled>
      <Helmet>
        <title>Jinka</title>
        <meta property="og:url" content="https://www.jinka.fr/unsubscribed" />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/loueragile/image/upload/v1618990667/web/jinka/Image_OG.jpg"
        />
        <link rel="canonical" href="https://www.jinka.fr/unsubscribed" />
      </Helmet>

      <Box
        as="section"
        maxWidth={1200}
        mx="auto"
        px={{ lg: 0, xs: 20 }}
        py={{ ml: 120, xs: 80 }}
        textAlign="center"
      >
        <Text className="fz-24" color="n_secondary" mb={20}>
          Arrêt des e-mails automatiques
        </Text>
        <Text fontSize={18} mb={20}>
          Les emails automatiques ont été stoppés.
        </Text>
        <Text fontSize={18} mb={20}>
          <strong>Attention</strong> : ceci ne désactive pas l'envoi d'alertes par e-mail !
        </Text>
        <Text maxWidth={500} mx="auto" className="fz-16">
          Pour cela, veuillez ajuster vos paramètres de réception dans l'application Jinka (compte /
          paramètre / Réception et fréquence) ou utiliser le boutton "Désactiver l'alerte" présent
          dans les e-mails d'alerte.
        </Text>
      </Box>
    </LandingStyled>
  )
}

export default LandingHomepage
