import styled, { css } from 'styled-components'
import React from 'react'
import {
  alignItems,
  alignSelf,
  backgroundColor,
  borders,
  borderStyle,
  borderWidth,
  bottom,
  boxShadow,
  color,
  display,
  flex,
  flexDirection,
  flexWrap,
  fontSize,
  fontWeight,
  gridColumn,
  gridColumnGap,
  gridGap,
  gridRow,
  gridRowGap,
  gridTemplateAreas,
  gridTemplateColumns,
  gridTemplateRows,
  gridAutoColumns,
  height,
  justifyContent,
  justifySelf,
  left,
  lineHeight,
  maxHeight,
  maxWidth,
  minHeight,
  minWidth,
  order,
  overflow,
  position,
  right,
  space,
  textAlign,
  top,
  width,
  zIndex
} from 'styled-system'

export const BoxHelpers = css`
  ${alignItems};
  ${alignSelf};
  ${backgroundColor};
  ${borders};
  ${borderStyle};
  ${borderWidth};
  ${bottom};
  ${boxShadow};
  ${color};
  ${display};
  ${flex};
  ${flexDirection};
  ${flexWrap};
  ${fontSize};
  ${fontWeight};
  ${gridColumn};
  ${gridColumnGap};
  ${gridGap};
  ${gridRow};
  ${gridRowGap};
  ${gridTemplateAreas};
  ${gridTemplateColumns};
  ${gridTemplateRows};
  ${gridAutoColumns};
  ${height};
  ${justifyContent};
  ${justifySelf};
  ${left};
  ${lineHeight};
  ${maxHeight};
  ${maxWidth};
  ${minHeight};
  ${minWidth};
  ${order};
  ${overflow};
  ${position};
  ${right};
  ${space};
  ${textAlign};
  ${top};
  ${width};
  ${zIndex};
`

export const Box = styled.div`
  ${BoxHelpers}
`

export const BoxSvg = styled(({ height, width, mr, ...props }) => <svg {...props} />)`
  ${BoxHelpers}
`

export const Link = styled.a`
  ${color};
  ${space};
`

export const Text = styled.p`
  ${({ variant, theme }) =>
    variant &&
    variant === 'error' &&
    `
      color: ${theme.palette.error.main};
    `};

  ${({ variant, theme }) =>
    variant &&
    variant === 'title' &&
    `
      color: ${theme.typography.title.color};
      font-weight: ${theme.typography.title.fontWeight};
      font-size: ${theme.typography.title.fontSize};
    `};

  ${({ variant, theme }) =>
    variant &&
    variant === 'body' &&
    `
      color: var(--colorText);
      line-height: 2.4rem;
    `};

  ${color};
  ${display};
  ${alignItems};
  ${fontSize};
  ${fontWeight};
  ${lineHeight};
  ${maxWidth};
  ${maxWidth};
  ${space};
  ${textAlign};
  ${width};
`
