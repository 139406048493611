import { useFetchApi, useCheckAuthentication } from './views/Shared/hooks'
import { useImmerStore } from './store'
import React from 'react'
import { withRouter } from 'react-router-dom'
import smoothscroll from 'smoothscroll-polyfill'
import { set_la_api_token } from './helpers/cookies'
import { qps_from_search } from './helpers/misc'
import Querystring from 'querystring'

export const AppBootstrapWrapper = withRouter(({ children, history }) => {
  const location_search = window.location.search
  const location_pathname = window.location.pathname

  const [ready_at, set_ready_at] = React.useState(false)

  React.useEffect(() => {
    const { access_token, ...other_qps } = qps_from_search(location_search)
    if (access_token) {
      set_la_api_token(access_token)
      history.replace({
        pathname: location_pathname,
        search: Querystring.stringify(other_qps)
      })
    }
    set_ready_at(true)
  }, [history, location_pathname, location_search])

  return ready_at ? children : null
})

const AppBootstrap = () => {
  const fetcher = useFetchApi()
  const [, set_state] = useImmerStore()
  const check_authentication = useCheckAuthentication()

  React.useEffect(() => {
    const async_init = async () => {
      const config = await fetcher('config')
      set_state(draft => {
        draft.db.config = config
      })

      await check_authentication()
    }

    if (navigator.userAgent !== 'ReactSnap') {
      smoothscroll.polyfill()
      async_init()
    }
  }, [set_state, fetcher, check_authentication])

  return null
}

export default AppBootstrap
