import React from 'react'
import { useHistory } from 'react-router-dom'
import routes from '../../routes'
import { remove_la_api_token } from '../../helpers/cookies'
import LoadingBar from '../Shared/LoadingBar'

import { useImmerStore } from '../../store'

const SignLogout = () => {
  const history = useHistory()
  const [, set_state] = useImmerStore()

  React.useEffect(() => {
    remove_la_api_token()

    // XXX sometimes cookie stay ?
    setTimeout(() => {
      set_state(draft => {
        draft.db.me = null
        draft.db.me_authenticated = false
      })
      history.replace(routes.homepage.route.reverse())
    }, 100)
  }, [history, set_state])

  return <LoadingBar />
}

export default SignLogout
