import { Dialog, DialogContent, useMediaQuery, useTheme } from '@material-ui/core'
import React from 'react'

import { Box } from '../Shared/Base'
import { SvgIconClose } from './Svg/Alert'

const ModalSimple = ({ onClose, maxSize = 'sm', children, has_icon_close = true }) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down(maxSize))

  return (
    <Dialog maxWidth={maxSize} onClose={onClose} open fullScreen={fullScreen} fullWidth>
      {has_icon_close && (
        <Box onClick={onClose}>
          <SvgIconClose />
        </Box>
      )}
      <DialogContent style={{ padding: '30px' }}>{children}</DialogContent>
    </Dialog>
  )
}

export default ModalSimple
