import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { Provider } from 'react-redux'
import * as serviceWorker from './serviceWorker'
import configureStore, { ImmerStoreProvider } from './store'
import Router from './Router'
import * as Sentry from '@sentry/browser'

const { NODE_ENV, REACT_APP_SENTRY_ENABLED } = process.env

if (REACT_APP_SENTRY_ENABLED === '1') {
  Sentry.init({
    dsn: 'https://6e73e862f85f4f08af7b9a3ba7c87a3e@o228614.ingest.sentry.io/5195049',
    environment: NODE_ENV,
    whitelistUrls: [
      'local.loueragile.fr',
      'www.loueragile.fr',
      'www.googletagmanager.com',
      'www.jinka.fr'
    ]
  })
}

const store = configureStore()

const render = Component => {
  const rootElement = document.getElementById('root')
  if (rootElement.hasChildNodes()) {
    ReactDOM.hydrate(
      <Provider store={store}>
        <ImmerStoreProvider>
          <Router>
            <Component />
          </Router>
        </ImmerStoreProvider>
      </Provider>,
      rootElement
    )
  } else {
    ReactDOM.render(
      <Provider store={store}>
        <ImmerStoreProvider>
          <Router>
            <Component />
          </Router>
        </ImmerStoreProvider>
      </Provider>,
      rootElement
    )
  }
}

render(App)

if (module.hot && NODE_ENV === 'development') {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default
    render(NextApp)
  })
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
