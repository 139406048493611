import { createGlobalStyle } from 'styled-components'
import Helpers from './Helpers'
import Buttons from './Buttons'
import Form from './Form'
import Misc from './Misc'

const Style = createGlobalStyle`
	${Helpers}
	${Buttons}
	${Form}
	${Misc}

	*,
	*::before,
	*::after {
		box-sizing: border-box;
	}

	html {
		font-size: 62.5%;
		font-size: calc(1em * .625);
		font-family: sans-serif;
		text-size-adjust: 100%;
		-webkit-tap-highlight-color: transparent;
	}

	article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
		display: block;
	}

	body {
		margin: 0;
		font-family: "Lato", "Helvetica", "Arial", sans-serif;
		font-display: optional;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		background-color: white;
		font-size: 1.6rem;
		color: var(--colorText);
	}

	body {
	 	background-color: #FBFDFF;
	}


	[tabindex="-1"]:focus {
		outline: none !important;
	}

	hr {
		box-sizing: content-box;
		height: 0;
		overflow: visible;
	}

	h1, h2, h3, h4, h5, h6 {
		margin: 0;
	}

	p {
		margin: 0;
	}

	abbr[title],
	abbr[data-original-title] {
		text-decoration: underline;
		text-decoration: underline dotted;
		border-bottom: 0;
		cursor: help;
	}

	address {
		margin-bottom: 1rem;
		font-style: normal;
		line-height: inherit;
	}

	ol,
	ul,
	dl {
		padding: 0;
		margin: 0;
		list-style: none;
	}

	ol ol,
	ul ul,
	ol ul,
	ul ol {
		margin: 0;
	}

	dt {
		font-weight: 700;
	}

	dd {
		margin: 0;
	}

	blockquote {
		margin: 0;
	}

	dfn {
		font-style: italic;
	}

	b,
	strong {
		font-weight: bold;
	}

	small {
		font-size: 80%;
	}

	sub,
	sup {
		position: relative;
		font-size: 75%;
		line-height: 0;
		vertical-align: baseline;
	}

	sub {
		bottom: -.25em;
	}

	sup {
		top: -.5em;
	}

	a {
		text-decoration: none;
		background-color: transparent;
		-webkit-text-decoration-skip: objects;
	}

	a:hover {
		// text-decoration: underline;
	}

	a:not([href]):not([tabindex]) {
		color: inherit;
		text-decoration: none;
	}

	a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
		color: inherit;
		text-decoration: none;
	}

	a:not([href]):not([tabindex]):focus {
		outline: 0;
	}

	pre,
	code,
	kbd,
	samp {
		font-family: monospace, monospace;
		font-size: 1em;
	}

	pre {
		margin-top: 0;
		margin-bottom: 1rem;
		overflow: auto;
		-ms-overflow-style: scrollbar;
	}

	figure {
		margin: 0;
	}

	img,
	table,
	td,
	blockquote,
	code,
	pre,
	textarea,
	input,
	video,
	svg {
		max-width: 100%;
	}

	img {
		height: auto;
		vertical-align: middle;
		border-style: none;
	}

	svg:not(:root) {
		overflow: hidden;
	}

	a,
	area,
	button,
	[role="button"],
	input:not([type="range"]),
	label,
	select,
	summary,
	textarea {
		touch-action: manipulation;
	}

	table {
		border-collapse: collapse;
	}

	caption {
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;
		color: #868e96;
		text-align: left;
		caption-side: bottom;
	}

	th {
		text-align: inherit;
	}

	label {
		display: inline-block;
		margin-bottom: 1rem;
	}

	button {
		border: 0;
		border-radius: 0;
		cursor: pointer;
		background-color: transparent;
	}

	button:focus {
		// outline: 1px dotted;
		// outline: 5px auto -webkit-focus-ring-color;
		outline: none;
	}

	input,
	button,
	select,
	optgroup,
	textarea {
		margin: 0;
		padding: 0;
		font-family: inherit;
		font-size: inherit;
		line-height: inherit;
	}

	button,
	input {
		overflow: visible;
		-webkit-appearance: none;
	}

	button {
		text-transform: none;
		-webkit-appearance: button;
	}

	select {
		text-transform: none;
		-webkit-appearance: none;
	}

	button,
	html [type="button"],
	[type="reset"],
	[type="submit"] {
		// -webkit-appearance: button;
	}

	button::-moz-focus-inner,
	[type="button"]::-moz-focus-inner,
	[type="reset"]::-moz-focus-inner,
	[type="submit"]::-moz-focus-inner {
		padding: 0;
		border-style: none;
	}

	input[type="radio"],
	input[type="checkbox"] {
		box-sizing: border-box;
		padding: 0;
	}

	input[type="date"],
	input[type="time"],
	input[type="datetime-local"],
	input[type="month"] {
		-webkit-appearance: listbox;
	}

	textarea {
		overflow: auto;
		resize: vertical;
		resize: none;
    line-height: 2.2rem;
	}

	fieldset {
		min-width: 0;
		padding: 0;
		margin: 0;
		border: 0;
	}

	legend {
		display: block;
		width: 100%;
		max-width: 100%;
		padding: 0;
		margin-bottom: .5rem;
		font-size: 1.5rem;
		line-height: inherit;
		color: inherit;
		white-space: normal;
	}

	progress {
		vertical-align: baseline;
	}

	[type="number"]::-webkit-inner-spin-button,
	[type="number"]::-webkit-outer-spin-button {
		height: auto;
		-webkit-appearance: none;
	}

	[type="search"] {
		outline-offset: -2px;
		-webkit-appearance: none;
	}

	[type="search"]::-webkit-search-cancel-button,
	[type="search"]::-webkit-search-decoration {
		-webkit-appearance: none;
	}

	::-webkit-file-upload-button {
		font: inherit;
		-webkit-appearance: button;
	}

	output {
		display: inline-block;
	}

	summary {
		display: list-item;
	}

	template {
		display: none;
	}

	[hidden] {
		display: none !important;
	}

	.la_webview_only {
		display: none !important;
	}
`

export default Style
