import { css } from 'styled-components'

const Helpers = css`
  /* -------------------------------------------------- */
  /* .variables
  /* -------------------------------------------------- */

  :root {
    ${({ theme }) =>
      Object.keys(theme.colors)
        .map(k => `--color${k[0].toUpperCase()}${k.slice(1)}: ${theme.colors[k]};`)
        .join('\n')};
  }

  hr {
    border: 0;
    border-bottom: 1px solid var(--colorThin);
    clear: both;
    height: 1px;
    margin: 40px auto;
    position: relative;
    text-align: center;

    &.w-space {
      margin: 60px auto;
    }

    &.w-tiny {
      margin: 30px auto;
    }
  }

  .br-mobile {
    @media screen and (max-width: 1023px) {
      br {
        display: none;
      }
    }
  }

  /* -------------------------------------------------- */
  /* .iframe
  /* -------------------------------------------------- */

  .resp-container {
    position: relative;
    overflow: hidden;
    padding-top: 70%;
  }

  .resp-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }

  /* -------------------------------------------------- */
  /* .list
  /* -------------------------------------------------- */

  .list {
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-align: left;

    li {
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAXCAYAAAAYyi9XAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFDSURBVHgBvZXtcYMwDIaF6QDZoIzQbJBO0GaCkgkwE4ROYGCBjNBukG7QbFBGYALoK1fc+bg0v6S8d8b47ONBsj6I7qyMlNR13Q/P0zRt67oe/zvnSFeFc+7j1gE1ICx7xjRg7Pq+P5E1EG4cBDrO81zCxY0pMIHuZXkMIXhToEC/AK3jx50LsPQt3VeL0rUAOmJqMEZ2NX7kYgpktW0bsixjtw4CHUyBLFh6xrQT6Fb9DteSIGJ3co6eo4XIG49QfiE7FTLogR+A8QVv6A6KQPZtnuePZCQYxGkSPWgeNLiuEsCl1JWmQFSaJwTKtyybqqrezaIUsLRzRBi/mFgoMM6/Au789N4v9VW/lgK2WWAYFwAP6b46ENF+EljsHOvurwpEGWth0SsltXN9Rg0oDbeiv+6wvwZTBQqMi8hhaUXX9AuAy5qpa9C9LQAAAABJRU5ErkJggg==');
      background-size: 13px 10px;
      background-repeat: no-repeat;
      background-position: left top 7px;
      padding-left: 30px;

      + li {
        margin-top: 10px;
      }

      &.alone {
        background-image: none !important;
      }
    }

    &.is-tiny li + li {
      margin-top: 5px;
    }
  }

  .list.check {
    li {
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAWCAYAAADeiIy1AAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFrSURBVHgBtZRNTsMwEEZnTFW2PUKPQG9AbkCWKGLBAhQQlHACwglAqKKRWMCmYVluwBHoDcgRsoSo9WCnIuTXdkr6bWKNojx7XsYIW8pleOoh4A0Axkta2QhbyBrC7rICUdw5yHt1h5zoQ5xkkAcx6DDeszfgBO8FiAgHvO8UxPtfvngMC0XCxcQJbnvQUaQX8bgqlaMkIVsuOnFU60WEr9CeHE3f5PrfrWv0QuD/QjKQ2NH+RXh2ABtE5SVfYuOZ68sdMaD5OHQ/XdEGMEzqBZu9FECAlH9x2BdQE5j0sp78YoSX6+A4iKqgarQwUy8F0JK4JZoat4GZeimAHp2nRRtYGy/5ZHN0PjvZ6yGrtCP9CIIVHAaRybxoQToYE1c9x505lFqWelG0rBakhsnWlmrCy4MzHYFBKn9ds7Oalmq8KEFq2F+a5qUVSAdTzUtTtLd3xVkLL/lob295MvaNIyJ4kSdhya4FG+QHjQXnpayPJ4wAAAAASUVORK5CYII=');
      background-size: 13px 10px;
      background-repeat: no-repeat;
      background-position: left top 7px;
      padding-left: 30px;
    }

    &.is-medium li {
      font-size: 2rem;
      line-height: 2.6rem;
      background-position: left top 9px;
    }

    &.is-white li {
      color: white;
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAMCAYAAAC5tzfZAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABqSURBVHgBlZELDYAwDETBARIqBSfgAAtIwAHSJgEcHFuArdn3dsnSZM1r89Jx6AiA2Za7B1jx5mIBsc980MlCP+DqxAA7QkRPMf4j7+GylVYL5RE1Pdj0yIBH4lEQ1mDqQYLcPRS4oHKPBwH83uL2Ol/dAAAAAElFTkSuQmCC');
    }
  }

  .list.bullet {
    li {
      position: relative;
      background-image: none;
      padding-left: 22px;

      + li {
        margin-top: 5px;
      }

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 10px;
        background-color: var(--colorThin);
        width: 6px;
        height: 6px;
        border-radius: 100%;
      }
    }
  }

  /* -------------------------------------------------- */
  /* .misc
  /* -------------------------------------------------- */

  main {
    -webkit-overflow-scrolling: touch;
  }

  .img-rwd {
    display: inline-block;
    height: 100%;
    width: 100%;
  }

  .debug {
    border: 1px solid red;
  }

  .fls,
  svg {
    flex-shrink: 0;
  }

  .tdu {
    text-decoration: underline;
  }

  .tdh {
    text-decoration: underline;
  }

  .dib {
    display: inline-block;
  }

  .csp {
    cursor: pointer;
  }

  .toe {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .wsn {
    white-space: nowrap;
  }

  .la_webview_only {
    display: none !important;
  }

  /* -------------------------------------------------- */
  /* .fz-xx
  /* -------------------------------------------------- */

  .fz-36 {
    font-size: 3.6rem;
    line-height: 4.6rem;
  }

  .fz-30 {
    font-size: 3rem;
    line-height: 4rem;
  }

  .fz-24 {
    font-size: 2.4rem;
    line-height: 3.2rem;
  }

  .fz-20 {
    font-size: 2rem;
    line-height: 2.8rem;
  }

  .fz-18 {
    font-size: 1.8rem;
    line-height: 2.6rem;
  }

  .fz-16 {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }

  .fz-14 {
    font-size: 1.4rem;
    line-height: 2.2rem;
  }

  /* -------------------------------------------------- */
  /* .title
  /* -------------------------------------------------- */

  .h2-like {
    font-size: 2.4rem;
    line-height: 3.2rem;
    font-family: montserrat;
  }

  .h3-like {
    font-size: 1.8rem;
    line-height: 2.6rem;
  }

  /* -------------------------------------------------- */
  /* .pac-container
  /* -------------------------------------------------- */

  .pac-container {
    box-shadow: none;
    border: 1px solid var(--colorThin);
    margin-top: -1px;
  }

  /* -------------------------------------------------- */
  /* .MISC
  /* -------------------------------------------------- */

  ._alert_result,
  ._alert_dashboard,
  ._asrenter {
    background-color: white;
  }

  [class^='_asrenter'] {
    background-color: white;
  }

  .logoPartner {
    border: 1px solid #dddcdc;
  }

  .wrapper-dash {
    display: grid
    grid-template-columns: auto 1fr;

    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }
`

export default Helpers
