import QueryString from 'querystring'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { get_la_api_token, set_la_api_token } from '../../helpers/cookies'
import { analyticsIdentify, qps_from_search } from '../../helpers/misc'
import { useImmerStore } from '../../store'
import routes from '../../routes'

export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])

  return debouncedValue
}

export function useScrollOnRef(offsetTop = 0) {
  const ref = useRef(null)

  useEffect(() => {
    window.scrollTo(0, ref.current.offsetTop + offsetTop)
  }, [offsetTop])

  return ref
}

export function useGoogleMapLib(onLoad) {
  useEffect(() => {
    if (!window.google) {
      const script = document.createElement(`script`)
      script.type = 'text/javascript'
      script.src =
        'https://maps.googleapis.com/maps/api/js?key=AIzaSyDpOIx5jgdpfdYSHjK--w7T6PWPw7b5hAA&libraries=places&v=3&language=fr'
      const headScript = document.getElementsByTagName(`script`)[0]
      headScript.parentNode.insertBefore(script, headScript)
      script.addEventListener('load', onLoad)
      return () => script.removeEventListener('load', onLoad)
    } else onLoad()
  }, [onLoad])
}

export function usePrevious(value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export function useMediaInfos(file_tokens) {
  const [media_infos, set_media_infos] = useState(null)
  const fetcher = useFetchApi()

  useEffect(() => {
    const init = async () => {
      const tokens = Object.values(file_tokens).reduce((acc, e) => acc.concat(e), [])

      const api_media_infos = await fetcher('media/infos', {
        method: 'POST',
        body: JSON.stringify({
          tokens
        })
      })

      set_media_infos(api_media_infos)
    }

    init()
  }, [fetcher, file_tokens])

  return media_infos
}

export class FetchError extends Error {
  constructor({ message, status }) {
    super(`(${status}) ${message}`)
    this.status = status
    this.name = 'FetchError'
    this.errorMessage = message
  }
}
export const useFetchApi = () => {
  return useCallback(async (url, options) => {
    const headers = (options && options.headers) || { 'Content-Type': 'application/json' }
    const access_token = get_la_api_token()
    if (access_token && !headers.Authorization) {
      headers.Authorization = `Bearer ${access_token}`
    }

    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/${url}`, {
      ...options,
      headers
    })

    const { ok, status } = response

    if (status === 401) {
      if (document && document.location) {
        // force logout to clean Cookies & state
        document.location.href = '/logout'
      }
    } else {
      if (status === 204) return 'no content'

      if (ok) return response.json()

      let message = 'no json message'
      try {
        const json = await response.json()
        message = json.message
      } catch (err) {}

      throw new FetchError({ status, message })
    }
  }, [])
}

export const useFetch_my_alerts = () => {
  const fetcher = useFetchApi()
  const [, set_state] = useImmerStore()

  return useEffect(() => {
    const init = async () => {
      const alerts = await fetcher('alert')
      set_state(draft => {
        alerts.forEach(alert => {
          draft.db.alert[alert.id] = alert
        })
        draft.ui.alert_list = { ids: alerts.map(a => a.id) }
      })
    }
    init()
  }, [fetcher, set_state])
}

export const useCloseMobileMenuLeftCallback = () => {
  const [, set_state] = useImmerStore()

  return useCallback(() => {
    set_state(draft => {
      draft.ui.left_mobile_menu_open = false
    })
  }, [set_state])
}

export const useOpenMobileMenuLeftCallback = () => {
  const [, set_state] = useImmerStore()

  return useCallback(() => {
    set_state(draft => {
      draft.ui.left_mobile_menu_open = true
    })
  }, [set_state])
}

export const useLA_fetch_profile = () => {
  const fetcher = useFetchApi()
  const [, set_state] = useImmerStore()

  const fetch = React.useCallback(async () => {
    const res = await fetcher('user/info_partner')

    set_state(draft => {
      draft.db.me_profile = res.result
    })

    return res
  }, [fetcher, set_state])

  return fetch
}

export const useCheckAuthentication = () => {
  const fetcher = useFetchApi()
  const [, set_state] = useImmerStore()

  return useCallback(async () => {
    if (get_la_api_token()) {
      const me = await fetcher('user/authenticated')

      if (me.result) {
        // refresh token
        if (me.access_token) {
          set_la_api_token(me.access_token)
        }

        if (me.email) {
          analyticsIdentify({ email: me.email, id: me.id })
        }

        await set_state(draft => {
          draft.db.me = me
          draft.db.me_authenticated = true
        })

        return me
      }
    }

    await set_state(draft => {
      draft.db.me = null
      draft.db.me_authenticated = false
    })

    return null
  }, [fetcher, set_state])
}

export const useSignRedirect = () => {
  const history = useHistory()
  const location = useLocation()
  const location_search = location.search
  const redirect_to = useMemo(() => {
    const qps = qps_from_search(location_search)
    const { r_pathname = '/user', r_search } = qps
    return {
      r_pathname: r_pathname === '/' ? '/user' : r_pathname,
      r_search
    }
  }, [location_search])

  return useCallback(() => {
    history.push({
      pathname: redirect_to.r_pathname,
      search: redirect_to.r_search
    })
  }, [history, redirect_to.r_pathname, redirect_to.r_search])
}

export const useGooglePlayLink = () => {
  const location = useLocation()
  const location_search = (location && location.search) || ''
  const url_base = 'https://play.google.com/store/apps/details'
  const qps_search = qps_from_search(location_search)
  const utm_qps = { utm_source: 'jinka_fr' }
  const qps = { id: 'com.babelfrance.loueragile' }

  Object.keys(qps_search).forEach(k => {
    if (/^utm_/.test(k)) {
      utm_qps[k] = qps_search[k]
    }
  })

  qps.referrer = QueryString.stringify(utm_qps)

  return `${url_base}?${QueryString.stringify(qps)}`
}

export const useAppStoreLink = () => {
  const location = useLocation()
  const location_search = (location && location.search) || ''
  const qps_search = qps_from_search(location_search)

  if (qps_search['utm_campaign']) {
    return `https://apps.apple.com/app/apple-store/id1519258255?${QueryString.stringify({
      pt: '121695215',
      mt: '8',
      ct: qps_search['utm_campaign']
    })}`
  }

  return 'https://apps.apple.com/fr/app/jinka/id1519258255'
}

// Hook
export const useScript = src => {
  // Keep track of script status ("idle", "loading", "ready", "error")
  const [status, setStatus] = useState(src ? 'loading' : 'idle')

  useEffect(
    () => {
      // Allow falsy src value if waiting on other data needed for
      // constructing the script URL passed to this hook.
      if (!src) {
        setStatus('idle')
        return
      }

      // Fetch existing script element by src
      // It may have been added by another intance of this hook
      let script = document.querySelector(`script[src="${src}"]`)

      if (!script) {
        // Create script
        script = document.createElement('script')
        script.src = src
        script.async = true
        script.setAttribute('data-status', 'loading')
        // Add script to document body
        document.body.appendChild(script)

        // Store status in attribute on script
        // This can be read by other instances of this hook
        const setAttributeFromEvent = event => {
          script.setAttribute('data-status', event.type === 'load' ? 'ready' : 'error')
        }

        script.addEventListener('load', setAttributeFromEvent)
        script.addEventListener('error', setAttributeFromEvent)
      } else {
        // Grab existing script status from attribute and set to state.
        setStatus(script.getAttribute('data-status'))
      }

      // Script event handler to update status in state
      // Note: Even if the script already exists we still need to add
      // event handlers to update the state for *this* hook instance.
      const setStateFromEvent = event => {
        setStatus(event.type === 'load' ? 'ready' : 'error')
      }

      // Add event listeners
      script.addEventListener('load', setStateFromEvent)
      script.addEventListener('error', setStateFromEvent)

      // Remove event listeners on cleanup
      return () => {
        if (script) {
          script.removeEventListener('load', setStateFromEvent)
          script.removeEventListener('error', setStateFromEvent)
        }
      }
    },
    [src] // Only re-run effect if script src changes
  )

  return status
}

export const useComponentDidMount = fn => {
  const ref = React.useRef(fn)
  React.useEffect(() => {
    ref.current()
  }, [])
}

export const useLA_dashboard_pathToPage = alert_id => {
  const location = useLocation()
  const qps = qps_from_search(location.search)
  const filter = qps.filter || 'all'
  const sorting = qps.sorting
  const page = parseInt(qps.page || 1, 10)

  return (options = {}) => ({
    pathname: routes.alert_dashboard.route.reverse({ id: alert_id }),
    search: QueryString.stringify({
      filter,
      page,
      sorting,
      ...options
    })
  })
}
