import React from 'react'
import LayoutWithLeftNav from '../Shared/Layout/WithLeftNav'
import LayoutNavLink from './NavLink'
import routes from '../../routes'

export const NavOwner = () => (
  <>
    <LayoutNavLink
      to={routes.asowner_home.route.reverse()}
      activeOnRoutes={[routes.asowner_home.route]}
    >
      Accueil
    </LayoutNavLink>
    <LayoutNavLink
      to={routes.asowner_ad_list.route.reverse()}
      activeOnRoutes={[
        routes.asowner_ad_list.route,
        routes.asowner_ad_add.route,
        routes.asowner_ad_add_home.route,
        routes.asowner_ad_detail.route
      ]}
    >
      Logements
    </LayoutNavLink>
    <LayoutNavLink
      to={routes.asowner_tenancy_list.route.reverse()}
      activeOnRoutes={[
        routes.asowner_tenancy_add.route,
        routes.asowner_tenancy_list.route,
        routes.asowner_collector_add.route,
        routes.asowner_collector_edit_kyc.route
      ]}
    >
      Locations
    </LayoutNavLink>
    <LayoutNavLink
      to={routes.asowner_invoices.route.reverse()}
      activeOnRoutes={[routes.asowner_invoices.route]}
    >
      Factures
    </LayoutNavLink>
    <LayoutNavLink
      to={routes.asowner_rents.route.reverse()}
      activeOnRoutes={[routes.asowner_rents.route]}
    >
      Loyers
    </LayoutNavLink>
    <LayoutNavLink
      to={routes.asowner_files.route.reverse()}
      activeOnRoutes={[routes.asowner_files.route]}
    >
      Documents
    </LayoutNavLink>
  </>
)

const LayoutOwner = ({ children }) => {
  return (
    <LayoutWithLeftNav
      navigation={
        <ul>
          <NavOwner />
        </ul>
      }
    >
      {children}
    </LayoutWithLeftNav>
  )
}

export default LayoutOwner
