import Cookies from 'universal-cookie'

const cookies = new Cookies()

export const remove_la_api_token = () => {
  cookies.remove('LA_API_TOKEN', { path: '/', secure: window.location.protocol === 'https:' })
}

export const set_la_api_token = access_token => {
  const expires_time = Date.now() + 30 * 24 * 60 * 60 * 1000
  cookies.set('LA_API_TOKEN', access_token, {
    path: '/',
    expires: new Date(expires_time),
    secure: window.location.protocol === 'https:'
  })
}

export const get_la_api_token = () => {
  return cookies.get('LA_API_TOKEN')
}

export default cookies
