import RegexRoute from 'route-parser'

const regex = pattern => new RegexRoute(pattern)

const routes = {
  redirect_logement: {
    route: regex('/valider'),
    public: true
  },
  sign_sudo: {
    route: regex('/sign/sudo'),
    header_footer: false,
    public: true
  },
  sign_download: {
    route: regex('/sign/download'),
    header_footer: false
  },
  sign: {
    route: regex('/sign/:action'),
    header_footer: false,
    public: true
  },
  asowner_tenancy_list: {
    route: regex('/asowner/tenancies'),
    menu: 'owner'
  },
  asowner_tenancy_add_home: {
    route: regex('/asowner/tenancy/add'),
    menu: 'owner'
  },
  asowner_tenancy_add: {
    route: regex('/asowner/tenancy/add/:step/:id'),
    menu: 'owner'
  },
  asowner_tenancy_edit_mandate: {
    route: regex('/asowner/tenancy/edit/mandate/:id'),
    menu: 'owner'
  },
  asowner_tenancy_edit_mandate_confirm: {
    route: regex('/asowner/tenancy/edit/mandate-confirm/:id'),
    menu: 'owner'
  },
  asowner_collector_add: {
    route: regex('/asowner/collector/add/:step/:id'), // id = draft_id
    menu: 'owner'
  },
  asowner_collector_edit_kyc: {
    route: regex('/asowner/collector/kyc/:id'),
    menu: 'owner'
  },
  asowner_home: {
    route: regex('/asowner'),
    menu: 'owner'
  },
  asrenter_tenancy_list: {
    route: regex('/asrenter/tenancies'),
    menu: 'renter'
  },
  asrenter_tenancy_edit_mandate: {
    route: regex('/asrenter/tenancy/edit/mandate/:id'),
    menu: 'renter'
  },
  asrenter_tenancy_edit_mandate_confirm: {
    route: regex('/asrenter/tenancy/edit/mandate-confirm/:id'),
    menu: 'renter'
  },
  asrenter_adpremium: {
    route: regex('/asrenter/adpremium/:id'),
    menu: 'renter'
  },
  asrenter_adpremium_apply: {
    route: regex('/asrenter/adpremium/:id/apply/:application_id/:step'),
    menu: 'renter'
  },
  asrenter_application_list: {
    route: regex('/asrenter/adpremium_applications'),
    menu: 'renter'
  },
  asrenter_global_application: {
    route: regex('/asrenter/global_application/:id/:step'),
    menu: 'renter'
  },
  asrenter_global_application_home: {
    route: regex('/asrenter/global_application_home'),
    menu: 'renter'
  },
  asrenter_mobilipass_dossier: {
    route: regex('/asrenter/mobilipass_dossier/:id'),
    public: true,
    layout_mw: 1200,
    header_footer: false
    // root_classname: '_homepage'
  },
  asrenter_mobilipass_chasseur_gendoc: {
    route: regex('/asrenter/mobilipass_chasseur_gendoc/:id'),
    public: true,
    layout_mw: 1200,
    header_footer: false
    // root_classname: '_homepage'
  },
  asrenter_jinkaloc_chasseur_end: {
    route: regex('/asrenter/jinkaloc_chasseur_end'),
    public: true,
    layout_mw: 1200,
    header_footer: false
    // root_classname: '_homepage'
  },
  asrenter_mobilipass_chasseur_end: {
    route: regex('/asrenter/mobilipass_chasseur_end/:id'),
    public: true,
    layout_mw: 1200,
    header_footer: false
    // root_classname: '_homepage'
  },
  asrenter_settings: {
    route: regex('/asrenter/settings'),
    menu: 'renter'
  },
  asrenter_profile: {
    route: regex('/asrenter/profile'),
    menu: 'renter'
  },
  asrenter_edit_email_confirm: {
    route: regex('/asrenter/edit_email_confirm'),
    menu: 'renter'
  },
  asrenter_user_email_mobile_confirm: {
    route: regex('/asrenter/email_mobile_confirm'),
    menu: 'renter'
  },
  asrenter_user_friend_mobile_confirm: {
    route: regex('/asrenter/friend_mobile_confirm'),

    public: true
  },
  asrenter_redirect_mobilipass_form_subscribe: {
    route: regex('/asrenter/redirect_mobilipass_form_subscribe'),
    public: true
  },
  asrenter_appredirect: {
    route: regex('/asrenter/app_redirect'),
    menu: 'renter',
    public: true
  },
  alert_list: { route: regex('/asrenter/alert/list'), menu: 'renter' },
  alert_create: {
    route: regex('/asrenter/alert/create/:step'),
    menu: 'renter',
    root_classname: '_alert_create'
  },
  alert_loading: { route: regex('/asrenter/alert/loading/:id'), root_classname: '_alert_loading' },
  alert_dashboard: {
    route: regex('/asrenter/alert/dashboard/:id'),
    layout_mw: 'calc(100% - 40px)',
    root_classname: '_alert_dashboard'
  },
  asrenter_partner_luko: {
    route: regex('/asrenter/partner_luko'),
    header_footer: false
  },
  asrenter_partner_generic: {
    route: regex('/asrenter/partner_generic'),
    layout_mw: 1200
  },
  asrenter_ad_contact: {
    route: regex('/asrenter/ad_contact'),
    public: true,
    layout_mw: 1200,
    hide_signin: true
  },
  asrenter_ad_contact_emerige: {
    route: regex('/asrenter/ad_contact_emerige'),
    public: true,
    layout_mw: 1200,
    hide_signin: true
  },
  asrenter_ad_contact_neuf: {
    route: regex('/asrenter/ad_contact_neuf'),
    public: true,
    layout_mw: 1200,
    hide_signin: true
  },
  alert_unsubscribe: {
    route: regex('/unsubscribe')
  },
  alert_result: {
    route: regex('/alert_result'),
    public: true,
    layout_mw: 1200,
    hide_signin: true
  },
  ad: {
    route: regex('/ad/:id'),
    public: true,
    layout_mw: 1200,
    hide_signin: true,
    root_classname: '_alert_result'
  },
  asowner_ad_list: {
    route: regex('/asowner/ads'),
    menu: 'owner'
  },
  asowner_ad_add_home: {
    route: regex('/asowner/ad/add'),
    menu: 'owner'
  },
  asowner_ad_add: {
    route: regex('/asowner/ad/add/:step/:id'),
    menu: 'owner'
  },
  asowner_ad_detail: {
    route: regex('/asowner/ad/:id'),
    menu: 'owner'
  },
  asowner_ad_lease_form: {
    route: regex('/asowner/ad_lease_form/:id'),
    menu: 'owner'
  },
  asowner_ad_lease_form_public_step: {
    route: regex('/asowner/ad_lease_form_public/:id/:step'),
    menu: 'owner'
  },
  asowner_invoices: {
    route: regex('/asowner/invoices'),
    menu: 'owner'
  },
  asowner_rents: {
    route: regex('/asowner/rents'),
    menu: 'owner'
  },
  asowner_files: {
    route: regex('/asowner/files'),
    menu: 'owner'
  },
  asowner_bkconfirm: {
    route: regex('/asowner/bkconfirm/:redirect_b64/:confirm_b64'),
    menu: 'owner'
  },
  homepage: {
    route: regex('/'),
    public: true,
    layout_mw: 1200,
    root_classname: '_homepage'
  },
  partner_agency: {
    route: regex('/partner/agency/:partner_source'),
    public: true,
    layout_mw: 1200,
    root_classname: '_neuf'
  },
  homepage_partner: {
    route: regex('/partner/:partner_source'),
    public: true,
    layout_mw: 1200,
    hide_signin: true,
    root_classname: '_homepage'
  },
  neuf: {
    route: regex('/achat-logement-neuf-idf'),
    public: true,
    layout_mw: 1200,
    root_classname: '_neuf'
  },
  agence: {
    route: regex('/pro'),
    public: true,
    layout_mw: 1200,
    root_classname: '_agence'
  },
  kit: {
    route: regex('/kit'),
    public: true,
    layout_mw: 1200
  },
  location: {
    route: regex('/chasseur-location'),
    public: true,
    layout_mw: 1200
  },
  rent: {
    route: regex('/rent2024'),
    public: true,
    layout_mw: 1200,
    root_classname: '_rent'
  },
  fnaim: {
    route: regex('/fnaim2023'),
    public: true,
    layout_mw: 1200,
    root_classname: '_rent'
  },
  stopmail: {
    route: regex('/unsubscribed'),
    public: true,
    layout_mw: 1200
  },
  nl_unsubscribe: {
    route: regex('/asrenter/nl_unsubscribe'),
    public: true,
    layout_mw: 1200,
    root_classname: '_nl_unsubscribe'
  },
  cgu: {
    route: regex('/cgu'),
    public: true,
    layout_mw: 1200
  },
  logout: {
    route: regex('/logout'),
    public: true
  },
  user_redirector: {
    route: regex('/user')
  },
  admin_user: {
    route: regex('/asrenter/admin/user'),
    menu: 'admin'
  },
  nps: {
    route: regex('/nps'),
    public: true,
    layout_mw: 1200,
    root_classname: '_rent'
  }
}

export default routes
