import { css } from 'styled-components'

const Form = css`
  /* -------------------------------------------------- */
  /* .form-
  /* -------------------------------------------------- */

  .form-input,
  .form-select {
    border: 1px solid var(--colorThin);
    padding: 16px 15px;
    border-radius: 5px;
    color: var(--colorText);
    background-color: white;
    width: 100%;
    font-size: 1.6rem;

    option:first-child {
      color: #cdc9c9;
    }

    ::placeholder,
    &.placeholder {
      color: #cdc9c9;
    }

    ::-ms-expand {
      display: none;
    }

    /* */

    &.placeholder option:not(:first-child) {
      color: var(--colorText);
    }

    /* */

    &.w-auto {
      width: auto;
    }

    /* */

    &:focus {
      border: 1px solid #aaaaaa;
      outline: none;
    }

    &:disabled {
      opacity: 0.7;
      background: var(--colorThin);
      cursor: inherit;
    }
  }

  .form-textarea {
    border: 1px solid var(--colorThin);
    padding: 16px 15px;
    border-radius: 5px;
    color: var(--colorText);
    /* background-color: #f7f7f7; */
    width: 100%;
    font-size: 1.6rem;

    ::placeholder,
    &.placeholder {
      color: #cdc9c9;
    }

    ::-ms-expand {
      display: none;
    }

    /* */

    &.placeholder option:not(:first-child) {
      color: var(--colorText);
    }

    /* */

    &.w-auto {
      width: auto;
    }

    /* */

    &:focus {
      border: 1px solid #aaaaaa;
      outline: none;
    }

    &:disabled {
      opacity: 0.7;
      background: var(--colorThin);
      cursor: inherit;
    }
  }

  /* -------------------------------------------------- */
  /* .form-select
  /* -------------------------------------------------- */

  .form-select {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAaCAMAAADCHv/YAAAAOVBMVEUAAADFxcXFxcXFxcXFxcXExMTExMTDw8PDw8PFxcXHx8fExMTFxcXExMTCwsLExMTGxsbDw8PExMTgEiVsAAAAEnRSTlMAIN9/z+8wEKBgX7+vcFCPb0BfZ1NNAAAAnklEQVQ4y43S2w4CIQwEUAq7sAp46f9/rMVoJmaFzjzxwGEIJQTpgU+XYCLqQYuk0UxVhXGF6vZuMVMYcaiOFjPbsIUXlgJDCRhZglJ/95SGE06Z3AStjqCNDFE/ArnPjcTJ7BIMJWBoAZP/ibT8Q3t2hGcyxNLcIHaIaS5quZ4Ea54QhOnfxcMHuA7qWNMgWIOn4yIRA+INBG3aVLwAyRAMhXF8WbsAAAAASUVORK5CYII=');
    background-size: 16.37px 8.56px;
    background-repeat: no-repeat;
    background-position: right 15px center;
    padding-right: 45px;
    cursor: pointer;

    &.is-tiny {
      padding-top: 10px;
      padding-bottom: 10px;
    }

    &.placeholder.no-placeholder {
      color: var(--colorText);
    }
  }

  /* -------------------------------------------------- */
  /* .form-label
  /* -------------------------------------------------- */

  .form-label {
    font-size: 1.6rem;
    line-height: 2.2rem;
    margin-bottom: 1rem;
  }

  /* -------------------------------------------------- */
  /* .form-checkbox
  /* -------------------------------------------------- */

  .form-checkbox {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    font-size: 1.6rem;
    line-height: 2.2rem;
    user-select: none;

    .checkmark {
      position: absolute;
      top: 1px;
      left: 0;
      height: 20px;
      width: 20px;
      background-color: white;
      border: 1px solid var(--colorThin);
      border-radius: 5px;
    }

    .checkmark:after {
      content: '';
      position: absolute;
      display: none;
    }

    .checkmark:after {
      left: 6px;
      top: 3px;
      width: 6px;
      height: 11px;
      border: solid var(--colorN_secondary);
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    input:checked ~ .checkmark:after {
      display: block;
    }

    &.is-secondary .checkmark:after {
      border: solid var(--colorSecondary);
    }

    &.is-medium .checkmark:after {
      left: 7px;
      top: 3px;
      width: 9px;
      height: 14px;
      border-width: 0 3px 3px 0;
    }

    &.is-medium .checkmark {
      height: 25px;
      width: 25px;
    }
  }

  /* -------------------------------------------------- */
  /* .form-radio
  /* -------------------------------------------------- */

  .form-radio {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    font-size: 1.6rem;
    line-height: 2.2rem;
    user-select: none;
    color: var(--colorN_primary);

    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      background-color: white;
      border: 1px solid var(--colorThin);
      border-radius: 100%;
    }

    .checkmark:after {
      content: '';
      position: absolute;
      display: none;
    }

    .checkmark:after {
      left: 5px;
      top: 5px;
      width: 8px;
      height: 8px;
      background-color: var(--colorN_secondary);
      border-radius: 100%;
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    input:checked ~ .checkmark:after {
      display: block;
    }

    &.is-light .checkmark:after {
      background-color: var(--colorLight);
    }
  }
`

export default Form
