import React from 'react'
import { Box, BoxSvg } from '../Base'

export const SvgIconArrowBack = () => (
  <BoxSvg
    width="11px"
    height="14x"
    viewBox="0 0 11 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fill="#5c5c5c" d="M10.979 1.231L1.636 7.69l-.89-.615L10.089.616l.89.615z" />
    <path fill="#5c5c5c" d="M10.089 13.616L.746 7.16l.89-.615 9.343 6.457-.89.615z" />
  </BoxSvg>
)

export const SvgIconHeart = ({ color }) => (
  <BoxSvg
    width="14px"
    height="13px"
    viewBox="0 0 14 13"
    fill={color || 'currentColor'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7.421 12.805a.552.552 0 01-.825.02l-.017-.02-5.545-6.272C-.041 5.368-.306 3.588.372 2.115 1.245.225 3.366-.541 5.11.405c.338.184.649.427.916.718L7 2.179l.974-1.056c1.38-1.495 3.614-1.495 4.992 0 .268.29.491.626.662.994.678 1.474.412 3.254-.662 4.419l-5.545 6.27z" />
  </BoxSvg>
)

export const SvgIconGarbage = () => (
  <Box
    as="svg"
    mr={{ sm: '15px', xs: 0 }}
    width="18"
    height="20"
    viewBox="0 0 18 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.136 6.318H2.864a.41.41 0 00-.41.41v11.454c0 .904.733 1.636 1.637 1.636h9.818c.904 0 1.637-.732 1.637-1.636V6.727a.41.41 0 00-.41-.409zM7.569 16.955a.614.614 0 01-1.227 0V9.59a.614.614 0 011.227 0v7.364zm4.091 0a.614.614 0 01-1.227 0V9.59a.614.614 0 011.227 0v7.364zm5.523-13.5h-3.886a.205.205 0 01-.205-.205V2.227c0-1.13-.915-2.045-2.045-2.045H6.955c-1.13 0-2.046.915-2.046 2.045V3.25a.205.205 0 01-.204.204H.818a.818.818 0 000 1.637h16.364a.819.819 0 000-1.637zM6.546 3.25V2.227a.41.41 0 01.409-.409h4.09a.41.41 0 01.41.41V3.25a.205.205 0 01-.205.204h-4.5a.205.205 0 01-.204-.204z"
      fill="#919191"
    />
  </Box>
)

export const SvgIconThumb = () => (
  <Box
    as="svg"
    width="76"
    height="76"
    viewBox="0 0 76 76"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    position="absolute"
    top="-30px"
    left="50%"
    marginLeft="-38px"
  >
    <circle cx="38" cy="38" r="37.5" fill="#fff" stroke="#DDDCDC" />
    <path
      stroke="#5A8FB6"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M22.375 37.173v18.813c0 .494.401.895.896.895h8.062M17 36.277l19.583-17.88a1.514 1.514 0 012.042 0l19.583 17.88"
    />
    <path
      stroke="#5A8FB6"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M35.813 26.423h3.583c.494 0 .896.401.896.896v3.583a.896.896 0 01-.896.896h-3.583a.896.896 0 01-.896-.896V27.32c0-.495.401-.896.896-.896z"
    />
    <path
      stroke="#F2C110"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M40.292 53.64c7.632 3.919 6.27 3.19 11.938 3.19 2.329 0 3.513-1.514 4.2-3.736v-.014l1.694-4.659v-.01a1.77 1.77 0 00-1.699-2.283h-4.353a1.79 1.79 0 01-1.407-.688 2.353 2.353 0 01-.31-1.792l.783-3.68a1.505 1.505 0 00-.288-1.337 1.53 1.53 0 00-2.424.081l-4.342 6.669a1.78 1.78 0 01-1.45.747h-2.35"
    />
    <path
      stroke="#F2C110"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M35.813 44.34h3.583c.494 0 .896.401.896.896v10.75a.896.896 0 01-.896.895h-3.583a.896.896 0 01-.896-.895v-10.75c0-.495.401-.896.896-.896z"
    />
  </Box>
)

export const SvgIconEuro = () => (
  <Box
    as="svg"
    width="76"
    height="76"
    viewBox="0 0 76 76"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    position="absolute"
    top="-30px"
    left="50%"
    marginLeft="-38px"
  >
    <circle cx="38" cy="38" r="37.5" fill="#fff" stroke="#DDDCDC" />
    <circle cx="38" cy="38" r="33.287" fill="#FFF4EA" />
    <path
      d="M39.434 48.588c2.924 0 5.378-1.04 7.361-3.123l2.38 2.343c-1.165 1.338-2.603 2.354-4.313 3.048-1.71.694-3.582 1.041-5.614 1.041-2.157 0-4.152-.396-5.986-1.19-1.835-.817-3.384-1.958-4.648-3.42-1.264-1.462-2.156-3.16-2.677-5.093H21.7V40.11h3.866a21.037 21.037 0 01-.074-1.524c0-.372.025-.88.075-1.524h-3.867V34.98h4.238c.52-1.933 1.413-3.63 2.677-5.093s2.813-2.59 4.648-3.384c1.834-.817 3.83-1.226 5.986-1.226 2.057 0 3.928.347 5.614 1.04 1.71.695 3.148 1.698 4.313 3.012l-2.38 2.343c-1.958-2.058-4.412-3.086-7.361-3.086-2.256 0-4.239.582-5.95 1.747-1.71 1.165-2.912 2.714-3.606 4.648h11.786v2.082H29.358c-.075.47-.112.979-.112 1.524 0 .52.038 1.029.112 1.524h12.306v2.083H29.878c.694 1.933 1.896 3.482 3.607 4.647 1.71 1.165 3.693 1.747 5.949 1.747z"
      fill="#F0973F"
    />
  </Box>
)

export const SvgIconClose = () => (
  <Box
    as="svg"
    width="40"
    height="40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="close"
    position="absolute"
    right={15}
    top={15}
    zIndex={2}
    style={{ cursor: 'pointer' }}
  >
    <circle cx="20" cy="20" r="19.5" fill="#fff" stroke="#DDDCDC" />
    <path
      d="M19.969 19.914l5.112 5.112-.566.565-5.112-5.112-5.369 5.37-.584-.585 5.369-5.37-4.91-4.91.565-.565 4.91 4.91 5.38-5.38.584.585-5.38 5.38z"
      fill="#5C5C5C"
    />
  </Box>
)

export const SvgIconFlag = () => (
  <Box
    as="svg"
    mr={10}
    width="23"
    height="25"
    viewBox="0 0 23 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.625 24.037L1.353 4.358M4.466 15.821L6.4 14.63a6.488 6.488 0 0 1 5.443-.622 6.488 6.488 0 0 0 5.327-.552l1.842-1.084a.782.782 0 0 0 .35-.88L17.02 2.65a.803.803 0 0 0-.96-.585.742.742 0 0 0-.196.08l-1.462.86a6.489 6.489 0 0 1-5.327.553 6.49 6.49 0 0 0-5.443.622L1.7 5.37l2.766 10.451z"
      stroke="#919191"
    />
  </Box>
)

export const SvgIconFilters = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="none" viewBox="0 0 26 26">
    <path
      fill="#919191"
      d="M13.867 4.483h11.7a.426.426 0 00.306-.132.456.456 0 000-.634.426.426 0 00-.306-.13h-11.7a.426.426 0 00-.307.13.456.456 0 000 .634.426.426 0 00.307.132z"
    />
    <path
      fill="#F08821"
      d="M.433 4.483h3.493a4.077 4.077 0 001.357 2.632 3.818 3.818 0 002.735.948 3.846 3.846 0 002.617-1.256A4.11 4.11 0 0011.7 4.034a4.11 4.11 0 00-1.066-2.772A3.846 3.846 0 008.018.006a3.817 3.817 0 00-2.735.948 4.077 4.077 0 00-1.357 2.632H.433a.426.426 0 00-.306.132.456.456 0 000 .633.426.426 0 00.306.132z"
    />
    <path
      fill="#919191"
      d="M25.567 21.517h-11.7a.426.426 0 00-.307.131.456.456 0 000 .634.426.426 0 00.307.132h11.7a.426.426 0 00.306-.132.456.456 0 000-.634.426.426 0 00-.306-.13z"
    />
    <path
      fill="#F08821"
      d="M7.8 17.931a3.832 3.832 0 00-2.598 1.03 4.087 4.087 0 00-1.276 2.556H.433a.426.426 0 00-.306.131.456.456 0 000 .634.426.426 0 00.306.132h3.493c.083.774.382 1.506.859 2.109a3.898 3.898 0 001.83 1.286 3.776 3.776 0 002.212.049 3.882 3.882 0 001.882-1.203c.502-.581.83-1.3.946-2.07a4.161 4.161 0 00-.294-2.268 4 4 0 00-1.44-1.738 3.806 3.806 0 00-2.121-.648z"
    />
    <path
      fill="#919191"
      d="M12.133 12.552H.433a.426.426 0 00-.306.131.456.456 0 000 .634.426.426 0 00.306.131h11.7a.426.426 0 00.307-.131.456.456 0 000-.634.426.426 0 00-.307-.131z"
    />
    <path
      fill="#F08821"
      d="M25.567 12.552h-3.492a4.077 4.077 0 00-1.358-2.633 3.817 3.817 0 00-2.735-.947 3.846 3.846 0 00-2.617 1.256A4.11 4.11 0 0014.3 13a4.11 4.11 0 001.066 2.772 3.847 3.847 0 002.617 1.256 3.817 3.817 0 002.735-.947 4.078 4.078 0 001.358-2.633h3.492a.426.426 0 00.306-.131.456.456 0 000-.634.426.426 0 00-.306-.131z"
    />
  </svg>
)

export const SvgIconPlay = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="9" height="12" fill="none" viewBox="0 0 9 12">
    <path
      fill="#919191"
      d="M.783.088A.5.5 0 000 .5v11a.5.5 0 00.783.412l8-5.5a.5.5 0 000-.824l-8-5.5z"
    />
  </svg>
)

export const SvgIconDisabled = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="none" viewBox="0 0 12 12">
    <path
      fill="#919191"
      d="M10.063.5L6 4.563 1.937.5.5 1.937 4.563 6 .5 10.063 1.937 11.5 6 7.437l4.063 4.063 1.437-1.437L7.437 6 11.5 1.937 10.063.5z"
    />
  </svg>
)

export const SvgIconShare = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 12 12">
    <g fill="#919191">
      <path
        d="M9,4A2,2,0,1,0,7.041,2.4L4.126,4.348a2,2,0,1,0,0,3.3L7.041,9.6a2,2,0,1,0,.833-1.247L4.959,6.405a2.021,2.021,0,0,0,0-.81L7.874,3.652A1.99,1.99,0,0,0,9,4Z"
        fill="#5c5c5c"
      />
    </g>
  </svg>
)
