import React from 'react'
import Loadable from 'react-loadable'
import { withRouter } from 'react-router-dom'
import cookies from '../../helpers/cookies'
import { compose, lifecycle } from 'recompose'
import LoadingBar from './LoadingBar'

/**
 * Use case :
 *  - the user is on the website v150
 *  - we are deploying v151
 *  - the user visit the page P, the chunk does not exist anymore -> 404
 *  - solution -> reload the page !
 */
const Reload = compose(
  withRouter,
  lifecycle({
    componentDidMount() {
      // don't reload endlessly with 30s cookie
      const noReload = cookies.get('noreload:timeout')
      if (!noReload) {
        cookies.set('noreload:timeout', 'yes', {
          maxAge: 30
        })
        window.location.reload()
      }
    }
  })
)(() => null)

const Async = (loader, opts) =>
  Loadable({
    loading: ({ error, pastDelay }) => (error ? <Reload /> : pastDelay ? <LoadingBar /> : null),
    loader,
    pastDelay: 200, // https://github.com/jamiebuilds/react-loadable#avoiding-flash-of-loading-component
    ...opts
  })

export default Async
