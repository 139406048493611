import { MuiThemeProvider } from '@material-ui/core'
import React from 'react'
import { Redirect, Route, Switch, withRouter } from 'react-router-dom'
import { compose, lifecycle } from 'recompose'
import { ThemeProvider } from 'styled-components'

import AppBootstrap, { AppBootstrapWrapper } from './App.Bootstrap'
import { PrivateRoute } from './Router'
import routes from './routes'
import { theme, theme_for_styled } from './theme'
import { LoadingBarGlobalStyle } from './views/Shared/LoadingBar'
import MainStyle from './views/Shared/Styles/Style'
import LandingCgu from './views/Landing/Cgu'
import LandingHomepage from './views/Landing/Homepage'
import LandingNeuf from './views/Landing/Neuf'
import LandingAgence from './views/Landing/Agence'
import LandingKit from './views/Landing/Kit'
import LandingLocation from './views/Landing/Location'
import LandingRent from './views/Landing/Rent'
import StopMail from './views/Landing/StopMail'
import Layout from './views/Layout'
import Async from './views/Shared/Async'
import Svg from './views/Shared/Styles/Svg'
import SignLogout from './views/Sign/Logout'
import SignConfirm from './views/Sign/Confirm'
import SignUserRedirector from './views/Sign/UserRedirector'
import AdContactEmerige from './views/Alert/AdContactEmerige'
import AdContactNeuf from './views/Alert/AdContactNeuf'
import NlUnsubscribe from './views/Landing/NlUnsubscribe'
import LandingAffiliation from './views/Landing/Affiliation'
import Nps from './views/Nps'
import SettingsAppRedirect from './views/Settings/AppRedirect'

const AsyncSigninRouter = Async(() => import('./views/Sign/Router'))
const AsyncAsownerTenancyList = Async(() => import('./views/AsownerTenancy/List'))
const AsyncAsownerTenancyAddRouter = Async(() => import('./views/AsownerTenancy/AddRouter'))
const AsyncAsownerTenancyAdd = Async(() => import('./views/AsownerTenancy/Add'))
const AsyncAsownerTenancyEditMandate = Async(() => import('./views/AsownerTenancy/EditMandate'))
const AsyncAsownerTenancyEditMandateConfirm = Async(() =>
  import('./views/AsownerTenancy/EditMandateConfirm')
)
const AsyncAsownerHome = Async(() => import('./views/AsownerHome/Home'))
const AsyncAsownerCollectorAddRouter = Async(() => import('./views/AsownerCollector/AddRouter'))
const AsyncAsownerCollectorEditKyc = Async(() => import('./views/AsownerCollector/EditKyc'))
const AsyncAsrenterTenancyList = Async(() => import('./views/AsrenterTenancy/List'))
const AsyncAsrenterTenancyEditMandate = Async(() => import('./views/AsrenterTenancy/EditMandate'))
const AsyncAsrenterTenancyEditMandateConfirm = Async(() =>
  import('./views/AsrenterTenancy/EditMandateConfirm')
)
const AsyncAsrenterGlobalApplication = Async(() =>
  import('./views/AsrenterGlobalApplication/Router')
)
const AsyncAsrenterGlobalApplicationRedirect = Async(() =>
  import('./views/AsrenterGlobalApplication/Redirect')
)
const AsyncAdpremiumApplicationRouter = Async(() => import('./views/AdpremiumApplication/Router'))
const AsyncAdpremiumApplicationList = Async(() => import('./views/AdpremiumApplication/List'))
const AsyncAdpremiumApplicationRedirect = Async(() =>
  import('./views/AdpremiumApplication/Redirect')
)
const AsyncAlertList = Async(() => import('./views/Alert/List'))
const AsyncAlertCreate = Async(() => import('./views/Alert/Create'))
const AsyncAlertLoading = Async(() => import('./views/Alert/Loading'))
const AsyncAlertDashboard = Async(() => import('./views/Alert/Dashboard'))
const AsyncAlertUnsubscribe = Async(() => import('./views/Alert/Unsubscribe'))
const AsyncPartnerLuko = Async(() => import('./views/Partner/Luko'))
const AsyncPartnerGeneric = Async(() => import('./views/Partner/Generic'))
const AdContact = Async(() => import('./views/Alert/AdContact'))
const AsyncSignSudo = Async(() => import('./views/Sign/Sudo'))
const AsyncSignDownload = Async(() => import('./views/Sign/Download'))
const AsyncAlertResult = Async(() => import('./views/Alert/Result'))
const AsyncAlertResultSolo = Async(() => import('./views/Alert/ResultSolo'))
const AsyncAsownerAdList = Async(() => import('./views/AsownerAd/List'))
const AsyncAsownerAdDetail = Async(() => import('./views/AsownerAd/Detail'))
const AsyncAsownerLeaseForm = Async(() => import('./views/AsownerAd/LeaseForm'))
const AsyncAsownerLeaseFormPublicRouter = Async(() =>
  import('./views/AsownerAd/LeaseFormPublicRouter')
)
const AsyncAsownerAdAddHome = Async(() => import('./views/AsownerAd/Add'))
const AsyncAsownerAdAddRouter = Async(() => import('./views/AsownerAd/AddRouter'))
const AsyncAsownerInvoices = Async(() => import('./views/AsownerInvoice/List'))
const AsyncAsownerRents = Async(() => import('./views/AsownerRent/List'))
const AsyncAsownerFiles = Async(() => import('./views/AsownerFile/List'))
const AsyncBkConfirm = Async(() => import('./views/BkConfirm/Redirect'))
const AsyncSettings = Async(() => import('./views/Settings'))
const AsyncProfile = Async(() => import('./views/Profile'))
const AsyncPartnerAgency = Async(() => import('./views/Partner/Agency'))
const AsyncSettingsEditEmailConfirm = Async(() => import('./views/Settings/EditEmailConfirm'))
const AsyncSettingsUserEmailMobileConfirm = Async(() =>
  import('./views/Settings/UserEmailMobileConfirm')
)
const AsyncSettingsUserFriendMobileConfirm = Async(() =>
  import('./views/Settings/UserFriendMobileConfirm')
)
const AsyncRedirectApp = Async(() => import('./views/Settings/AppRedirect'))
const AsyncRedirectMobilipassFormSubscribe = Async(() =>
  import('./views/Redirect/MobilipassFormSubscribe')
)
const AsyncMobilipassDossier = Async(() => import('./views/Mobilipass/Dossier'))
const AsyncMobilipassChasseurGendoc = Async(() => import('./views/Mobilipass/Chasseur.Gendoc'))
const AsyncMobilipassChasseurEnd = Async(() => import('./views/Mobilipass/Chasseur.End'))
const AsyncJinkalocChasseurEnd = Async(() => import('./views/Jinkaloc/Chasseur.End'))
const AsyncAdminUser = Async(() => import('./views/Admin/User'))

const {
  redirect_logement,
  sign,
  asowner_tenancy_list,
  asowner_tenancy_add,
  asowner_tenancy_add_home,
  asowner_collector_add,
  asowner_collector_edit_kyc,
  asowner_home,
  asrenter_tenancy_list,
  asrenter_tenancy_edit_mandate,
  asrenter_tenancy_edit_mandate_confirm,
  asrenter_adpremium,
  asrenter_adpremium_apply,
  asrenter_application_list,
  asrenter_global_application,
  asrenter_global_application_home,
  asrenter_settings,
  asrenter_profile,
  asrenter_edit_email_confirm,
  asrenter_user_email_mobile_confirm,
  asrenter_user_friend_mobile_confirm,
  alert_list,
  alert_create,
  alert_result,
  ad,
  alert_dashboard,
  alert_loading,
  alert_unsubscribe,
  asrenter_partner_luko,
  asrenter_ad_contact,
  asrenter_ad_contact_emerige,
  asrenter_partner_generic,
  asrenter_ad_contact_neuf,
  sign_sudo,
  asowner_ad_add,
  asowner_ad_add_home,
  asowner_tenancy_edit_mandate,
  asowner_tenancy_edit_mandate_confirm,
  asowner_ad_list,
  asowner_ad_detail,
  asowner_ad_lease_form,
  asowner_ad_lease_form_public_step,
  homepage,
  neuf,
  agence,
  kit,
  location,
  rent,
  fnaim,
  stopmail,
  cgu,
  nl_unsubscribe,
  asowner_invoices,
  asowner_rents,
  asowner_files,
  asowner_bkconfirm,
  logout,
  user_redirector,
  admin_user,
  asrenter_appredirect,
  nps
} = routes

const page_view = () => {
  if (window && window.plausible) {
    const { pathname } = window.location
    if (/^\/asrenter\/alert\/dashboard/.test(pathname)) {
      window.plausible('pageview', { u: `/asrenter/alert/dashboard/token` })
    } else {
      window.plausible('pageview', { u: `${pathname}` })
    }
  }

  return null
}

const AppRouter = () => {
  return (
    <Layout>
      <Route path="/" component={page_view} />
      <Switch>
        <PrivateRoute path={user_redirector.route.spec} component={SignUserRedirector} />
        <Route path={alert_unsubscribe.route.spec} component={AsyncAlertUnsubscribe} />
        <Route path="/confirm_user" component={SignConfirm} />
        <Route path={logout.route.spec} component={SignLogout} />

        <Route
          path={redirect_logement.route.spec}
          component={() => (
            <Redirect
              to={{
                pathname: sign.route.reverse({ action: 'up' }),
                search: `r_pathname=${asowner_home.route.reverse()}`
              }}
            />
          )}
        />
        <PrivateRoute path={routes.sign_download.route.spec} component={AsyncSignDownload} />
        <Route path={sign_sudo.route.spec} component={AsyncSignSudo} />
        <Route path={sign.route.spec} component={AsyncSigninRouter} />
        <PrivateRoute path={asowner_tenancy_list.route.spec} component={AsyncAsownerTenancyList} />
        <PrivateRoute
          path={asowner_tenancy_add.route.spec}
          component={AsyncAsownerTenancyAddRouter}
        />
        <PrivateRoute
          path={asowner_tenancy_add_home.route.spec}
          component={AsyncAsownerTenancyAdd}
        />
        <PrivateRoute
          path={asowner_collector_add.route.spec}
          component={AsyncAsownerCollectorAddRouter}
        />
        <PrivateRoute
          path={asowner_collector_edit_kyc.route.spec}
          component={AsyncAsownerCollectorEditKyc}
        />
        <PrivateRoute path={asowner_ad_list.route.spec} component={AsyncAsownerAdList} />
        <PrivateRoute path={asowner_ad_add.route.spec} component={AsyncAsownerAdAddRouter} />
        <PrivateRoute path={asowner_ad_add_home.route.spec} component={AsyncAsownerAdAddHome} />
        <PrivateRoute
          path={asowner_tenancy_edit_mandate.route.spec}
          component={AsyncAsownerTenancyEditMandate}
        />
        <PrivateRoute
          path={asowner_tenancy_edit_mandate_confirm.route.spec}
          component={AsyncAsownerTenancyEditMandateConfirm}
        />
        <PrivateRoute path={asowner_ad_detail.route.spec} component={AsyncAsownerAdDetail} />
        <PrivateRoute path={asowner_ad_lease_form.route.spec} component={AsyncAsownerLeaseForm} />
        <PrivateRoute
          path={asowner_ad_lease_form_public_step.route.spec}
          component={AsyncAsownerLeaseFormPublicRouter}
        />
        <PrivateRoute path={asowner_invoices.route.spec} component={AsyncAsownerInvoices} />
        <PrivateRoute path={asowner_rents.route.spec} component={AsyncAsownerRents} />
        <PrivateRoute path={asowner_files.route.spec} component={AsyncAsownerFiles} />
        <PrivateRoute path={asowner_bkconfirm.route.spec} component={AsyncBkConfirm} />
        <PrivateRoute path={asowner_home.route.spec} component={AsyncAsownerHome} />

        <PrivateRoute
          path={asrenter_tenancy_list.route.spec}
          component={AsyncAsrenterTenancyList}
        />
        <PrivateRoute
          path={asrenter_tenancy_edit_mandate.route.spec}
          component={AsyncAsrenterTenancyEditMandate}
        />
        <PrivateRoute
          path={asrenter_tenancy_edit_mandate_confirm.route.spec}
          component={AsyncAsrenterTenancyEditMandateConfirm}
        />
        <PrivateRoute
          path={asrenter_adpremium_apply.route.spec}
          component={AsyncAdpremiumApplicationRouter}
        />
        <PrivateRoute
          path={asrenter_application_list.route.spec}
          component={AsyncAdpremiumApplicationList}
        />
        <PrivateRoute
          path={asrenter_adpremium.route.spec}
          component={AsyncAdpremiumApplicationRedirect}
        />
        <PrivateRoute
          path={asrenter_global_application.route.spec}
          component={AsyncAsrenterGlobalApplication}
        />
        <PrivateRoute
          path={asrenter_global_application_home.route.spec}
          component={AsyncAsrenterGlobalApplicationRedirect}
        />
        <Route
          path={routes.asrenter_mobilipass_dossier.route.spec}
          component={AsyncMobilipassDossier}
        />
        <Route
          path={routes.asrenter_mobilipass_chasseur_gendoc.route.spec}
          component={AsyncMobilipassChasseurGendoc}
        />
        <Route
          path={routes.asrenter_mobilipass_chasseur_end.route.spec}
          component={AsyncMobilipassChasseurEnd}
        />
        <Route
          path={routes.asrenter_jinkaloc_chasseur_end.route.spec}
          component={AsyncJinkalocChasseurEnd}
        />
        <PrivateRoute path={asrenter_settings.route.spec} component={AsyncSettings} />
        <PrivateRoute path={asrenter_profile.route.spec} component={AsyncProfile} />
        <PrivateRoute
          path={asrenter_edit_email_confirm.route.spec}
          component={AsyncSettingsEditEmailConfirm}
        />
        <PrivateRoute
          path={asrenter_user_email_mobile_confirm.route.spec}
          component={AsyncSettingsUserEmailMobileConfirm}
        />
        <Route path={asrenter_appredirect.route.spec} component={SettingsAppRedirect} />
        <Route
          path={asrenter_user_friend_mobile_confirm.route.spec}
          component={AsyncSettingsUserFriendMobileConfirm}
        />
        <PrivateRoute path={alert_list.route.spec} component={AsyncAlertList} />
        <PrivateRoute path={alert_create.route.spec} component={AsyncAlertCreate} />
        <PrivateRoute path={alert_loading.route.spec} component={AsyncAlertLoading} />

        <PrivateRoute path={alert_dashboard.route.spec} component={AsyncAlertDashboard} />
        <PrivateRoute path={asrenter_partner_luko.route.spec} component={AsyncPartnerLuko} />
        <PrivateRoute path={asrenter_partner_generic.route.spec} component={AsyncPartnerGeneric} />
        <PrivateRoute path={admin_user.route.spec} component={AsyncAdminUser} />
        <Route path={asrenter_ad_contact.route.spec} component={AdContact} />
        <Route path={asrenter_ad_contact_emerige.route.spec} component={AdContactEmerige} />
        <Route path={asrenter_ad_contact_neuf.route.spec} component={AdContactNeuf} />
        <Route path={alert_result.route.spec} component={AsyncAlertResult} />
        <Route path={ad.route.spec} component={AsyncAlertResultSolo} />
        <Route path={routes.partner_agency.route.spec} component={AsyncPartnerAgency} />
        <Route path={routes.homepage_partner.route.spec} component={LandingAffiliation} />
        <Route path={homepage.route.spec} component={LandingHomepage} exact />
        <Route path={neuf.route.spec} component={LandingNeuf} exact />
        <Route path={agence.route.spec} component={LandingAgence} exact />
        <Route path={kit.route.spec} component={LandingKit} exact />
        <Route path={location.route.spec} component={LandingLocation} exact />
        <Route path={rent.route.spec} component={LandingRent} exact />
        <Route path={fnaim.route.spec} component={LandingRent} exact />
        <Route path={stopmail.route.spec} component={StopMail} exact />
        <Route path={cgu.route.spec} component={LandingCgu} />
        <Route path={nl_unsubscribe.route.spec} component={NlUnsubscribe} />
        <Route path={asrenter_appredirect.route.spec} component={AsyncRedirectApp} />
        <Route
          path={routes.asrenter_redirect_mobilipass_form_subscribe.route.spec}
          component={AsyncRedirectMobilipassFormSubscribe}
        />
        <Route path={nps.route.spec} component={Nps} />
      </Switch>
    </Layout>
  )
}

const ScrollToTop = compose(
  withRouter,
  lifecycle({
    componentDidUpdate(prevProps) {
      const { location } = this.props
      const noScroll = location.state && location.state.noscroll

      if (location !== prevProps.location && !noScroll) {
        window.scrollTo(0, 0)
      }
    }
  })
)(() => null)

export default () => (
  <ThemeProvider theme={theme_for_styled}>
    <MuiThemeProvider theme={theme}>
      <AppBootstrapWrapper>
        <ScrollToTop />
        <LoadingBarGlobalStyle />
        <MainStyle />
        <Svg />
        <AppBootstrap />
        <AppRouter />
      </AppBootstrapWrapper>
    </MuiThemeProvider>
  </ThemeProvider>
)
