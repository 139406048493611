import { createBrowserHistory } from 'history'
import QueryString from 'querystring'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, Router, useLocation } from 'react-router-dom'

import { changeLocation } from './store/actions/router'
import LoadingBar from './views/Shared/LoadingBar'
import routes from './routes'
import { useFetchApi } from './views/Shared/hooks'
import { useImmerStore } from './store'

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const [
    {
      db: { me_authenticated }
    }
  ] = useImmerStore()
  const [action, set_action] = React.useState(null)
  const location = useLocation()
  const location_pathname = location.pathname
  const fetcher = useFetchApi()

  React.useEffect(() => {
    const init = async () => {
      if (/^\/asrenter\/alert\/dashboard/.test(location_pathname)) {
        const alert_id_migrate_signup = (location_pathname.match(
          /^\/asrenter\/alert\/dashboard\/([a-zA-Z0-9]*)/
        ) || [])[1]

        const result = await fetcher(`alert/${alert_id_migrate_signup}`)
        if (result.user_id) {
          set_action('in')
        } else {
          set_action('up')
        }
      } else if (
        /^\/application\/p\/apply/.test(location_pathname) ||
        /^\/asrenter\/adpremium/.test(location_pathname) ||
        /^\/asrenter\/alert\/create/.test(location_pathname)
      ) {
        set_action('up')
      } else if (/^\/asowner\/ad\/add/.test(location_pathname)) {
        set_action('up')
      } else {
        set_action('in')
      }
    }
    if (me_authenticated === false) {
      init()
    }
  }, [me_authenticated, fetcher, location_pathname])

  const render = React.useCallback(
    props => {
      if (me_authenticated) {
        return <Component {...props} />
      }

      if (action) {
        return (
          <Redirect
            to={{
              pathname: routes.sign.route.reverse({ action }),
              search: QueryString.stringify({
                r_pathname: location_pathname,
                r_search: props.location.search
              })
            }}
          />
        )
      }

      // case "null"
      return <LoadingBar />
    },
    [action, me_authenticated, location_pathname]
  )

  return <Route {...rest} render={render} />
}

class RouterDispatcher extends Component {
  history = createBrowserHistory(this.props)

  handleLocationChange = (location, action) => {
    const { dispatch } = this.props
    dispatch(changeLocation(location, action))
  }

  UNSAFE_componentWillMount() {
    this.unsubscribeFromHistory = this.history.listen(this.handleLocationChange)
    this.handleLocationChange(this.history.location, null)
  }

  componentWillUnmount() {
    if (this.unsubscribeFromHistory) this.unsubscribeFromHistory()
  }

  render() {
    return <Router history={this.history}>{this.props.children}</Router>
  }
}

export default connect()(RouterDispatcher)
